// FileHandler.js
import JSZip from 'jszip';
import * as shapefile from 'shapefile';
import { kml } from 'togeojson';
import { gpx } from 'togeojson';
import xml2js from 'xml2js';
import CustomMap from './CustomMap';
import * as turf from '@turf/turf';
import { CommonUtils } from './CommonUtils';
import maplibregl from 'maplibre-gl';
import markerIconR from './img/marker_r.png';
import Supercluster from 'supercluster';
import exifr from 'exifr';
import Marker from './Marker';
import { toast } from 'react-toastify';
import { Translator, resources } from './Translator';
import elevationService from './ElevationService';
import { FileTypeChecker } from './FileTypeChecker';

export default class FileHandler {
	constructor(map,mapManager,fm, ownerUid, projectId) {
		this.map = map;
		this.mapManager = mapManager;
		this.fm = fm;
		this.ownerUid = ownerUid;
		this.projectId = projectId;
		this.savedTracks = [];
		this.savedData = []; 
		this._index = 0;
		this.displayedFiles = new Set();
		this.markers = [];

		this.popup = new maplibregl.Popup({
			closeButton: true,
			closeOnClick: false
		});

		this.myName = fm._myName; 
		this.myColor = fm._myColor;
	}

	handleFileUpload = async (event, myName, myColor,nowCount,maxCount,onProgress,onOverMaxCount) => {
		const translator = new Translator(resources);					
		const t = (key) => translator.t(key);

		const files = event.target.files;
		if (!files || files.length === 0) {
			onProgress(0, 0);
			return;
		}
		this.myName = myName; 
		this.myColor = myColor; 

		let filesCount = files.length;

		const rest = maxCount - nowCount;
		let isOver = false;
		if(rest <= 0) {
			onProgress(0, 0);
			onOverMaxCount();
			return;
		}
		if(filesCount > rest) {
			filesCount = rest;
			isOver = true;
		}

		for (let i = 0; i < filesCount; i++) {
			onProgress(i, files.length);
			const file = files[i];
			let fileData;
			let filePath;
			let fileType = file.type;

			const contentsType = await FileTypeChecker.checkFileType(file);

			try {
				const docId = CommonUtils.generateUUID();
				if (file.name.endsWith('.zip')) {
					fileData = await this.handleZipFile(file,docId);
					if(!file.type || file.type === '') fileType = 'application/zip';
				}
				else if (file.name.endsWith('.kml')) {
					fileData = await this.handleKMLFile(file,docId);
					if(!file.type || file.type === '') fileType = 'application/kml+xml';
				}
				else if (file.name.endsWith('.geojson') || file.name.endsWith('.json')) {
					fileData = await this.handleGeoJSONFile(file,docId);
					if(!file.type || file.type === '') fileType = 'application/json';
				}
				else if (file.type.startsWith('image/') || file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.jpeg') || file.name.endsWith('.gif')) {
					const resizedImageDataUrl = await this.resizeImage(file, 1024, 0.5);
					let gps = await exifr.gps(file);

					let isNoGps = false;
					if(!gps) {
						gps = this.getCenterGPSInfo();
						isNoGps = true;
					}
					const exifData = await exifr.parse(file, ['DateTimeOriginal', 'Model','GPSAltitude']);

					if (gps && gps.latitude && gps.longitude) {
						let altitude = null;
						if(isNoGps) {
							altitude = await elevationService.getElevation(gps.latitude, gps.longitude);
						}

						if(exifData && exifData.GPSAltitude) {
							altitude = exifData.GPSAltitude;
							const dateTimeOriginal = exifData.DateTimeOriginal != null ?  exifData.DateTimeOriginal : file.lastModifiedDate;

							await this.handleImageFileWithGPS(gps, altitude, dateTimeOriginal, exifData.Model, file, resizedImageDataUrl);
						}
						else {
							await this.handleImageFileWithGPS(gps, altitude, file.lastModifiedDate, "No Data", file, resizedImageDataUrl);
						}
						
						if(isNoGps) {
							toast(t('no gps photo'), {autoClose: 8000});
						}
						continue;
					}
				}
				/*else if (file.type.startsWith('image/') || file.name.endsWith('.png') || file.name.endsWith('.jpg') || file.name.endsWith('.gif')) {
					fileData = this.handleImageFile(file,docId);
					if(!file.type || file.type === '') {
						fileType = 'image/' + file.name.split('.').pop();
					}
				}*/
				else {
					if(contentsType === FileTypeChecker.GPX_TYPE_MARKER) {
						//fileから読み込んでMarkerを作る
						const markers = await this.handleGPXForMarkers(file, docId);
						if(markers && markers.length > 0) {
							//読み込んだマーカー群を登録する


						}

						fileData = null;//マーカーとして展開して使うのでアップロードしない
					}
					else if(contentsType === FileTypeChecker.GPX_TYPE_TRACK) {
						fileData = await this.handleGPXFile(file,docId);
						if(!file.type || file.type === '') fileType = 'application/gpx+xml';
					}
				}

				if (fileData) {
					//const fileName = `${CommonUtils.generateUUID()}.${file.name.split('.').pop()}`;
					this.displayedFiles.add(file.name);
					const storagePath = `files/${this.ownerUid}/${this.projectId}/${this.fm.auth.currentUser.uid}/open/${file.name}`;
					filePath = await this.fm.uploadFile(storagePath, file, fileType);
					console.log('file.name:', file.name);
					await this.saveFileDataToFirebase(filePath,docId,file.name, 4);
				}
			} catch (error) {
				console.error(`Error processing file ${file.name}:`, error);
				toast.error(`Failed to process ${file.name}: ${error.message}`);
			}
		}
		onProgress(files.length, files.length);
		if(isOver) onOverMaxCount();
	};

	async resizeImage(file, maxSize = 1024,quality = 0.8) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (readerEvent) => {
				const image = new Image();
				image.onload = () => {
					const canvas = document.createElement('canvas');
					let width = image.width;
					let height = image.height;
					
					if (width > height) {
					if (width > maxSize) {
						height *= maxSize / width;
						width = maxSize;
					}
					} else {
					if (height > maxSize) {
						width *= maxSize / height;
						height = maxSize;
					}
					}
					
					canvas.width = width;
					canvas.height = height;
					
					const ctx = canvas.getContext('2d');
					ctx.drawImage(image, 0, 0, width, height);
					
					// ファイルタイプに基づいて適切な圧縮を適用
					let dataUrl;
					if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
						dataUrl = canvas.toDataURL('image/jpeg', quality);
					} else if (file.type === 'image/png') {
						dataUrl = canvas.toDataURL('image/png');
					} else {
						// その他のファイルタイプの場合はデフォルトのJPEG圧縮を使用
						dataUrl = canvas.toDataURL('image/jpeg', quality);
					}
					resolve(dataUrl);
				};
				image.onerror = reject;
				image.src = readerEvent.target.result;
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	getCenterGPSInfo() {
		let centerLat = this.map.getCenter().lat;
		let centerLng = this.map.getCenter().lng;
		return {
			latitude: centerLat,
			longitude: centerLng
		};	
	}

	async handleImageFileWithGPS(gps,altitude,dateTimeOriginal,model,file,resizedImageDataUrl) {
		try {
			// EXIFデータから GPS 情報を抽出
			if (!gps || !gps.latitude || !gps.longitude) {
				throw new Error('GPS data not found in the image');
			}
			if(!altitude) {
				altitude = -10;
			}

			const color = CommonUtils.numberToHexColor(CommonUtils.colors[this.myColor]);

			const testLabel = document.getElementById('testLabel');
			testLabel.innerText = `handleImageFileWithGPS: ${this.myColor} / ${color}`;

			// 画像を処理して円形のアイコンを作成
			const iconDataUrl = await this.createCircularIcon(file, color);

			const resizedImageBlob = await fetch(resizedImageDataUrl).then(r => r.blob());
			const iconImageBlob = await fetch(iconDataUrl).then(r => r.blob());
			
			// ファイル名を生成
			const fileName = file.name;
			const iconfilename = file.name + ".png";
			
			// Firebase Storage にアップロード
			const storagePath = `files/${this.ownerUid}/${this.projectId}/${this.fm.auth.currentUser.uid}/open/`;
			const imageFilePath = await this.fm.uploadFile(storagePath + fileName, resizedImageBlob, 'image/jpeg');
			const iconFilePath = await this.fm.uploadFile(storagePath + iconfilename, iconImageBlob, 'image/png');
			
			//作成日時
			let createTime;
			if (dateTimeOriginal instanceof Date && !isNaN(dateTimeOriginal)) {
				// Use EXIF date if available
				createTime = dateTimeOriginal;
			} else {
				// Fall back to file creation date
				createTime = file.lastModified ? new Date(file.lastModified) : new Date();
			}

			// Firebase にファイル情報を保存
			await this.saveImageFileDataToFirebase(imageFilePath,fileName,iconFilePath,gps,altitude,dateTimeOriginal,model, 5,createTime);
			this.displayedFiles.add(fileName);

			// マーカーを作成して地図に追加
			const marker = new Marker(
				this.map,
				gps.longitude,
				gps.latitude,
				altitude || 0,
				{ name: file.name, note: 'GPS Photo', iconNumber: -1 }, // iconNumber: -1 でカスタムアイコンを使用
				64, // マーカーサイズ
				CommonUtils.generateUUID(),
				this,
				null,
				(marker) => {
					console.log('photo move');
				},
				null
			);

			// マーカーを地図に追加
			marker.addToMap();

			if (this.map) {
				this.map.flyTo({
					center: [gps.longitude, gps.latitude],
					zoom: this.map.getZoom()
				});
			}

			return marker;
		} catch (error) {
			console.error('Error processing image with GPS:', error);
			throw error;
		}
	}
	async saveImageFileDataToFirebase(filePath,fileName,iconPath,gps,altitude,dateTimeOriginal,model,type,createTime) {
		const collectionPath = `userdata/${this.ownerUid}/projects/${this.projectId}/items`;
		const documentId = CommonUtils.generateUUID();
		const data = {
			type: type,
			lng:gps.longitude,
			lat:gps.latitude,
			elevation:altitude || 0,
			iconNumber:0,
			name:"",
			note:"",
			owneruid: this.fm.auth.currentUser.uid,
			ownername: this.myName,
			ownercolor: this.myColor,
			fileurl: filePath,
			fileName: fileName,
			iconurl:iconPath,
			dateTimeOriginal: dateTimeOriginal ? dateTimeOriginal.toISOString() : null,
			cameraModel: model || 'Unknown',
			createtime: new Date(),
			updatetime: new Date(),
		};
		try {
			await this.fm.setDocument(collectionPath, documentId, data);
			console.log('File data successfully saved to Firebase!');
		} catch (error) {
			console.error('Failed to save file data to Firebase:', error);
		}
	}

	deleteFile = (ProjectOwneruid,itemId,fileData) => {
		const collectionPath = `userdata/${ProjectOwneruid}/projects/${this.projectId}/items`;
		const storagePath = `files/${ProjectOwneruid}/${this.projectId}/${fileData.owneruid}/open/`;

		let fileName = null;
		if(fileData.type === 2 || fileData.type === 3) {
			fileName = itemId + '.geojson';
		}
		else {
			fileName = fileData.fileName;
		}

		try {
			this.fm.deleteFile(storagePath + fileName);
			try {
				this.fm.deleteDocument(collectionPath, itemId);
				console.log('File data successfully deleteFile to Firebase!');
			} catch (error) {
				console.error('Failed to deleteFile to Firebase:', error);
			}
		}
		catch (error) {
			console.error('Error deleteFile:', error);
			throw error;
		}
	};

	deletePhoto = (photo) => {
		const collectionPath = `userdata/${this.ownerUid}/projects/${this.projectId}/items`;

		const storagePath = `files/${this.ownerUid}/${this.projectId}/${this.fm.auth.currentUser.uid}/open/`;
		const photoFileName = photo.fileName;
		const iconFileName = photo.fileName + '.png';

		try {
			this.fm.deleteFile(storagePath + photoFileName);
			this.fm.deleteFile(storagePath + iconFileName);
			try {
				this.fm.deleteDocument(collectionPath, photo.docId);
				console.log('File data successfully deletePhoto!');
			} catch (error) {
				console.error('Failed to deletePhoto:', error);
			}
		}
		catch (error) {
			console.error('Error deletePhoto:', error);
			throw error;
		}
	};

	async createCircularIcon(file, borderColor) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					const size = 256;
					const circleRadius = 114;
					canvas.width = size;
					canvas.height = size;

					// 画像を正方形にトリミングし、256x256にリサイズ
					const minDimension = Math.min(img.width, img.height);
					const sx = (img.width - minDimension) / 2;
					const sy = (img.height - minDimension) / 2;
					ctx.drawImage(img, sx, sy, minDimension, minDimension, 0, 0, size*1.5, size*1.5);

					// 中心から半径64ピクセルの円形で切り出す
					ctx.globalCompositeOperation = 'destination-in';
					ctx.beginPath();
					ctx.arc(size / 2, size / 2, circleRadius, 0, Math.PI * 2);
					ctx.fill();

					// 円形の画像を縁取り
					ctx.globalCompositeOperation = 'source-over';
					
					// 白の縁取り（外側）
					ctx.strokeStyle = 'white';
					ctx.lineWidth = 28;
					ctx.beginPath();
					ctx.arc(size / 2, size / 2, circleRadius + 3, 0, Math.PI * 2);
					ctx.stroke();

					// borderColor
					ctx.strokeStyle = borderColor;
					ctx.lineWidth = 14;
					ctx.beginPath();
					ctx.arc(size / 2, size / 2, circleRadius + 3, 0, Math.PI * 2);
					ctx.stroke();

					resolve(canvas.toDataURL('image/png'));
				};
				img.onerror = reject;
				img.src = e.target.result;
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	dataURLtoBlob(dataURL) {
		const arr = dataURL.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}


	async saveFileDataToFirebase(filePath,docId,fileName, type) {
		const collectionPath = `userdata/${this.ownerUid}/projects/${this.projectId}/items`;
		const data = {
			type: type,
			owneruid: this.fm.auth.currentUser.uid,
			ownername: this.myName,
			ownercolor: this.myColor,
			fileUrl: filePath,
			fileName: fileName,
			createtime: new Date(),
			updatetime: new Date(),
		};
		try {
			await this.fm.setDocument(collectionPath, docId, data);
			console.log('File data successfully saved to Firebase!');
		} catch (error) {
			console.error('Failed to save file data to Firebase:', error);
		}
	}
	
	handleImageFile = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const imageData = e.target.result;
			this.addCustomMap(imageData);
		};
		reader.readAsDataURL(file);
	};

	addCustomMap = (imageData) => {
		const bounds = this.map.getBounds();
		const lng = (bounds.getEast() + bounds.getWest()) / 2;
		const lat = (bounds.getNorth() + bounds.getSouth()) / 2;
		const width = (bounds.getEast() - bounds.getWest()) * 0.4;

		const image = new Image();
		image.onload = () => {
			const aspectRatio = image.width / image.height;
			const height = width / aspectRatio;

			const corners = [
				[lng - width / 2, lat + height / 2], // top-left
				[lng + width / 2, lat + height / 2], // top-right
				[lng + width / 2, lat - height / 2], // bottom-right
				[lng - width / 2, lat - height / 2]  // bottom-left
			];

			new CustomMap(this.map, imageData, corners);
		};
		image.src = imageData;
	};
	handleZipFile = async (file,docId) => {
		const zip = new JSZip();
		try {
			const content = await zip.loadAsync(file);
			const shpFile = Object.keys(content.files).find(filename => filename.endsWith('.shp'));
			const dbfFile = Object.keys(content.files).find(filename => filename.endsWith('.dbf'));

			if (shpFile && dbfFile) {
				const [shpData, dbfData] = await Promise.all([
					content.files[shpFile].async('arraybuffer'),
					content.files[dbfFile].async('arraybuffer')
				]);
				const geojson = await this.parseShapefile(shpData, dbfData);
				this.savedData.push({ geojson });
				this.displaySavedDataOnMap();
				return geojson;
			} else {
				throw new Error('Shapefile components missing');
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};


	parseShapefile = async (shpData, dbfData) => {
		try {
			const source = await shapefile.open(shpData, dbfData);
			const features = [];
			while (true) {
				const result = await source.read();
				if (result.done) break;
				features.push(result.value);
			}
			const geojson = {
				type: 'FeatureCollection',
				features: features,
			};
			return geojson;
		} catch (error) {
			console.error('Error parsing shapefile:', error);
			throw error;
		}
	};

	displaySavedDataOnMap = (docId) => {
		this.savedData.forEach((data, index) => {
			const { geojson, styles } = data;

			if(!styles || styles === undefined) {
				this.displayDataOnMap(geojson,index);
			}
			else {
				const sourceId = `data-${index}-${docId}`;
				const layerId = `data-layer-${index}-${docId}`;

				if (!this.map.getSource(sourceId)) {
					this.map.addSource(sourceId, {
						type: 'geojson',
						data: geojson,
					});

					geojson.features.forEach((feature, featureIndex) => {
						const featureLayerId = `${layerId}-${featureIndex}`;
						const layer = {
							id: featureLayerId,
							type: this.getLayerType(feature.geometry.type),
							source: sourceId,
							paint: this.getLayerPaint(feature, styles),
						};

						this.map.addLayer(layer);
					});
				} else {
					this.map.getSource(sourceId).setData(geojson);
				}
			}

		});
	};

	displayGeoJSON = (geojson) => {
		const sourceId = `geojson-${Date.now()}`;
		const layerId = `layer-${Date.now()}`;

		this.map.addSource(sourceId, {
			type: 'geojson',
			data: geojson
		});

		// 基本的なスタイルでレイヤーを追加
		this.map.addLayer({
			id: layerId,
			type: 'line',
			source: sourceId,
			paint: {
				'line-color': '#007cbf',
				'line-width': 2
			}
		});

		// ポイントのレイヤーを追加
		this.map.addLayer({
			id: `${layerId}-points`,
			type: 'circle',
			source: sourceId,
			paint: {
				'circle-radius': 5,
				'circle-color': '#007cbf'
			},
			filter: ['==', '$type', 'Point']
		});

		// ポリゴンのレイヤーを追加
		this.map.addLayer({
			id: `${layerId}-polygons`,
			type: 'fill',
			source: sourceId,
			paint: {
				'fill-color': '#007cbf',
				'fill-opacity': 0.5
			},
			filter: ['==', '$type', 'Polygon']
		});
	};

	handleKMLFile = (file,docId) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const kmlIds = this.kmlSendToMap(reader.result,docId);
				this.savedData.push({ type: 'kml', ids: kmlIds, file: file });
				resolve(kmlIds);
			};
			reader.onerror = reject;
			reader.readAsText(file);
		});
	};

	kmlSendToMap(kmlText, docId) {
		const parser = new DOMParser();
		const kmlDom = parser.parseFromString(kmlText, 'text/xml');
		const geoJson = kml(kmlDom);

		const sourceId = `kml-source-${this._index}-${docId}`;
		const polygonLayerId = `kml-polygons-${this._index}-${docId}`;
		const lineLayerId = `kml-lines-${this._index}-${docId}`;
		const pointLayerId = `kml-points-${this._index}-${docId}`;

		// Add the GeoJSON source
		this.map.addSource(sourceId, {
			type: 'geojson',
			data: geoJson
		});

		// Add Polygon layer
		this.map.addLayer({
			id: polygonLayerId,
			type: 'fill',
			source: sourceId,
			filter: ['==', '$type', 'Polygon'],
			paint: {
				'fill-color': ['get', 'fill'],
				'fill-opacity': ['get', 'fill-opacity']
			}
		});

		// Add polygon outline layer
		this.map.addLayer({
			id: `${polygonLayerId}-outline`,
			type: 'line',
			source: sourceId,
			filter: ['==', '$type', 'Polygon'],
			paint: {
				'line-color': ['get', 'stroke'],
				'line-opacity': ['get', 'stroke-opacity'],
				'line-width': ['get', 'stroke-width']
			}
		});

		// LineString layer
		this.map.addLayer({
			id: lineLayerId,
			type: 'line',
			source: sourceId,
			filter: ['==', '$type', 'LineString'],
			paint: {
				'line-color': ['get', 'stroke'],
				'line-opacity': ['get', 'stroke-opacity'],
				'line-width': ['get', 'stroke-width']
			}
		});

		// Point layer
		this.map.addLayer({
			id: pointLayerId,
			type: 'circle',
			source: sourceId,
			filter: ['==', '$type', 'Point'],
			paint: {
				'circle-radius': 6,
				'circle-color': '#ff0000'
			}
		});

		this._index++;

		// Return all layer IDs
		return {
			sourceId,
			polygonLayerId,
			lineLayerId,
			pointLayerId
		};
	}



	handleGeoJSONFile = (file,docId) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {

				const geojson = JSON.parse(reader.result);
				this.savedData.push({ geojson });
				this.displaySavedDataOnMap(docId);

				resolve(geojson);
			};
			reader.onerror = reject;
			reader.readAsText(file);
		});
	};


	handleGPXFile = (file,docId) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const parser = new xml2js.Parser();
				parser.parseString(reader.result, (err, result) => {
					if (err) {
						console.error('Error parsing GPX file:', err);
						reject(err);
						return;
					}
					const tracks = this.extractTracksFromGPX(result);
					this.savedTracks = [...this.savedTracks, ...tracks];
					this.displayTracksOnMap(this.savedTracks,docId);
					
					// ファイル情報を保存
					this.savedData.push({ 
						type: 'gpx', 
						tracks: tracks, 
						file: file 
					});
					
					resolve(tracks);
				});
			};
			reader.onerror = reject;
			reader.readAsText(file);
		});
	};

	handleGPXForMarkers = async (file, docId) => {
		// thisを保持
		const self = this;
		
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = async () => {
				try {
					const parser = new DOMParser();
					const gpxDoc = parser.parseFromString(reader.result, "text/xml");
					const waypoints = gpxDoc.getElementsByTagName("wpt");
					const markers = [];

					for (let wpt of waypoints) {
						const lat = parseFloat(wpt.getAttribute("lat"));
						const lon = parseFloat(wpt.getAttribute("lon"));
						const ele = parseFloat(wpt.getElementsByTagName("ele")[0]?.textContent) || 0;
						const name = wpt.getElementsByTagName("name")[0]?.textContent || "";
						const cmt = wpt.getElementsByTagName("cmt")[0]?.textContent || "";
						const time = wpt.getElementsByTagName("time")[0]?.textContent || "";

						const extensions = wpt.getElementsByTagName("extensions")[0];
						const geographicaIcon = extensions?.getElementsByTagName("geographica:icon")[0]?.textContent;
						const iconNumber = geographicaIcon ? parseInt(geographicaIcon) : 0;

						const markerData = {
							name: name,
							note: cmt,
							iconNumber: iconNumber,
							lat: lat,
							lng: lon,
							elevation: ele,
							owneruid: self.fm.auth.currentUser.uid,
							ownername: self.myName,
							ownercolor: self.myColor,
							createtime: new Date(time),
							updatetime: new Date()
						};

						markers.push({ markerData });
					}

					resolve(markers);
				} catch (error) {
					console.error('Error parsing GPX:', error);
					reject(error);
				}
			};
			reader.onerror = reject;
			reader.readAsText(file);
		});
	};
	extractTracksFromGPX = (gpx) => {
		const tracks = [];
		if (gpx.gpx && gpx.gpx.trk) {
			gpx.gpx.trk.forEach((trk) => {
				trk.trkseg.forEach((trkseg) => {
					const coords = trkseg.trkpt.map((pt) => [
						parseFloat(pt.$.lon),
						parseFloat(pt.$.lat),
					]);
					tracks.push(coords);
				});
			});
		}
		return tracks;
	};

	displayDataOnMap = (geojson, i, styles = {}) => {
		if (!geojson || !geojson.features) {
			console.error('Invalid GeoJSON data', geojson);
			return;
		}
		
		geojson.features.forEach((feature, index) => {
			const sourceId = `data-${i}-${index}`;
			const layerId = `data-layer-${i}-${index}`;

			if (feature.geometry.type === 'Point' && !feature.properties._markerType) {
				// Pointの場合はMarkerとして処理
				this.addMarkerToMap(feature, sourceId);
			}
			else {
				if (feature.properties._markerType === "Circle") {
					feature = this.convertPointToCircle(feature);
				}

				if (!this.map.getSource(sourceId)) {
					this.map.addSource(sourceId, {
						type: 'geojson',
						data: feature,
					});

					const layer = {
						id: layerId,
						type: this.getLayerType(feature.geometry.type, feature.properties),
						source: sourceId,
						paint: this.getLayerPaint(feature, styles),
					};

					this.map.addLayer(layer);

					// Add click event listener
					this.map.on('click', layerId, (e) => {
						const features = this.map.queryRenderedFeatures(e.point, { layers: [layerId] });
						if (!features.length) {
							return;
						}
//						const clickedFeature = features[0];
//						this.showPopup(e.lngLat, clickedFeature.properties);
					});

					// Change the cursor to a pointer when hovering over the layer
					this.map.on('mouseenter', layerId, () => {
						this.map.getCanvas().style.cursor = 'pointer';
					});

					// Change it back to a pointer when it leaves
					this.map.on('mouseleave', layerId, () => {
						this.map.getCanvas().style.cursor = '';
					});
				} else {
					this.map.getSource(sourceId).setData(feature);
				}
			}

		});
	};
	
	addMarkerToMap = (feature, sourceId) => {
		const { coordinates } = feature.geometry;
		const properties = feature.properties;

		const el = document.createElement('div');
		el.className = 'custom-marker';
		el.style.backgroundImage =  'url(/img/marker_r.png)';
		el.style.opacity = 0.5;
		el.style.width = '30px';
		el.style.height = '30px';
		el.style.backgroundSize = '100%';

		let popupHtml = "";
		if(properties) {
			let i = 0;
			Object.entries(properties).forEach(([key, value]) => {
				if(i === 0) {
					popupHtml = `<h3>${value}</h3>`;
				}
				else {
					popupHtml = popupHtml + `<p>${key}: ${value}</p>`
				}
				i++;
			});
		}
		const marker = new maplibregl.Marker({
			element: el,
			anchor: 'center'
		})
			.setLngLat(coordinates)
			.setPopup(
				new maplibregl.Popup({ offset: 10 })
					.setHTML(popupHtml)
			)
			.addTo(this.map);

		this.markers.push({ id: sourceId, marker });
	};

	showPopup = (lngLat, properties) => {
		let popupContent = '<div>';
		for (const [key, value] of Object.entries(properties)) {
			if (key !== '_markerType' && !key.startsWith('_')) {
				popupContent += `<strong>${key}:</strong> ${value}<br>`;
			}
		}
		popupContent += '</div>';

		this.popup
			.setLngLat(lngLat)
			.setHTML(popupContent)
			.addTo(this.map);
	};
	isFileAlreadyDisplayed(fileName) {
		if (this.displayedFiles.has(fileName)) {
			return true;
		}
		for (let displayedFile of this.displayedFiles) {
			if (fileName.includes(displayedFile) || displayedFile.includes(fileName)) {
				return true;
			}
		}
		return false;
	}

	//GPXやGeoJson,KMLなどの図形ファイルを地図に表示する
	displayDataOnMapFromFile = async (filePath,docId) => {
		try {

			const style = this.map.getStyle();
			const sources = Object.keys(style.sources).filter(sourceId => sourceId.includes(docId));
			if(sources && sources.length > 0) return null;

			if (this.isFileAlreadyDisplayed(filePath)) {
				return null;
			}
			this.displayedFiles.add(filePath);
			const response = await fetch(filePath);
			if (!response.ok) {
				throw new Error('Failed to fetch file from Firebase Storage');
			}

			const contentType = response.headers.get('content-type');
			const fileData = await response.text();

			if (contentType.includes('json')) {
				const geojson = JSON.parse(fileData);
				this.savedData.push({ geojson });
				this.displaySavedDataOnMap(docId);
			}
			else if (contentType.includes('kml')) {
				const kmlIds = this.kmlSendToMap(fileData,docId);
				this.savedData.push({ type: 'kml', ids: kmlIds, filePath: filePath });
			}
			else if (contentType.includes('gpx')) {
				const parser = new xml2js.Parser();
				parser.parseString(fileData, (err, result) => {
					if (err) {
						console.error('Error parsing GPX file:', err);
						return null;
					}
					const tracks = this.extractTracksFromGPX(result);
					this.savedTracks = [...this.savedTracks, ...tracks];
					this.displayTracksOnMap(tracks,docId);
				});
			} else {
				console.error('Unsupported file type:', contentType);
			}

			return fileData;
		} catch (error) {
			console.error('Failed to load file from Firebase Storage:', error);
		}
		return null;
	};


	removeDataFromMap = (docId) => {
		const layers = this.map.getStyle().layers;
		layers.forEach(layer => {
			if (layer.id.startsWith(`data-layer-${docId}`)) {
				this.map.removeLayer(layer.id);
			}
		});

		const sources = this.map.getStyle().sources;
		Object.keys(sources).forEach(sourceId => {
			if (sourceId.startsWith(`data-${docId}`)) {
				this.map.removeSource(sourceId);
			}
		});
	};

	convertPointToCircle = (feature) => {
		const center = feature.geometry.coordinates;
		const radius = feature.properties._radius || 5; // メートル単位
		const options = { steps: 64, units: 'meters' }; // 円を構成するステップ数と単位

		const circle = turf.circle(center, radius, options);
		circle.properties = feature.properties;
		return circle;
	};


	getLayerType = (geometryType, properties) => {
		if (geometryType === 'Point' && properties._markerType === "Circle") {
			return 'fill';
		}
		switch (geometryType) {
			case 'Point':
				return 'circle';
			case 'LineString':
				return 'line';
			case 'Polygon':
				return 'fill';
			default:
				return 'line';
		}
	};

	getLayerPaint = (feature, styles = {}) => {
		const properties = feature.properties || {};
		const paint = {};

		if (feature.geometry.type === 'Polygon' && properties._markerType === "Circle") {
			paint['fill-color'] = properties._fillColor || 'rgba(255, 0, 0, 0.5)';
			paint['fill-opacity'] = properties._fillOpacity || 0.5;
			paint['fill-outline-color'] = properties._color || '#000000';
		}
		else if (feature.geometry.type === 'Point') {
			paint['circle-radius'] = properties._radius || 5;
			paint['circle-color'] = properties._fillColor || 'rgba(255, 0, 0, 0.5)';
			paint['circle-opacity'] = properties._fillOpacity || 0.5;
			paint['circle-stroke-color'] = properties._color || '#000000';
			paint['circle-stroke-width'] = properties._weight || 1;
			paint['circle-stroke-opacity'] = properties._opacity || 1.0;
		}
		else if (feature.geometry.type === 'LineString') {
			paint['line-width'] = properties._weight || 2;
			paint['line-color'] = properties._color || 'rgba(0, 0, 255, 0.5)';
			paint['line-opacity'] = properties._opacity || 0.5;
		}
		else if (feature.geometry.type === 'Polygon') {
			paint['fill-color'] = properties._fillColor || 'rgba(0, 255, 0, 0.5)';
			paint['fill-opacity'] = properties._fillOpacity || 0.5;
			paint['fill-outline-color'] = properties._color || '#000000';
		}

		return paint;
	};

	displayTracksOnMap = (tracks,docId) => {
		tracks.forEach((track, index) => {
			const sourceId = `track-${this.savedTracks.indexOf(track)}-${docId}`; 		// track-0
			const layerId = `track-layer-${this.savedTracks.indexOf(track)}-${docId}`;	// track-layer-0
			console.log('displayTracksOnMap:', sourceId);

				if (!this.map.getSource(sourceId)) {
					this.map.addSource(sourceId, {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: [{
								type: 'Feature',
								geometry: {
									type: 'LineString',
									coordinates: track,
								},
							}],
						},
					});

					this.map.addLayer({
						id: layerId,
						type: 'line',
						source: sourceId,
						paint: {
							'line-color': 'rgba(255, 30, 30, 0.5)',
							'line-width': 4,
						},
					});
				} else {
					this.map.getSource(sourceId).setData({
						type: 'FeatureCollection',
						features: [{
							type: 'Feature',
							geometry: {
								type: 'LineString',
								coordinates: track,
							},
						}],
					});
				}
			});
		};
}
