import React, { Component} from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import Marker from './Marker';
import { useGsiTerrainSource } from 'maplibre-gl-gsi-terrain';
import AddMarkerPopup from './AddMarkerPopup';
import EditMarkerPopup from './EditMarkerPopup';
import { CommonUtils } from './CommonUtils';
import PalettePopup from './PalettePopup';
import MapSharePopup from './MapSharePopup';
import './css/App.css';
import FileHandler from './FileHandler';
import CustomScaleControl from './CustomScaleControl';
import { Translator, resources } from './Translator';
import ConfirmDialog from './ConfirmDialog';
import MapWithMagneticNorthLine from './MapWithMagneticNorthLine';
import { field } from 'geomag'; // geomagからfieldをインポート
import DistanceLayer from './DistanceLayer';
import SearchPopup from './SearchPopup';
import LayerListPanel from './LayerListPanel';
import * as turf from '@turf/turf';
import DataListPanel from './DataListPanel';
import EditProjectPopup from './EditProjectPopup';
import ChatPanel from './ChatPanel';
import PhotoViewer from './PhotoViewer';
import html2canvas from 'html2canvas';
import { MapLibrePrinter } from './MapLibrePrinter';
import ProgressBar from './ProgressBar';
import elevationService from './ElevationService';
import { toast } from 'react-toastify';
import LayerSettingPopup from './LayerSettingPopup';
import CustomSource from './CustomSource';
import LoadingPopup from './LoadingPopup';
import FirebaseManager from './FirebaseManager';
import ShapeOperationPanel from './ShapeOperationPanel';


const translator = new Translator(resources);
const normalBackGround = '#ffffff';
const activeBackGround = '#8fbc18';

const _drawingTag = 'drawing';
const _shapeTag = 'shape';

const _drawUpdateTimer = 3000;
const MAX_HISTORY = 30; // 履歴の最大件数
const MAX_DATA_COUNT = 50;

class MapManager extends Component {


	gsiTerrainSourceKokudo = useGsiTerrainSource(maplibregl.addProtocol);
	terrainUrlS3 = "https://s3.amazonaws.com/elevation-tiles-prod/terrarium/{z}/{x}/{y}.png";

    tileUrlDocomo = `https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/proxyTile?baseUrl=${encodeURIComponent('https://servicearea.nttdocomo.co.jp/map/1014/0000000000000000/{z}/{x}/{z}_{x}_{y}.gif')}&z={z}&x={x}&y={y}`
    tileUrlRakuten = `https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/proxyTile?baseUrl=${encodeURIComponent('https://area-map.mobile.rakuten.co.jp/dsd/geoserver/4g/mno_coverage_map/gwc/service/gmaps?LAYERS=mno_coverage_map:all_map&FORMAT=image/png&TRANSPARENT=TRUE&x={x}&y={y}&zoom={z}')}&z={z}&x={x}&y={y}`

	gsiTerrainSourceS3 = {
		type: "raster-dem",
		tiles:[this.terrainUrlS3],
		encoding: 'terrarium',
		tileSize: 256,
		maxzoom: 15,
		minzoom: 1,
		attribution: 'ArcticDEM terrain data DEM(s) were created from DigitalGlobe, Inc., imagery and funded under National Science Foundation awards 1043681, 1559691, and 1542736; / Australia terrain data © Commonwealth of Australia (Geoscience Australia) 2017; / Austria terrain data © offene Daten Österreichs – Digitales Geländemodell (DGM) Österreich; / Canada terrain data contains information licensed under the Open Government Licence – Canada; / Europe terrain data produced using Copernicus data and information funded by the European Union - EU-DEM layers; / Global ETOPO1 terrain data U.S. National Oceanic and Atmospheric Administration / Mexico terrain data source: INEGI, Continental relief, 2016; / New Zealand terrain data Copyright 2011 Crown copyright (c) Land Information New Zealand and the New Zealand Government (All rights reserved); / Norway terrain data © Kartverket; / United Kingdom terrain data © Environment Agency copyright and/or database right 2015. All rights reserved; / United States 3DEP (formerly NED) and global GMTED2010 and SRTM terrain data courtesy of the U.S. Geological Survey.'
	};
	skyLayer =  {
		"sky-color": "#199EF3",
		"sky-horizon-blend": 0.5,
		"horizon-color": "#ffffff",
		"horizon-fog-blend": 0.5,
		"fog-color": "#0000ff",
		"fog-ground-blend": 0.5,
		"atmosphere-blend": ["interpolate",["linear"],["zoom"],0,1,10,1,12,0]
	}


	constructor(props) {
		super(props);
		
		const savedColor = localStorage.getItem('selectedColor') || '#FF0000';
		const savedOpacity = localStorage.getItem('selectedOpacity') || 80;
		const savedLineWidth = localStorage.getItem('lineWidth') || 4;

		this.state = {
			lng: 139.767125,
			lat: 35.681236,
			zoom: 10,
			map: null,
			showAddMarkerPopup:false,
			showEditMarkerPopup:false,
			showMarkerSelectPopup:false,
			showMapSharePopup:false,
			showSearchPopup:false,
			showLayerListPanel: false,
			showDataListPanel: false,
			showEditProjectPopup: false,

			searchKeyword: '',
			searchResults: [],

			is3DEnabled: false,
			showPalettePopup: false,
			isDrawingMode:0,
			isjapan : true,
			isDrawing : false,
			erasedLinesDocIds: new Set(), 
			projectTitle:"",
			ownerName:"",

			penButtonBG: normalBackGround,
			shapeButtonBG: normalBackGround,
			eraserButtonBG: normalBackGround,

			selectedColor: savedColor,
			selectedOpacity: parseInt(savedOpacity, 10),
			lineWidth: parseInt(savedLineWidth, 10),

			showConfirmDialog:false,
			showConfshowOkConfirmDialogirmDialog:false,

			confirmDialog:({
				message: '',
				data:null,
				onConfirm: () => {},
				onCancel: () => {},
			}),
			okConfirmDialog:({
				message: '',
				data:null,
				onConfirm: () => {},
			}),
			permission:0,
			showDistanceLayer: false,
			labelScaleDistance: '',
			currentLocation:null,

			shapeDrawingMode: null,
			lineButtonBG: activeBackGround,
			circleButtonBG: normalBackGround,
			polygonButtonBG: normalBackGround,
			measurementButtonBG: normalBackGround,
			currentShape: null,
			shapePoints: [],
			circleRadius: 0,
			showChatPanel: false,
			selectedPhoto: null,

			photoMarkers: [],
			currentPhotoIndex: 0,
			isPhotoViewerFullscreen: false,
			isfavorite: false,
			isShowUI:true,
			showThumbnailPopup: false,
			showPrintMap: false,
			uploadProgress: null,
			centerElevation: null,
			showLayerSettingPopup: false,
			files:[],
			showLoadingPopup: false,
			loadingMessage: "",
			exaggeration: 1,

			showShapeOperationPanel: false,
			operationPanelPosition: null
		};
		this.latestClickTime = null;//最新クリック時間

		this.lastTap = 0;
		this.tapTimeout = null;

		this.terrainControl = null;

		this.currentOverlay = null;
		this.currentMap = null;

		this.thumbnailUrl = null;
		this.imageUrl = null;

		this.updateProjectInfo = props.updateProjectInfo;

		this.drawingHistory = [];

		this.currentProject = null;
		this.myDrawing = null;
		this.myShape = null;

		if(this.props.fm.auth.currentUser) {
			this.myDrawing = _drawingTag + '_' + this.props.fm.auth.currentUser.uid;
			this.myShape = _shapeTag  + '_' + this.props.fm.auth.currentUser.uid;
		}

		this.markers = [];
		this.currentMarker = null;

		this.currentLine =  [];//this.stateに入れていると描画時にレンダリングが呼ばれて重くなるので外に出した
		this.isSpaceKeyPressed = false;
		this.isAltKeyPressed = false;
		this.isInitializeMap = false;
		this.editingMarker = null;
		this.shapeDrawing = false;
		this.isLoadedDrawingData = false;

		this.mapContainerRef = React.createRef();
		this.fileHandler = null;
		this.customScale = null;
		this.defaultIconNumber = 0;
		this.drawUpdatetimerId = null;
		this.shapeUpdatetimerId = null;

		this.lastUpdate = null;

		this.customOverlays = {
			'custom_1':{ type:'', name: 'custom_1', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:40,cc:0 ,proxy:false},
			'custom_2':{ type:'', name: 'custom_2', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:40,cc:0 ,proxy:false},
			'custom_3':{ type:'', name: 'custom_3', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:40,cc:0 ,proxy:false},
			'custom_4':{ type:'', name: 'custom_4', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:40,cc:0 ,proxy:false},
			'custom_5':{ type:'', name: 'custom_5', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:40,cc:0 ,proxy:false},
		};

		this.customMaps = {
			'custom_1':{ type:'', name: 'custom_1', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:100,cc:0 ,proxy:false},
			'custom_2':{ type:'', name: 'custom_2', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:100,cc:0 ,proxy:false},
			'custom_3':{ type:'', name: 'custom_3', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:100,cc:0 ,proxy:false},
			'custom_4':{ type:'', name: 'custom_4', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:100,cc:0 ,proxy:false},
			'custom_5':{ type:'', name: 'custom_5', cp: '', url: '',ts:256, minz:1 ,mz:18,bp:0,op:100,cc:0 ,proxy:false},
		};
	}
	startLoading = (loadingMessage) => {
		this.setState({ showLoadingPopup: true,loadingMessage:loadingMessage });
	}

	// 処理終了時に呼び出す
	endLoading = () => {
		this.setState({ showLoadingPopup: false });
	}
	
	updateCenterElevation = async () => {
		//console.log("updateCenterElevation");
		if(!this.state.map) return;

		const center = this.state.map.getCenter();
		try {
			const elevation = await elevationService.getElevation(center.lat, center.lng);
			this.setState({ centerElevation: elevation });
		} catch (error) {
			console.error('Error fetching elevation:', error);
			this.setState({ centerElevation: null });
		}
	};

	toggleDistanceLayer = () => {
		this.setState(prevState => ({
			showDistanceLayer: !prevState.showDistanceLayer
		}));
	};

	handleFileUpload = async (event) => {
		if(event.target.files && this.fileHandler) {
			this.setState({ uploadProgress: { current: 0, total: event.target.files.length } });

			this.fileHandler.handleFileUpload(event,this.props.myName,this.props.myColor,this.getDataCount(),MAX_DATA_COUNT,
				(current, total) => this.setUploadProgress({ current, total }),
				()=>{
					const t = (key) => translator.t(key);
					toast.info(t('max_data_message_B')); 
				});
		}
	};

	setUploadProgress = ({ current, total }) => {
		this.setState({ uploadProgress: { current, total } });
		if(current === total) setTimeout(() => this.setState({ uploadProgress: null }), 1000);
	}

	//操作履歴関連-------------------------
	addToHistory = () => {
//			console.log('addToHistory');
		if(!this.props.fm.auth || !this.props.fm.auth.currentUser) return;

		const source_d = this.state.map.getSource(this.myDrawing);
		const source_s = this.state.map.getSource(this.myShape);
		let data_d = null;
		let data_s = null;

		if(source_d) data_d = source_d._data;
		if(source_s) data_s = source_s._data;

		if(!data_d && !data_s) return;

		const action = {
			sourceId_d: this.myDrawing,
			data_d:JSON.parse(JSON.stringify(data_d)),
			sourceId_s: this.myShape,
			data_s:JSON.parse(JSON.stringify(data_s)),
		};

		this.drawingHistory.push(action);

/*		this.drawingHistory.forEach(action3 => {
			if(action3.data_d && action3.data_d.features)
				console.log('addToHistory: length:' + action3.data_d.features.length);
		});
			console.log('');
*/

		// 履歴が最大件数を超えた場合、古いものから削除
		if (this.drawingHistory.length > MAX_HISTORY) {
			this.drawingHistory.splice(0,this.drawingHistory.length - MAX_HISTORY);
		}

	};

	undo = () => {
		const { map } = this.state;
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);

//		console.log('undo: drawUpdatetimerId:' + this.drawUpdatetimerId + ' shapeUpdatetimerId:' + this.shapeUpdatetimerId.length);

		if (this.drawingHistory.length > 1) {
			const prevAction = this.drawingHistory[this.drawingHistory.length - 1];
			this.drawingHistory.pop();
			this.applyAction(prevAction);

			const center = map.getCenter();

			const source_d = map.getSource(prevAction.sourceId_d);
			if (source_d) {
				this.drawUpdatetimerId = setTimeout(() => {
					const geoJSON = source_d._data;
					this.updateDrawingData(2,geoJSON,_drawingTag,center.lng,center.lat);
				}, _drawUpdateTimer);
			}
			const source_s = map.getSource(prevAction.sourceId_s);
			if (source_s) {
				this.shapeUpdatetimerId = setTimeout(() => {
					const geoJSON = source_s._data;
					this.updateDrawingData(3,geoJSON,_shapeTag,center.lng,center.lat);
				}, _drawUpdateTimer);
			}
		}
		else {
			this.addToHistory();
		}
	};

	applyAction = (action) => {
		if(!action) return;
		const { map } = this.state;
		const source_d = map.getSource(action.sourceId_d);
		const source_s = map.getSource(action.sourceId_s);

		if (source_d && action.data_d && action.data_d) {
			source_d.setData(action.data_d);
		}
		if (source_s && action.data_s && action.data_s) {
			source_s.setData(action.data_s);
		}
	};

	//--------------------イベント処理

	//フォーカス
	handleVisibilityChange = () => {
	};

	componentDidMount() {
		this.initializeMap();
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener('mouseout', this.handleMouseOut);

		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('keyup', this.handleKeyUp);
		window.addEventListener('keypress', this.handleKeypress);

		// 保存された設定を適用
		const savedColor = localStorage.getItem('selectedColor');
		const savedOpacity = localStorage.getItem('selectedOpacity');
		const savedLineWidth = localStorage.getItem('lineWidth');

		if (savedColor) this.setState({ selectedColor: savedColor });
		if (savedOpacity) this.setState({ selectedOpacity: parseInt(savedOpacity, 10) });
		if (savedLineWidth) this.setState({ lineWidth: parseInt(savedLineWidth, 10) });

		document.addEventListener('visibilitychange', this.handleVisibilityChange);
	}


	componentWillUnmount() {
		const { map } = this.state;
		if (map) {
			map.off('mousedown', this.startDrawing);
			map.off('mousemove', this.drawLine);
			map.off('mouseup', this.endDrawing);
			map.off('touchstart', this.startDrawing);
			map.off('touchmove', this.drawLine);
			map.off('touchend', this.endDrawing);
			map.off('mouseout', this.handleMouseOut);

//			map.off('dblclick', this.handleDoubleClick);

			map.off('moveend', this.updateCenterElevation);

			map.off('touchstart', this.handleTouchStart);
			map.off('touchend', this.handleTouchEnd);
		}
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener('keypress', this.handleKeypress);
		//window.removeEventListener('mouseout', this.handleMouseOut);

		// リスナーの解除
		if (this.unsubscribeDocListener) {
			this.unsubscribeDocListener();
		}
		if (this.unsubscribeColListener) {
			this.unsubscribeColListener();
		}
		document.removeEventListener('visibilitychange', this.handleVisibilityChange);
	}
	
	handleMapMove = () => {
		this.markers.forEach(markerObj => {
			const popup = markerObj.marker.marker.getPopup();
			if (popup && popup.isOpen()) {
				markerObj.marker.closePopup();
			}
		});
	};

	//画面外でマウスのボタンが離されたらそこで描画終了
	handleMouseOut = (e) => {
		if (!this.state.isDrawing) return;
		if(this.isAltKeyPressed || this.state.isDrawingMode) {
			console.log('handleMouseOut');
			this.isAltKeyPressed = false;
			this.setState({ isDrawing: false });

			const { map} = this.state;

			map.dragPan.enable();//これがdrawイベントにあるとタッチペンの挙動がおかしくなる

			const source = map.getSource(this.myDrawing);
			if(source) {
				const data = source._data;

				if (data && data.features) {
					source.setData(data);
				}
			}
			this.currentLine = [];
//			this.endDrawingFunc(e.lngLat);
			this.endDrawing(e);
		}
	}

	isOpenOtherPopup() {
		return (this.state.showChatPanel || this.state.showSearchPopup || this.state.showEditMarkerPopup || this.state.showAddMarkerPopup || this.state.showEditProjectPopup);
	}

	handleKeyDown = (e) => {
		const { map ,isDrawingMode} = this.state;

		if (this.isOpenOtherPopup() || e.target.tagName.toLowerCase() === 'input') {
			return;
		}

		if(this.state.permission === 2) {
			if (e.key === 'Alt') {
				this.isAltKeyPressed = true;
				this.state.map.dragPan.disable();

				map.getCanvas().style.cursor = 'url(/img/pen.png) 0 32, auto';
			}
			else if (e.key === ' ' || e.key === 'Spacebar') {
				this.isSpaceKeyPressed = true;
				this.state.map.dragPan.enable();
				//map.getCanvas().style.cursor = 'grab';
			}

			if (e.ctrlKey && e.key === 'z') {
				this.undo();
			}
			// Ctrl+V の処理を追加
			if (e.ctrlKey && e.key === 'v') {
				this.handlePasteMarker();
			}			

			if (e.ctrlKey && e.key === 'c') {
				if(this.currentMarker) {
					this.currentMarker.copyToClipboard();
				}
			}			
		}		
	};


	checkClipboardPasteMarker = async () => {
		const clipboardText = await navigator.clipboard.readText();
		if(!clipboardText || clipboardText.length < 1)	return false;
		try {
			const clipboardData = JSON.parse(clipboardText);
			if (clipboardData.app === "CollaboMap") return true;
			else return false;
		}
		catch(error) {
		}
		return false;
	}
	// 新しい非同期関数を作成してクリップボードの処理を行う
	handlePasteMarker = async () => {
		try {
			const clipboardText = await navigator.clipboard.readText();
			if(!clipboardText || clipboardText.length < 1)	return;
			const clipboardData = JSON.parse(clipboardText);
			await navigator.clipboard.writeText("");
			if (clipboardData.app === "CollaboMap") {
				// マーカーデータの抽出
				const markerData = {
					lng: clipboardData.lng,
					lat: clipboardData.lat,
					elevation: clipboardData.elevation,
					name: clipboardData.name,
					note: clipboardData.note,
					iconNumber: clipboardData.iconNumber
				};
				if (this.state.map) {
					this.state.map.flyTo({
						center: [clipboardData.lng, clipboardData.lat],
					});
				}
				this.handleAddMarker(markerData);
			}
		} catch (error) {
			console.error('Failed to paste marker:', error);
		}
	};

	handleKeyUp = (e) => {
		const { map ,isDrawingMode} = this.state;
		if (this.isOpenOtherPopup() || e.target.tagName.toLowerCase() === 'input') {
			return;
		}

		if(this.state.permission === 2) {
			if (e.key === 'Alt') {
				this.isAltKeyPressed = false;
				if(!this.state.isDrawingMode) this.state.map.dragPan.enable();
				map.getCanvas().style.cursor = 'grab';
			}
			if (e.key === ' ' || e.key === 'Spacebar') {
				this.isSpaceKeyPressed = false;
				this.state.map.dragPan.disable();

				if(isDrawingMode === 1) map.getCanvas().style.cursor = 'url(/img/pen.png) 0 32, auto';
				else if(isDrawingMode === 2) map.getCanvas().style.cursor = 'url(/img/eraser.png) 0 32, auto';
				else if(isDrawingMode === 3) map.getCanvas().style.cursor = 'crosshair';
			}
		}
	};

	handleKeypress = (e) => {
		if (this.isOpenOtherPopup() || e.target.tagName.toLowerCase() === 'input') {
			return;
		}

		//編集系機能
		if(this.state.permission === 2) {
			if (e.key === 'e') {
				this.clickEraserButton();
			}
			if (e.key === 'b') {
				this.clickPenButton();
			}
			if (e.key === 'p') {
				this.setState({ showPalettePopup: true });
			}
			if (e.key === 'm') {
				this.setState({ showAddMarkerPopup: true });
			}
		}

		//一般機能
		if (e.key === 'd') {
			const { map, is3DEnabled, exaggeration } = this.state;
			if (!map) return;

			if (!is3DEnabled) {
				if (map.getPitch() === 0) map.setPitch(40);
				map.setTerrain({ source: 'terrain', exaggeration: exaggeration });
				this.setState({ is3DEnabled: true });
				setTimeout(() => {
					this.customScale.update();
				}, 100);
			} else {
				this.clickPoszeroButton();
			}
		}

	};

	//図形描画開始
	//直線：マウスダウンしてそのまま移動、離したら確定
	//円：マウスダウンしてそのまま移動、離したら確定
	//ポリゴン：マウスダウンして移動、マウスアップでポイント追加、
	startShape = (e) => {
		const { map } = this.state;
		if (this.state.isDrawingMode === 3) {
			//準備
			let source = map.getSource(this.myShape);
			if(!source || source === undefined) {
				source = this.addShapeSource(map,this.myShape);
				this.addShapeLayer(map,this.myShape);
				this.addLabelSourceAndLayer(map,this.myShape);
			}

			source = map.getSource(this.myDrawing);
			if(!source || source === undefined) {
				source = this.addDrawingSource(map,this.myDrawing);
				this.addDrawingLayer(map,this.myDrawing);
			}

			const { shapeDrawingMode } = this.state;
			const lngLat = e.lngLat;
			
			//直線
			if (shapeDrawingMode === 'line') {
				this.clickLineDrawing(lngLat);
			}

			//円
			else if (this.state.shapeDrawingMode === 'circle') {
				if(this.shapeDrawing) {
					this.finishCircleDrawing(lngLat);
				}
				else {
					this.shapeDrawing = true;
					this.startCircleDrawing(lngLat);
				}
			}

			//ポリゴン
			else if (shapeDrawingMode === 'polygon') {
				if(!this.shapeDrawing) {
					this.startPolygonDrawing(lngLat);
				}
			}

			//計測
			if (shapeDrawingMode === 'measurement') {
				//計測の始点を決める
				this.clickMeasurement(lngLat);				
			}
		}
	};

	endShapeAll() {
		this.endShape(null);
	}

	//クリック
	handleMapClick = (e) => {
		if (this.state.isDrawingMode === 3) {//図形
			return;
		}
	};

	//ダブルクリック　描画終了
	handleDoubleClick = (e) => {
		if (this.shapeDrawing && this.state.isDrawingMode === 3) {
			if(this.state.shapeDrawingMode === 'line') {
				this.finishLineDrawing(e.lngLat);
			}
			else if(this.state.shapeDrawingMode === 'polygon') {
				this.finishPolygonDrawing();
			}
			else if(this.state.shapeDrawingMode === 'measurement') {
				this.finishMeasurement(e.lngLat);
			}
			this.resetMapInteraction();
		}
		this.props.fm.checkAndSaveCurrentPosition(this.props.owneruid,this.props.uuid,this.state.map,this.currentProject);

	};

	//図形 マウスアップ
/*	endShape = (e) => {
		if (this.shapeDrawing && this.state.isDrawingMode === 3) {
		
			let type = null;
			let id = null;

			//直線
			if (this.state.shapeDrawingMode === 'line') {
				this.finishLineDrawing(e.lngLat);
				type = _drawingTag;
				id = this.myDrawing;
			}

			//円
			else if (this.state.shapeDrawingMode === 'circle') {
				this.finishCircleDrawing(e.lngLat);
				type = _shapeTag;
				id = this.myShape;
			}

			//ポリゴン
			else if (this.state.shapeDrawingMode === 'polygon') {
				this.addPointPolygon(e.lngLat);
				type = _shapeTag;
				id = this.myShape;
			}

			//計測
			else if (this.state.shapeDrawingMode === 'measurement') {
				this.updateMeasurement(e.lngLat,true);
				console.log('endShape:');
//				type = _drawingTag;
//				id = this.myDrawing;

			}

			this.props.fm.checkAndSaveCurrentPosition(this.props.owneruid,this.props.uuid,this.state.map,this.currentProject);

//			this.addToHistory();
		}
	};*/

	endShape = (e) => {
		if (this.shapeDrawing && this.state.isDrawingMode === 3) {
			// 円の場合は従来通りの処理
			if (this.state.shapeDrawingMode === 'circle') {
				this.finishCircleDrawing(e.lngLat);
				this.resetMapInteraction();
				return;
			}

			// その他の図形の場合は操作パネルを表示
			const point = this.state.map.project(e.lngLat);
			this.setState({
				showShapeOperationPanel: true,
				operationPanelPosition: { x: point.x, y: point.y }
			});

			if (this.state.shapeDrawingMode === 'line') {
				this.updateLineDrawing(e.lngLat);
			}
			else if (this.state.shapeDrawingMode === 'polygon') {
				this.addPointPolygon(e.lngLat);
			}
			else if (this.state.shapeDrawingMode === 'measurement') {
				this.updateMeasurement(e.lngLat, true);
			}
		}
	};

	//マップの操作状態をリセット
	resetMapInteraction = () => {
	const { map } = this.state;
	if (!map) return;

	// マーカーのポインターイベントを再有効化
	this.changeMarkersPointerEvents(true);

	// マップの操作を通常状態に戻す
	map.dragPan.enable();
	map.doubleClickZoom.enable();
	map.touchZoomRotate.enable();
	map.getCanvas().style.cursor = 'grab';

	// 図形描画モードをリセット
	this.shapeDrawing = false;
	};


	//線描画
	clickLineDrawing = (lngLat) => {
		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);

		const { shapePoints } = this.state;
		const newPoints = [...shapePoints, [lngLat.lng, lngLat.lat]];

		this.setState({ shapePoints: newPoints });

		if (newPoints.length > 0) {
			const lineFeature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
			};
			this.updateShapeLineSource(lineFeature);
		}
	};



	updateLineDrawing = (lngLat) => {//マウスが動いてるとき
		const { shapePoints } = this.state;
		const newPoints = [...shapePoints, [lngLat.lng, lngLat.lat]];

		if (newPoints.length > 1) {
			const lineFeature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
				}
			};
			this.updateShapeLineSource(lineFeature);//線更新
		}
	};

	//線更新
	updateShapeLineSource = (feature) => {
		const { map } = this.state;
		let source = map.getSource(this.myDrawing);
		
		if (source) {
			const geoJSON = source._data;

			if(this.shapeDrawing) {
				geoJSON.features[geoJSON.features.length - 1] = feature;
				console.log("updateShapeLineSource:1");
			}
			else {
				geoJSON.features.push(feature);
				this.shapeDrawing = true;
				console.log("updateShapeLineSource:2");
			}

			source.setData(geoJSON);
		}
	};

	// 新しいメソッド
	removeLastPoint = () => {
		const { shapePoints } = this.state;
		if (shapePoints.length > 1) {
			const newPoints = shapePoints.slice(0, -1);
			this.setState({ shapePoints: newPoints }, () => {
			// 現在の描画モードに応じて適切な更新処理を呼び出す
			if (this.state.shapeDrawingMode === 'line' || this.state.shapeDrawingMode === 'measurement') {
				const lineFeature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
				};
				this.updateShapeLineSource(lineFeature);
			} else if (this.state.shapeDrawingMode === 'polygon') {
				const polygonFeature = {
					type: 'Feature',
					geometry: {
						type: 'Polygon',
						coordinates: [newPoints]
					},
					properties: {
						color: this.state.selectedColor,
						width: this.state.lineWidth
					}
				};
				this.updateShapeSource(polygonFeature);
			}
			});
		}
	};


	//線終了
	finishLineDrawing = (lngLat) => {
		const { shapePoints,map } = this.state;
		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);
		const newPoints = [...shapePoints, [lngLat.lng, lngLat.lat]];

		if (newPoints.length > 1) {
			const lineFeature = {
				type: 'Feature',
				geometry: {
				type: 'LineString',
				coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
			};
			this.updateShapeLineSource(lineFeature);
			this.setState({ shapePoints: [], currentShape: null });

			// GeoJSONをFirestorageに保存
			let source = map.getSource(this.myDrawing);
			if (source) {
				this.drawUpdatetimerId = setTimeout(() => {
					const geoJSON = source._data;
					this.updateDrawingData(2,geoJSON,_drawingTag,lngLat.lng,lngLat.lat);
				}, _drawUpdateTimer);
			}
		}

		this.shapeDrawing = false;
	};

	//計測線開始
	clickMeasurement = async(lngLat) => {
		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);
		const { shapePoints } = this.state;
	
		const elevation = await elevationService.getElevation(lngLat.lat, lngLat.lng);
		const newPoints = [ ...shapePoints,[lngLat.lng, lngLat.lat, elevation]];

		this.setState({ shapePoints: newPoints });

		if (newPoints.length > 0) {
			const lineFeature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
			};
			this.updateShapeLineSource(lineFeature);
		}
	};

	//計測の線を更新
	updateMeasurement = async(lngLat,isAdd = false) => {//マウスが動いてるとき
		const { shapePoints } = this.state;
		const elevation = await elevationService.getElevation(lngLat.lat, lngLat.lng);
		const newPoints = [...shapePoints, [lngLat.lng, lngLat.lat,elevation]];

		let distance = 0;
		if (newPoints.length > 1) {
			distance = CommonUtils.calculateDistanceWithElevationArray(newPoints);
			const eleDiff =	CommonUtils.calculateDiffElevationArray(newPoints);
			const walkSpeed = FirebaseManager.getWalkSpeed(this.props.userSettings.walkingSpeed);
			const time = CommonUtils.calcTimeArray(newPoints,walkSpeed);

			const lineFeature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
					distance:distance,
					time:time,
					altitudeDifferenceAscent:eleDiff.ascent,
					altitudeDifferenceDescent:eleDiff.descent,
				}
			};
			this.updateShapeLineSource(lineFeature);//線更新
			if(isAdd) {
				//console.log('updateMeasurement:' + distance + ":"  + time);
				this.setState({ shapePoints: newPoints, currentShape: null });
			}
		}
	};

	//計測終了
	finishMeasurement = async(lngLat) => {
		const { shapePoints,map } = this.state;
		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);
		const elevation = await elevationService.getElevation(lngLat.lat, lngLat.lng);
		const newPoints = [...shapePoints, [lngLat.lng, lngLat.lat, elevation]];

		let distance = 0;
		if (newPoints.length > 1) {
			distance = CommonUtils.calculateDistanceWithElevationArray(newPoints);

			const eleDiff =	CommonUtils.calculateDiffElevationArray(newPoints);
			const walkSpeed = FirebaseManager.getWalkSpeed(this.props.userSettings.walkingSpeed);
			const time = CommonUtils.calcTimeArray(newPoints,walkSpeed);
			console.log('finishMeasurement:' + distance + ":"  + time);

			const lineFeature = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newPoints
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
					distance:distance,
					time:time,
					altitudeDifferenceAscent:eleDiff.ascent,
					altitudeDifferenceDescent:eleDiff.descent,
				}
			};

			// GeoJSONをFirestorageに保存
			let source = map.getSource(this.myDrawing);
			this.updateShapeLineSource(lineFeature);//線更新
			if (source) {
				this.drawUpdatetimerId = setTimeout(() => {
					const geoJSON = source._data;
					this.updateDrawingData(2,geoJSON,_drawingTag,lngLat.lng,lngLat.lat);
				}, _drawUpdateTimer);
			}
		}

		this.setState({ shapePoints: [], currentShape: null });
		this.shapeDrawing = false;
	};


	//円開始
	startCircleDrawing = (lngLat) => {
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		const center = [lngLat.lng, lngLat.lat];
		this.setState({
			currentShape: {
			center: center,
			radius: 0
			}
		});
		this.shapeDrawing = true;

		const circleFeature = {
			type: 'Feature',
			geometry: {
				type: 'GeometryCollection',
				geometries: [
					{
						type: 'Point',
						coordinates: center
					},
					turf.circle(center, 0, { steps: 32, units: 'meters' }).geometry
				]
			},
			properties: {
				color: this.state.selectedColor,
				width: this.state.lineWidth,
				radius: 0,
				centerPointColor: this.state.selectedColor,
				centerPointRadius: this.state.lineWidth
			}
		};		
		this.startShapeSource(circleFeature);
	};

	//円更新
	updateCircleDrawing = (lngLat) => {
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		const { currentShape } = this.state;
		if (currentShape && this.shapeDrawing) {
			const center = turf.point(currentShape.center);
			const edge = turf.point([lngLat.lng, lngLat.lat]);
			const radius = turf.distance(center, edge, { units: 'meters' });

			const circleFeature = {
				type: 'Feature',
				geometry: {
					type: 'GeometryCollection',
					geometries: [
						{
							type: 'Point',
							coordinates: currentShape.center
						},
						turf.circle(currentShape.center, radius, { steps: 32, units: 'meters' }).geometry
					]
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
					radius: radius,
					centerPointColor: this.state.selectedColor,
					centerPointRadius: this.state.lineWidth
				}
			};
			this.updateShapeSource(circleFeature);
		}
	};

	//円終了
	finishCircleDrawing = (lngLat) => {
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		const { currentShape, map } = this.state;
		if (currentShape && this.shapeDrawing) {
			const center = turf.point(currentShape.center);
			const edge = turf.point([lngLat.lng, lngLat.lat]);
			const radius = turf.distance(center, edge, { units: 'meters' });
			const area = CommonUtils.calculateCircleArea(radius);

			const circleFeature = {
				type: 'Feature',
				geometry: {
					type: 'GeometryCollection',
					geometries: [
						{
							type: 'Point',
							coordinates: currentShape.center,
						},
						turf.circle(currentShape.center, radius, { steps: 32, units: 'meters' }).geometry
					]
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
					radius: radius,
					area:area,
					centerPointColor: this.state.selectedColor,
					centerPointRadius: this.state.lineWidth
				}
			};

			this.updateShapeSource(circleFeature);
			this.setState({ currentShape: null });
			this.shapeDrawing = false;

			// GeoJSONをFirestorageに保存
			let source = map.getSource(this.myShape);
			if (source) {
				this.shapeUpdatetimerId = setTimeout(() => {
					const geoJSON = source._data;
					this.updateDrawingData(3, geoJSON, _shapeTag, center.geometry.coordinates[0], center.geometry.coordinates[1]);
				}, _drawUpdateTimer);
			}
		}
	};

	//ポリゴン開始
	startPolygonDrawing = (lngLat) => {
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		this.setState({ shapePoints: [], currentShape: null });
		const start = [lngLat.lng, lngLat.lat];
		const newPoints = [start];
		this.setState({ shapePoints: newPoints });

		if (newPoints.length > 0) {
			const polygonFeature = {
				type: 'Feature',
				geometry: {
					type: 'Polygon',
					coordinates: [newPoints]
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
			};
			this.startShapeSource(polygonFeature);
			this.shapeDrawing = true;
		}
	};

	updatePolygonDrawing = (lngLat) => {
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		const { shapePoints } = this.state;
		if (shapePoints.length > 0) {
			const updatedPoints = [...shapePoints,[lngLat.lng, lngLat.lat]];
			const polygonFeature = {
				type: 'Feature',
				geometry: {
					type: 'Polygon',
					coordinates: [updatedPoints]
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
			};
			this.updateShapeSource(polygonFeature);
		}
	};

	//ポリゴン更新
	addPointPolygon = (lngLat) => {
		const { shapePoints,map } = this.state;

		if (shapePoints.length > 0) {
			const updatedPoints = [...shapePoints,[lngLat.lng, lngLat.lat]];
			this.setState({ shapePoints: updatedPoints });
			const polygonFeature = {
				type: 'Feature',
				geometry: {
					type: 'Polygon',
					coordinates: [updatedPoints]
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth
				}
			};
			this.updateShapeSource(polygonFeature);
		}
	};

	//ポリゴン終了
	finishPolygonDrawing = () => {
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);
		const { shapePoints,map } = this.state;
		if (shapePoints.length > 2) {
			//const closedPoints = [...shapePoints, shapePoints[0]];
			const closedPoints = CommonUtils.removeDuplicateConsecutiveCoordinates([...shapePoints, shapePoints[0]]);

			const totalDistance = CommonUtils.calculateTotalDistance(closedPoints);
			const area = CommonUtils.calculatePolygonArea(closedPoints);
			const center = CommonUtils.calculateCentroid(closedPoints);
			const polygonFeature = {
				type: 'Feature',
				geometry: {
					type: 'Polygon',
					coordinates: [closedPoints]
				},
				geometries: [
					{
						type: 'Point',
						coordinates: center,
					},
				],
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
					area: area,
					totalDistance: totalDistance,
				}
			};
			this.updateShapeSource(polygonFeature);
			this.setState({ shapePoints: [], currentShape: null });
			this.shapeDrawing = false;

			// GeoJSONをFirestorageに保存
			let source = map.getSource(this.myShape);
			if (source) {
				this.shapeUpdatetimerId = setTimeout(() => {
					const geoJSON = source._data;
					this.updateDrawingData(3,geoJSON,_shapeTag,shapePoints[0][0],shapePoints[0][1]);
				}, _drawUpdateTimer);
			}

		}
	};

	//図形開始
	startShapeSource = (feature) => {
		const { map } = this.state;
		const source = map.getSource(this.myShape);
		if (source) {
			const geoJSON = source._data;
			geoJSON.features.push(feature);
			source.setData(geoJSON);
		}
	};


	//図形更新
	updateShapeSource = (feature) => {
		const { map } = this.state;
		const source = map.getSource(this.myShape);
		if (source) {
			const geoJSON = source._data;
			if(geoJSON.features.length < 1) {
				geoJSON.features.push(feature);
			}
			else {
				geoJSON.features[geoJSON.features.length - 1] = feature;
			}
			source.setData(geoJSON);
		}
	};

	//----------------------------------------
	//isDrawingMode 1:フリーハンド　2:消しゴム　3:図形

	//描画開始 mousedown touchstart
	startDrawing = (e) => {
		const originalEvent = e.originalEvent;

	    // マウスイベントの場合
		if (originalEvent instanceof MouseEvent) {
			// 左ボタンでないばあい
			if (originalEvent.button !== 0) {
				return;
			}
		} 

		if(this.isSpaceKeyPressed) return;

		const { map} = this.state;
		if (!map) return;

		this.latestClickTime = new Date();
		
		if (!this.isAltKeyPressed && (this.state.isDrawingMode === 0)) return;//Alt押しながらで描ける

		if(this.props.fm.auth.currentUser) {//ログインしている
			this.myDrawing = _drawingTag  + '_' + this.props.fm.auth.currentUser.uid;
			this.myShape = _shapeTag  + '_' + this.props.fm.auth.currentUser.uid;
		}
		else return;


		// タッチしている指やペンの数を取得
		let touchCount = 0;
		if (originalEvent.touches) {
			touchCount = originalEvent.touches.length;
		}
//		const testLabel = document.getElementById('testLabel');
//		testLabel.innerText = `Touch count startDrawing: ${touchCount}`;

		//タッチ点が１より大きければズームやパンを有効にする
		if(touchCount > 1) {
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			return;
		}
		else {
			map.touchZoomRotate.disable();
			map.dragPan.disable();
			originalEvent.preventDefault();//イベントを下のビューに伝播させない
			originalEvent.stopPropagation();
		}

		this.changeMarkersPointerEvents(false);//描画中、マーカーを一時的にUI無効にする

		this.addToHistory();
//		this.lastUpdate = new Date();

		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);

		if (this.state.isDrawingMode === 3) {//図形
			this.startShape(e);			
			return;
		}

		const newLine = [e.lngLat.toArray()];
		let source = map.getSource(this.myDrawing);

		if(!source || source === undefined) {
			source = this.addDrawingSource(map,this.myDrawing);
			this.addDrawingLayer(map,this.myDrawing);
		}

		const data = source._data;

		if (data && data.features) {
			data.features.push({
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: newLine
				},
				properties: {
					color: this.state.selectedColor,
					width: this.state.lineWidth,
				}
			});
			source.setData(data);
		}
		this.currentLine = newLine;
		this.setState({ isDrawing: true });
	};

	//描画中 mousemove
	drawLine = (e) => {
		const { map ,isDrawingMode} = this.state;

		const originalEvent = e.originalEvent;

	    // マウスイベントの場合
		if (map && originalEvent instanceof MouseEvent) {
			// なんらかの描画中でボタンが押されていないばあい
			if ((isDrawingMode === 1 || isDrawingMode === 2) && originalEvent.buttons !== 1) {
				this.endDrawing(e);
			}
        
			if((isDrawingMode !== 0)) {
				if(this.isSpaceKeyPressed) {
//				console.log(originalEvent.buttons);
					if(originalEvent.buttons === 0) {
						map.getCanvas().style.cursor = 'grab';
					}
					else if(originalEvent.buttons >= 1  ) {   
						map.getCanvas().style.cursor = 'grabbing';//ここが効いてないっぽい　わからん
					}
				}
				else {
					if(isDrawingMode === 1) map.getCanvas().style.cursor = 'url(/img/pen.png) 0 32, auto';
					else if(isDrawingMode === 2) map.getCanvas().style.cursor = 'url(/img/eraser.png) 0 32, auto';
					else if(isDrawingMode === 3) map.getCanvas().style.cursor = 'crosshair';
				}
			}

			if(this.isAltKeyPressed) {
				map.getCanvas().style.cursor = 'url(/img/pen.png) 0 32, auto';
			}
		}
		
		if(this.isSpaceKeyPressed) return;

		if (!this.state.isDrawing && !this.shapeDrawing) return;

		this.latestClickTime = new Date();

		// タッチしている指やペンの数を取得
		let touchCount = 0;
		if (originalEvent.touches) {
			touchCount = originalEvent.touches.length;
		}

//		const testLabel = document.getElementById('testLabel');
//		testLabel.innerText = `Touch count drawLine: ${touchCount}`;

		if(touchCount > 1) {
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			return;
		}
		else {
			map.touchZoomRotate.disable();
			map.dragPan.disable();
			originalEvent.preventDefault();//イベントを下のビューに伝播させない
			originalEvent.stopPropagation();
		}

		if (isDrawingMode === 3 && this.shapeDrawing) {
			if (this.state.shapeDrawingMode === 'line') {
				this.updateLineDrawing(e.lngLat);
			}
			else if(this.state.currentShape) {
				if (this.state.shapeDrawingMode === 'circle') {
					this.updateCircleDrawing(e.lngLat);
				}
			}
			else if (this.state.shapeDrawingMode === 'polygon') {
				this.updatePolygonDrawing(e.lngLat);
			}
			else if (this.state.shapeDrawingMode === 'measurement') {
				this.updateMeasurement(e.lngLat);
			}

			return;
		}

		// フリーハンド描画処理
		if (isDrawingMode === 1) {
			// 描画中のラインのみのソースを作成
			if (!map.getSource('currentDrawing')) {
				map.addSource('currentDrawing', {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: [{
							type: 'Feature',
							geometry: {
								type: 'LineString',
								coordinates: []
							},
							properties: {
								color: this.state.selectedColor,
								width: this.state.lineWidth,
							}
						}]
					}
				});

				map.addLayer({
					id: 'currentDrawing',
					type: 'line',
					source: 'currentDrawing',
					layout: {
						'line-cap': 'round',
						'line-join': 'round'
					},
					paint: {
						'line-color': ['get', 'color'],
						'line-width': ['get', 'width']
					}
				});
			}

			this.currentLine = [...this.currentLine, e.lngLat.toArray()];
			
			// 描画中のラインのみを更新
			const currentDrawingSource = map.getSource('currentDrawing');
			if (currentDrawingSource) {
				currentDrawingSource.setData({
					type: 'FeatureCollection',
					features: [{
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: this.currentLine
						},
						properties: {
							color: this.state.selectedColor,
							width: this.state.lineWidth,
						}
					}]
				});
			}
			return;
		}


		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);
		if (this.shapeUpdatetimerId) clearTimeout(this.shapeUpdatetimerId);

		//消しゴムモード
		if (isDrawingMode === 2) {
			const source = map.getSource(this.myDrawing);
			if(!source) return;

			const data = source._data;
			const newLine = [...this.currentLine, e.lngLat.toArray()];

			const zoomLevel = map.getZoom();
			const epsilon = 10.25 / 2 ** zoomLevel; //ズームレベルから計算する

			const sourcesToProcess = [this.myDrawing, this.myShape];

			sourcesToProcess.forEach(sourceId => {
			//console.log(sourceId);
				const source = map.getSource(sourceId);
				if (source) {
					const data = source._data;

					if (data && data.features) {
					const erasedDocIds = new Set(this.state.erasedLinesDocIds);
					data.features = data.features.filter(feature => {
						let shouldKeepFeature = true;
						const originalGeometry = JSON.parse(JSON.stringify(feature.geometry));

						if (feature.geometry.type === 'LineString') {
							const lengthA = feature.geometry.coordinates.length;
							feature.geometry.coordinates = feature.geometry.coordinates.filter(coord => {
								return !newLine.some(newCoord => {
									return Math.abs(newCoord[0] - coord[0]) < epsilon && Math.abs(newCoord[1] - coord[1]) < epsilon;
								});
							});
							const lengthB = feature.geometry.coordinates.length;
							shouldKeepFeature = feature.geometry.coordinates.length > 1;

							//ポイント数が減ったら再計算する
							if(shouldKeepFeature && lengthA !== lengthB) {
								if(feature.properties.distance && feature.properties.time) {
									feature.properties.distance = CommonUtils.calculateDistanceWithElevationArray(feature.geometry.coordinates);
									const eleDiff =	CommonUtils.calculateDiffElevationArray(feature.geometry.coordinates);
									const walkSpeed = FirebaseManager.getWalkSpeed(this.props.userSettings.walkingSpeed);
									feature.properties.time = CommonUtils.calcTimeArray(feature.geometry.coordinates,walkSpeed);
									feature.properties.altitudeDifferenceAscent = eleDiff.ascent;
									feature.properties.altitudeDifferenceDescent = eleDiff.descent;

									console.log('LineString: length:' + lengthA + ":" + lengthB + ":" + feature.properties.distance);
								}
							}
							// ゴミデータ削除
							if (feature.properties.distance !== undefined && 
								feature.properties.distance === 0) {
								shouldKeepFeature = false;
							}
						}
						else if (feature.geometry.type === 'Polygon') {
							const lengthA = feature.geometry.coordinates[0].length;
							feature.geometry.coordinates = feature.geometry.coordinates.map(ring => 
								ring.filter(coord => {
									return !newLine.some(newCoord => {
										return Math.abs(newCoord[0] - coord[0]) < epsilon && Math.abs(newCoord[1] - coord[1]) < epsilon;
									});
								})
							);
							const lengthB = feature.geometry.coordinates[0].length;
							shouldKeepFeature = feature.geometry.coordinates[0].length >= 3;

							//ポイント数が減ったら再計算する
							if(shouldKeepFeature && lengthA !== lengthB) {
								if(feature.properties.area && feature.properties.totalDistance) {
									feature.properties.totalDistance = CommonUtils.calculateTotalDistance(feature.geometry.coordinates[0]);
									feature.properties.area = CommonUtils.calculatePolygonArea(feature.geometry.coordinates[0]);
								}
							}
						}
						else if (feature.geometry.type === 'GeometryCollection') {
						// Circle処理 (GeometryCollection)
							feature.geometry.geometries = feature.geometry.geometries.map(geom => {
								if (geom.type === 'Point') {
								// 中心点が消しゴムの範囲内にある場合、円全体を削除
								if (newLine.some(newCoord => {
									return Math.abs(newCoord[0] - geom.coordinates[0]) < epsilon && 
											Math.abs(newCoord[1] - geom.coordinates[1]) < epsilon;
									})) {
										shouldKeepFeature = false;
									}
								} else if (geom.type === 'Polygon') {
									// 円周の座標を処理
									const lengthA = geom.coordinates[0].length;
									geom.coordinates = geom.coordinates.map(ring => 
										ring.filter(coord => {
											return !newLine.some(newCoord => {
												return Math.abs(newCoord[0] - coord[0]) < epsilon && 
													Math.abs(newCoord[1] - coord[1]) < epsilon;
											});
										})
									);
									const lengthB = geom.coordinates[0].length;
									if (geom.coordinates[0].length <= 3) {
										shouldKeepFeature = false;
									}
									console.log('GeometryCollection: length:' + lengthA + ":" + lengthB);
									//ポイント数が減ったら再計算する
									if(shouldKeepFeature && lengthA !== lengthB) {
										if(feature.properties.area && feature.properties.radius) {
											feature.properties.area = CommonUtils.calculatePolygonArea(geom.coordinates[0]);
										}
									}
								}
								return geom;
							});
						}

						if (JSON.stringify(originalGeometry) !== JSON.stringify(feature.geometry)) {
							erasedDocIds.add(data.id);
						}

						return shouldKeepFeature;
					});

					this.setState({ erasedLinesDocIds: erasedDocIds });
					source.setData(data);
					}
				}
			});
		}


	};

	//描画終了 mouseup
	endDrawing = (e) => {
		const {map,isDrawingMode} = this.state;

		if((!this.state.isDrawing && !this.shapeDrawing) || this.isSpaceKeyPressed) {
			map.dragPan.enable();
			return;
		}
		this.latestClickTime = new Date();

		//イベントを下のビューに伝播させない
		const originalEvent = e.originalEvent;
		// タッチしている指やペンの数を取得
		let touchCount = 0;
		if (originalEvent.touches) {
			touchCount = originalEvent.touches.length;
		}

		if(touchCount > 1 || (isDrawingMode === 1 && this.currentLine.length < 3)) {
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			this.currentLine = [];
			return;
		}
		else {
			map.touchZoomRotate.disable();
			map.dragPan.disable();
			originalEvent.preventDefault();
			originalEvent.stopPropagation();
		}

		//図形の場合はendShape
		if (isDrawingMode === 3 && this.shapeDrawing) {//図形
			this.endShape(e);
			return;
		}

		// GeoJSONをFirestorageに保存
		let source = map.getSource(this.myShape);
		if (source) {
			this.shapeUpdatetimerId = setTimeout(() => {
				const geoJSON = source._data;
				this.updateDrawingData(3,geoJSON,_shapeTag,e.lngLat.lng,e.lngLat.lat);
			}, _drawUpdateTimer);
		}
		this.props.fm.checkAndSaveCurrentPosition(this.props.owneruid,this.props.uuid,this.state.map,this.currentProject);
			
		this.endDrawingFunc(e.lngLat);
	};


	saveGeoJSONToFirestorage = async (header,geoJSON) => {
		const fileName = `${header}_${this.props.fm.auth.currentUser.uid}.geojson`;
		const filePath = `files/${this.props.owneruid}/${this.props.uuid}/${this.props.fm.auth.currentUser.uid}/open/${fileName}`;
		const fileBlob = new Blob([JSON.stringify(geoJSON)], { type: 'application/json' });
		const fileUrl = await this.props.fm.uploadFile(filePath, fileBlob);
		return fileUrl;
	}
	getGeoJson(typeNumber,uid) {
		const { map} = this.state;
		let type = 'drawing_';
		if(typeNumber === 3) type = 'shape_';
		const source = map.getSource(type + uid);
		if(!source) return null;

		return source._data;
	}


	endDrawingFunc = (lngLat = null) => {
		if (this.drawUpdatetimerId) clearTimeout(this.drawUpdatetimerId);

		const { map,erasedLinesDocIds,isDrawingMode} = this.state;
		map.dragPan.enable();
		
		this.setState({ isDrawing: false });
		this.changeMarkersPointerEvents(true);

		const source = map.getSource(this.myDrawing); //myDrawing:"drawing_uid"
		if(!source) return;

		const geoJSON = source._data;
		let newLine;
		if(lngLat)
			newLine = [...this.currentLine, lngLat.toArray()];
		else 
			newLine = [...this.currentLine];

		if (isDrawingMode === 2) {//消しゴムモード
			erasedLinesDocIds.forEach(docId => {
				if (docId && docId !== undefined) {
					this.drawUpdatetimerId = setTimeout(() => {
						this.updateDrawingData(2,geoJSON,_drawingTag,newLine[0][0],newLine[0][1]);
					}, _drawUpdateTimer);
				}
			});
            this.setState({ erasedLinesDocIds: new Set() });
			// GeoJSONをFirestorageに保存
			this.drawUpdatetimerId = setTimeout(() => {
				this.updateDrawingData(2,geoJSON,_drawingTag,newLine[0][0],newLine[0][1]);
			}, _drawUpdateTimer);

			return;
		}
		else {
			// 新しい線を追加
			// 描画中のラインを本体のソースに追加
			const mainSource = map.getSource(this.myDrawing);
			if (!mainSource) return;

			const data = mainSource._data;

			// 点の数を間引く
			newLine = CommonUtils.dynamicPointReduction(newLine, {
				n: 5,    // 直線部分は最大1/10まで間引く
				minReduction: 2,     // カーブ部分は最小1/3まで間引く
				curvatureThreshold: 0.1,  // 曲率の閾値
				smoothingFactor: 0.3     // スムージングの強さ
			});

			// 描画中のラインのデータのみをクリア
			const currentDrawingSource = map.getSource('currentDrawing');
			if (currentDrawingSource) {
				currentDrawingSource.setData({
					type: 'FeatureCollection',
					features: []
				});
			}

			if (newLine.length > 1) {
				const feature = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: newLine
					},
					properties: {
						color: this.state.selectedColor,
						width: this.state.lineWidth,
					}
				};

				data.features.push(feature);
				mainSource.setData(data);

				// GeoJSONの保存を遅延実行
				this.drawUpdatetimerId = setTimeout(() => {
					this.updateDrawingData(2, data, _drawingTag, newLine[0][0], newLine[0][1]);
				}, _drawUpdateTimer);
			}
		}

		this.currentLine = [];
	}

	//描画データのアップデート
	updateDrawingData = async (type,geoJSON,header,lng,lat) => {
		const fileUrl = await this.saveGeoJSONToFirestorage(header,geoJSON);
		console.log('fileUrl:' + fileUrl);

		// Firestoreにインデックスを保存
		const collectionPath = `userdata/${this.props.owneruid}/projects/${this.props.uuid}/items`;
		const documentId = header + '_' + this.props.fm.auth.currentUser.uid;
		const lineData = {
			type: type,
			owneruid: this.props.fm.auth.currentUser.uid,
			ownername: this.props.myName,
			ownercolor: this.props.myColor,
			lng: lng,
			lat: lat,
			geojsonUrl: fileUrl,
			createtime: new Date(),
			updatetime: new Date(),
			creater:'collaboMap',
			title:this.currentProject.title,
		};

		try {
			await this.props.fm.setDocument(collectionPath, documentId, lineData);
			this.updateProjectCoordinate();

			console.log('GeoJSON and index successfully saved to Firebase!');
		} catch (error) {
			console.error('Failed to save GeoJSON and index to Firebase:', error);
		}
	}

	//描画中、マーカーを一時的にUI無効にする
	changeMarkersPointerEvents(enable) {
		this.markers.forEach(markerObj => {
			const markerElement = markerObj.marker.marker.getElement();
			markerElement.style.pointerEvents = enable ? 'auto' : 'none';
			const popup = markerObj.marker.marker.getPopup();
			if (popup) {
				const popupElement = popup._content;
				if (popupElement) {
					popupElement.style.pointerEvents = enable ? 'auto' : 'none';
				}
			}
		});
	}

	handleCompassButton = () => {
		const { map } = this.state;
		if (!map) return;

		map.resetNorth();
		map.setPitch(0);
	};

	updateUI= () => {
		if(this.currentProject) {
			this.updateSignedIn(this.currentProject);
		}
	}

	//--------プロジェクト設定を読む
	setProjectListener(map) {
		// ドキュメントリスナーの設定
		const docPath = `userdata/${this.props.owneruid}/projects/${this.props.uuid}`;
		this.unsubscribeDocListener = this.props.fm.subscribeToDocument(docPath, (doc) => {
			if (doc.exists()) {
				const currentProject = doc.data();

				this.currentProject = currentProject;
				this.props.updateProjectInfo(currentProject);
				this.updateSignedIn(this.currentProject);

				if (currentProject.exaggeration !== undefined) {
					setTimeout(() => {
						this.createTerrainControl(currentProject.exaggeration);
						this.setState({ exaggeration: currentProject.exaggeration });
					}, 500);
				}
				else {
					setTimeout(() => {
						currentProject.exaggeration = 1;
						this.createTerrainControl(currentProject.exaggeration);				
						this.setState({ exaggeration: currentProject.exaggeration });
					}, 500);
				}

				currentProject.title = doc.data().title ? doc.data().title : "no title";
				currentProject.ownername = doc.data().ownername ? doc.data().ownername : "no name";
				
				console.log('unsubscribeDocListener:' + currentProject.title);
				this.setState({
					projectTitle: currentProject.title,
					ownerName: currentProject.title.ownername,
				});
				this.thumbnailUrl = currentProject.thumbnailUrl;
				
				//閲覧履歴更新
				if(this.props.fm && this.props.fm.auth && this.props.fm.auth.currentUser) {
					if(this.props.fm.auth.currentUser.uid !== currentProject.owneruid) {//自分の地図ではない場合
						const myCollectionPath = `userdata/${this.props.fm.auth.currentUser.uid}/projects/`;

						let data = null;
						data = {
							title:currentProject.title,
							memo:currentProject.memo,
							tags: currentProject.tags,
							ownername: currentProject.ownername,
							ownercolor: currentProject.ownercolor,
							owneruid: currentProject.owneruid,
							basemap: currentProject.basemap,
							exaggeration:currentProject.exaggeration,
							permission: currentProject.permission,
							issearchable: false,
							iscopyable: false,
							createtime:  currentProject.createtime,
							updatetime: new Date(),
						};

						try {
							this.props.fm.getDocument(myCollectionPath, this.props.uuid)
							.then(myData => {
								if(myData.isfavorite) {
									this.setState({isfavorite:true});
								}
								else {
									this.setState({isfavorite:false});
								}
							})
							.catch(error => {
								console.error('Failed to getDocument:', error);
							});
						} catch (error) {
							console.error('Failed to getDocument:', error);
						}

						try {
							this.props.fm.documentExists(myCollectionPath, this.props.uuid)
							.then(exists => {
								if (exists) {
									this.props.fm.updateDocument(myCollectionPath, this.props.uuid, data);
								} else {
									this.props.fm.setDocument(myCollectionPath, this.props.uuid, data);
								}
							})
							.catch(error => {
								console.error('Error:', error);
							});
						} catch (error) {
							console.error('Failed to write document:', error);
						}
					}
					else {
						if(currentProject.isfavorite) {
							this.setState({isfavorite:true});
						}
						else {
							this.setState({isfavorite:false});
						}
					}
				}


				if(map && (this.currentMap !== doc.data().basemap || this.currentOverlay !== doc.data().overlay)) {
					this.currentMap = doc.data().basemap;
					this.currentOverlay = doc.data().overlay;
					if (map.getSource('terrain')) map.removeSource('terrain');

					if (doc.data().customLayers) {
						if (doc.data().customLayers.overlays) {
							Object.keys(doc.data().customLayers.overlays).forEach(key => {
								if (this.customOverlays[key]) {
									this.customOverlays[key] = {
										...this.customOverlays[key],
										...doc.data().customLayers.overlays[key]
									};
								}
							});
						}
						if (doc.data().customLayers.maps) {
							Object.keys(doc.data().customLayers.maps).forEach(key => {
								if (this.customMaps[key]) {
									this.customMaps[key] = {
										...this.customMaps[key],
										...doc.data().customLayers.maps[key]
									};
								}
							});
						}
					}

					if (doc.data().overlays) {
						Object.keys(doc.data().overlays).forEach(key => {
							if (CommonUtils.overlays[key]) {
								CommonUtils.overlays[key] = doc.data().overlays[key];
							}
						});
					}

					//データに不備がある場合
					if (typeof this.currentMap  !== 'string') {
						console.log('doc.data().language:' + doc.data().language);
						if(!doc.data().language || doc.data().language === undefined || doc.data().language === 'ja') {
							this.currentOverlay = 'slopemap';
							this.currentMap = 'pale';
						}
						else {
							this.currentOverlay = 'null';
							this.currentMap = 'osm';
						}
						//不備を修正
						if(this.props.fm && this.props.fm.auth.currentUser && this.props.fm.auth.currentUser.uid === this.props.owneruid) {
							this.props.fm.updateLayerSetting(this.props.owneruid,this.props.uuid,this.currentOverlay,this.currentMap);
						}

					}
					//標高データ
					if(!doc.data().language || doc.data().language === undefined || doc.data().language === 'ja') {
						map.addSource('terrain',this.gsiTerrainSourceKokudo);
					}
					else {
						map.addSource('terrain',this.gsiTerrainSourceS3);
					}
//					this.removeSourceAndLayer(map,'overlay_docomo');
//					this.addSourceAndLayer(map,'overlay_docomo','raster',this.tileUrlDocomo,256,3,15,0.3,'');

//					this.removeSourceAndLayer(map,'overlay_rakuten');
//					this.addSourceAndLayer(map,'overlay_rakuten','raster',this.tileUrlRakuten,256,3,15,0.3,'');

					this.setupOverlay(map,this.currentOverlay);
					this.setupBaseMap(map,this.currentMap);
					setTimeout(() => {
						const urlParams = new URLSearchParams(window.location.search);
						const is3D = urlParams.get('is3D') === 'true';
						if(is3D) {
							const { map, exaggeration } = this.state;
							map.setTerrain({ source: 'terrain', exaggeration: exaggeration });
							this.customScale.setVisibility(false);
						}
						this.setState({ is3DEnabled: is3D });
					}, 500);
				}
				
			} else {
				console.log("No such document!");
			}
		});
	}

	setupOverlay(map,label) {
		let layerSetting = CommonUtils.overlays[label];
		if(layerSetting === undefined) layerSetting = this.customOverlays[label];
		if(layerSetting === undefined) return;

		let url = layerSetting.url;
		if(layerSetting.proxy) {
			url = `https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/proxyTile?baseUrl=${encodeURIComponent(layerSetting.url)}&z={z}&x={x}&y={y}`
		}

		this.removeSourceAndLayer(map,'overlay');
		if(label && label !== 'null') {
			this.addSourceAndLayerBottom(map,'overlay','raster',url,layerSetting.ts,layerSetting.minz,layerSetting.mz,layerSetting.op/100,layerSetting.cp || '',layerSetting.bp,layerSetting.cc);
		}
	}

	setupBaseMap(map,label) {
		let layerSetting = CommonUtils.maps[label];
		if(layerSetting === undefined) layerSetting = this.customMaps[label];
		if(layerSetting === undefined) return;

		let url = layerSetting.url;
		if(layerSetting.proxy) {
			url = `https://asia-northeast1-collabomap-1bcb9.cloudfunctions.net/proxyTile?baseUrl=${encodeURIComponent(layerSetting.url)}&z={z}&x={x}&y={y}`
		}

		this.removeSourceAndLayer(map,'baseMap');
		if(label && label !== 'null') {
			this.addSourceAndLayerBottom(map,'baseMap','raster',url,layerSetting.ts,layerSetting.minz,layerSetting.mz,layerSetting.op/100,layerSetting.cp || '',layerSetting.bp,layerSetting.cc);
		}
	}


	updateSignedIn(data) {
		/*if(data == null) {
			data = this.state.currentProjectData;
		}
		else {
			this.setState({currentProjectData:data});
		}*/

		if(this.props.isSignedIn && data) {
			if(!this.props.fm.auth.currentUser) {
				this.setState({'permission':0});
			}
			else {//ログインしている
				if(data.owneruid === this.props.fm.auth.currentUser.uid) {
					this.setState({'permission':2});
					this.myDrawing = _drawingTag  + '_' + this.props.fm.auth.currentUser.uid;
					this.myShape = _shapeTag  + '_' + this.props.fm.auth.currentUser.uid;
				}
				else if(data.permission === 0) {
					this.setState({'permission':0});
				}
				else if(data.permission === 1) {
					this.setState({'permission':1});
				}
				else if(data.permission === 2) {
					this.setState({'permission':2});
				}
			}
		}
		else {
			this.setState({'permission':0});
		}
	}

	//--------------------地図初期化
	initializeMap() {
		if (this.state.map) {
			return;
		}
		if(this.isInitializeMap) {
			return;
		}
		let gsiTerrainSource = null;
		if(this.state.isjapan) gsiTerrainSource = this.gsiTerrainSourceKokudo;
		else gsiTerrainSource = this.gsiTerrainSourceS3;

		this.isInitializeMap = true;
		const styleNull = {
			version: 8,
			sources: {
			},
			layers: [
			],
			glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
			sky: this.skyLayer,
		};

		const timer = setTimeout(() => {
			const map = new maplibregl.Map({
				maxZoom: 22,
				container: this.mapContainerRef.current,
				style: styleNull,
				maxPitch: 85,
				hash: true,
				center: [137.969392, 36.238642],
				zoom: 8,
			});


			// カスタムプロトコルを追加
			maplibregl.addProtocol('customtile', (params) => {
				const url = params.url.replace('customtile://', 'https://');

				const urlObj = new URL(url);
				const basePosType = urlObj.searchParams.get('bp');
				const colorConversion = urlObj.searchParams.get('cc');

				// URLからz, x, yを抽出する関数
				const extractZXY = (url) => {
					// パターン1: /z/x/y.png 形式
					const patternMatch = url.match(/\/(\d+)\/(\d+)\/(\d+)/);
					if (patternMatch) {
						return patternMatch.slice(1, 4).map(Number);
					}
					
					const z = urlObj.searchParams.get('z');
					const x = urlObj.searchParams.get('x');
					const y = urlObj.searchParams.get('y');
					
					if (z && x && y) {
						return [z, x, y].map(Number);
					}
					
					throw new Error('Invalid tile URL format: ' + url);
				};
				let [zoom, x, origY] = extractZXY(url);
	//			console.log(`zoom:${zoom} x:${x} y:${origY}`);
				// basePosTypeが1の場合、y座標を変換
				let y = origY;
				if (basePosType) {
					const ymax = 1 << (zoom-1);
					y = ymax - origY - 1;
				}
				console.log(`a zoom:${zoom} x:${x} origY:${origY} y:${y} basePosType:${basePosType} colorConversion:${colorConversion}`);

				
				// 変換後のy座標でURLを再構築
				const convertedUrl = url.replace(`/${origY}`, `/${y}`);


				return fetch(convertedUrl)
					.then(response => response.blob())
					.then(blob => createImageBitmap(blob))
					.then(imageBitmap => {
						const canvas = document.createElement('canvas');
						canvas.width = imageBitmap.width;
						canvas.height = imageBitmap.height;
						const context = canvas.getContext('2d');
						context.drawImage(imageBitmap, 0, 0);

						const urlObj = new URL(convertedUrl);
						const colorConversion = urlObj.searchParams.get('cc');

						const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
						if(colorConversion) {
							const data = imageData.data;

							for (let i = 0; i < data.length; i += 4) {
								if (data[i] === 255 && data[i+1] === 255 && data[i+2] === 255) {
									data[i] = 255;   // 赤
									data[i+1] = 0;   // 緑
									data[i+2] = 0;   // 青
								}
							}
						}
						context.putImageData(imageData, 0, 0);

						return createImageBitmap(canvas);
					})
					.then(processedImageBitmap => {
						return {
							data: processedImageBitmap,
							cacheControl: params.cacheControl,
							expires: params.expires
						};
					})
					.catch(error => {
						console.error(error);
						throw error;
					});
			});


			const navControl = new maplibregl.NavigationControl();
			map.addControl(navControl, 'top-right');
			map.on('load', () => {

	//			this.setScreeSize();
				this.setState({ map });

				map.on('moveend', this.updateCenterElevation);

				map.on('mousedown', this.startDrawing);
				map.on('mousemove', this.drawLine);
				map.on('mouseup', this.endDrawing);
				map.on('move', this.handleMapMove);

				map.on('touchstart', this.startDrawing);
				map.on('touchmove', this.drawLine);
				map.on('touchend', this.endDrawing);
				map.on('mouseout', this.handleMouseOut);
				map.on('click', this.handleMapClick);

//				map.on('dblclick', this.handleDoubleClick);

				map.on('touchstart', this.handleTouchStart);
				map.on('touchend', this.handleTouchEnd);

				const geolocate = new maplibregl.GeolocateControl({
					positionOptions: {
						enableHighAccuracy: true,
					},
					trackUserLocation: true,
					showUserHeading: true,
				});

				map.addControl(geolocate, 'top-right');

				// 位置情報が更新されたときのイベントリスナー
				geolocate.on('geolocate', (e) => {
					const lon = e.coords.longitude;
					const lat = e.coords.latitude;
					const position = [lon, lat];
					this.setState({ currentLocation: position });
					console.log(position);
				});

				let exaggeration = this.state.exaggeration;
				if(exaggeration === null || exaggeration === undefined) {
					exaggeration = 1;
					this.setState({ exaggeration: exaggeration });
				}

				//3D表示コントローラーと状態管理
				this.createTerrainControl(exaggeration);
/*				this.terrainControl = new maplibregl.TerrainControl({
					source: 'terrain',
					exaggeration: this.state.exaggeration,
				});
				map.addControl(this.terrainControl, 'top-right');
*/
				// スケールコントロールを作成
				this.customScale = new CustomScaleControl({
					maxWidth: 150,
					unit: 'metric'
				})

				map.addControl(this.customScale);
				this.customScale.setPosition((mapWidth, mapHeight, scaleWidth, scaleHeight,correctionX,correctionY) => {
					// 中央下部に配置
					const x = mapWidth / 2 - scaleWidth / 2 - correctionX;
					const y = mapHeight / 2 - scaleHeight + (mapHeight * 0.40) - correctionY; // 位置調整
					return { x, y };
				});

				// TerrainControlの変更を監視
				map.on('terrain', () => {
					const currentTerrain = map.getTerrain();
					const is3DEnabled = !!currentTerrain;
					this.setState({ is3DEnabled }, () => {
						const urlParams = new URLSearchParams(window.location.search);
						urlParams.set('is3D', is3DEnabled);
						window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`);

						if (this.customScale) {
							this.customScale.setVisibility(!is3DEnabled);
							if(!is3DEnabled) {
								this.customScale.update();
							}
						}
					});
				});
				this.toggleDistanceLayer();

				//map,fm, ownerUid,myName,myColor, projectId
				this.fileHandler = new FileHandler(map,this,this.props.fm,this.props.owneruid,this.props.uuid);

				this.setProjectListener(map);// プロジェクトのドキュメントリスナー
				this.readItemData();//アイテムを読む

				setTimeout(() => {
					this.updateCenterElevation();
				}, 1000);
			});

		//---------
		}, 100); // 100ミリ秒の遅延 リストから遷移してきたとき、遅延させないと画面中央の座標が実際より下にずれてしまう
		return () => clearTimeout(timer);
	}

	handleTouchStart = (e) => {
		if (e.originalEvent.touches.length > 1) {
			// マルチタッチの場合、ダブルタップ検出をキャンセル
			clearTimeout(this.tapTimeout);
			this.lastTap = 0;
		}
	};

	handleTouchEnd = (e) => {
		const currentTime = new Date().getTime();
		const tapLength = currentTime - this.lastTap;
		clearTimeout(this.tapTimeout);

		if (this.lastTap && tapLength < 500) {
			// ダブルタップ検出
//			this.handleDoubleClick(e);
		} else {
			this.tapTimeout = setTimeout(() => {
				// シングルタップとして処理
			}, 300);
		}

		this.lastTap = currentTime;
	};

	//---------プロジェクト内のデータ(items)を読み込む
	async readItemData(){
			const currentTime = new Date();
			const collectionPath = `userdata/${this.props.owneruid}/projects/${this.props.uuid}/items`;

			try {
				// コレクションからドキュメントを取得
				const querySnapshot = await this.props.fm.getCollection(collectionPath);

				// 全ての処理が完了するのを待つ
				await Promise.all(querySnapshot.docs.map(async (doc) => {
					const data = doc.data();
					const docId = doc.id;
					await this.processProjectItem(docId, data); // processProjectItemが非同期関数である場合
				}));
				this.addToHistory();
				this.isLoadedDrawingData = true;
			} catch (error) {
				console.error('Error fetching project items:', error);
			}
			//プロジェクトのアイテムリスナー
			this.unsubscribeColListener = this.props.fm.subscribeToCollectionWithUpdateTime(collectionPath,currentTime, async (snapshot) => {
				snapshot.docChanges().forEach(async (change) => {
					const data = change.doc.data();
					const docId = change.doc.id;
//const testLabel = document.getElementById('testLabel');
//testLabel.innerText = `readItemData: ${docId} / ${change.type} / ${data.type}`;

					if (change.type === "added" || change.type === "modified") {
						this.processProjectItem(docId,data,change.type);
					}
					else if (change.type === "removed") {
						const { map } = this.state;

						if (data.type === 1 || data.type === 5) {//マーカー、写真
							this.removeMarkerFromMap(docId);
						}
						else if (data.type === 2) {//フリーハンド
							if(map.getLayer(docId)) map.removeLayer(docId);
							const source = map.getSource(docId);
							if(source) {
								source.setData([]);
								map.removeSource(docId);
							}
							this.drawingHistory = [];
							map.removeLayer(docId + '-label');
	
							if(docId === this.myDrawing) {
								this.addDrawingSource(map,docId);
								this.addDrawingLayer(map,docId);
								console.log('addDrawingSource:addDrawingLayer');							
							}
						}
						else if (data.type === 3) {//図形
							map.removeLayer(docId + '-fill');
							map.removeLayer(docId + '-outline');
							map.removeLayer(docId + '-label');
							map.removeLayer(docId + '-center-point-layer');

							const source = map.getSource(docId);
							if(source) {
								source.setData([]);
								map.removeSource(docId);
							}
							if(docId === this.myShape) {
								this.addShapeLayer(map,docId);
								this.addLabelSourceAndLayer(map,docId);
							}
						}
						else if (data.type === 4) {//ファイル
							this.fileHandler.removeDataFromMap(docId);
						}	
					}
				});
			});

	}

	//更新データがこちらのデータより新しいかチェックする
	checkLastUpdate(updatetime) {
		if(!this.latestClickTime) return true;
		const updateTime = updatetime.toDate(); // JavaScriptのDate型に変換
		console.log('checkLastUpdate:' + updateTime.getTime() + ':' + this.latestClickTime.getTime());
		if(updateTime.getTime() > this.latestClickTime.getTime()) return true;
		return false;
	}

	async processProjectItem(docId,data,changeType) {

		//マーカー
		if (data.type === 1) {
			new Marker(this.state.map, data.lng, data.lat, data.elevation,data, 60, docId,this,
				(marker) => {
					this.addMarkerToMap(marker, docId);
				},
				(marker) => {
					this.updateFirebaseMarker(marker,docId);
				},
				(marker) => {
					marker.currentLocation = this.state.currentLocation;
				},
			);
		}
		else if (data.type === 5) {
			// 写真マーカーの処理
			try {
				const iconResponse = await fetch(data.iconurl);
				const iconBlob = await iconResponse.blob();
				const iconDataUrl = URL.createObjectURL(iconBlob);

				const marker = this.findMarkerByDocId(docId);
				if(!marker || marker === undefined) {
					new Marker(this.state.map, data.lng, data.lat, data.elevation, {
							...data,
							iconDataUrl: iconDataUrl,
							photoUrl: data.fileurl
						}, 60, docId, this,
						(marker) => {//地図に表示
							this.addMarkerToMap(marker, docId);
						},
						(marker) => {//変更
							this.updateFirebasePhoto(marker, docId);
						},
						(marker) => {//クリック
							marker.currentLocation = this.state.currentLocation;
							this.showPhotoViewer(marker);
						},
					);
				}
				else {
					marker.update(data);
					marker.updatePhotoIcon(data.note);
					this.updatePhotoMarkers();
				}
				
			} catch (error) {
				console.error('Failed to load photo marker:', error);
			}
		}
		//フリーハンド描画
		else if (data.type === 2) {
			/*if(this.isLoadedDrawingData && this.lastUpdate && this.lastUpdate > data.updatetime) {
				this.lastUpdate =  new Date();
				//return;	//これがあると同期しなくなるっぽいのでコメントにしたが、いいんだっけ？
			}*/
			if (this.props.fm.auth.currentUser && data.owneruid === this.props.fm.auth.currentUser.uid &&  this.state.isDrawing) return
			if(!this.checkLastUpdate(data.updatetime)) return;
			try {
				const response = await fetch(data.geojsonUrl);
				const geoJSON = await response.json();
				geoJSON.id = docId;
				geoJSON.data = data;
				this.updateMapWithGeoJSON_drawing(geoJSON);
				const index = this.state.files.findIndex(item => item.id === docId);
				if (index !== -1) {
					this.updateFiles(index, {id:docId,data:data});
//					this.state.files[index] = {id:docId,data:data};
				}
				else {
					this.state.files.push({id:docId,data:data});
				}
			} catch (error) {
				console.error('Failed to load GeoJSON:', error);
			}
			//this.addLineToMap(data, docId);
		}
		//図形
		else if (data.type === 3) {
			/*if(this.isLoadedDrawingData && this.lastUpdate && this.lastUpdate > data.updatetime) {
				this.lastUpdate =  new Date();
				//return;	//これがあると同期しなくなるっぽいのでコメントにしたが、いいんだっけ？
			}*/
			if ((this.props.fm.auth.currentUser && data.owneruid === this.props.fm.auth.currentUser.uid) && (this.drawUpdatetimerId || this.state.isDrawing)) return
			if(!this.checkLastUpdate(data.updatetime)) return;
			try {
				const response = await fetch(data.geojsonUrl);
				if(response.ok) {
					const geoJSON = await response.json();
					geoJSON.id = docId;
					geoJSON.data = data;
					this.updateMapWithGeoJSON_shape(geoJSON);

					const index = this.state.files.findIndex(item => item.id === docId);
					if (index !== -1) {
						this.updateFiles(index, {id:docId,data:data});
//						this.state.files[index] = {id:docId,data:data};
					}
					else {
						this.state.files.push({id:docId,data:data});
					}
				}
			} catch (error) {
				console.error('Failed to load GeoJSON:', error);
			}
			//this.addLineToMap(data, docId);
		}
		//ファイル
		else if (data.type === 4) {
			const fileData = this.fileHandler.displayDataOnMapFromFile(data.fileUrl, docId);
			if(fileData) {
				const index = this.state.files.findIndex(item => item.id === docId);
				if (index !== -1) {
					this.updateFiles(index, {id:docId,data:data,fileData:fileData});
//					this.state.files[index] = {id:docId,data:data,fileData:fileData};
				}
				else {
					this.state.files.push({id:docId,data:data,fileData:fileData});
				}
			}
		}

	}
	updateFiles = (index, data) => {
		this.setState(prevState => {
			const newFiles = [...prevState.files];
			if (index !== -1 && index < newFiles.length) {
				newFiles[index] = { ...newFiles[index], ...data };
			} else {
				newFiles.push(data);
			}
			return { files: newFiles };
		});
	};

	findMarkerByDocId(docId) {
		const markerObj = this.markers.find(marker => marker.id === docId);
		return markerObj ? markerObj.marker : null;
	}

	updatePhotoMarkers() {
		const photoMarkers = this.markers
		.filter(marker => marker.marker.data.iconDataUrl)
		.map(marker => ({
			...marker.marker.data,
			lat: marker.marker.lat,
			lng: marker.marker.lng,
			docId: marker.id
		}))
		.sort((a, b) => new Date(a.dateTimeOriginal) - new Date(b.dateTimeOriginal));

		this.setState({ photoMarkers });
	}

	showPhotoViewer(marker) {
		this.updatePhotoMarkers();
		setTimeout(() => {
			const currentPhotoIndex = this.state.photoMarkers.findIndex(photo => photo.docId === marker.docId);

			this.setState({
				selectedPhoto: {
					owneruid:marker.data.owneruid,
					url: marker.data.photoUrl,
					name: marker.name,
					note: marker.note,
					elevation: marker.elevation,
					cameraModel: marker.data.cameraModel,
					cameraModel: marker.data.cameraModel,
					fileName: marker.data.fileName,
					dateTimeOriginal: marker.data.dateTimeOriginal,
					docId:marker.docId,
				},
				currentPhotoIndex
			}, () => {
				this.scrollMapToPhoto(marker.lat, marker.lng);
			});
		}, 100);


	}

	handlePhotoSelect = (index) => {
		const selectedPhoto = this.state.photoMarkers[index];
		this.setState({ 
		currentPhotoIndex: index,
		selectedPhoto: {
			owneruid:selectedPhoto.owneruid,
			url: selectedPhoto.photoUrl,
			name: selectedPhoto.name,
			note: selectedPhoto.note,
			elevation: selectedPhoto.elevation,
			cameraModel: selectedPhoto.cameraModel,
			fileName: selectedPhoto.fileName,
			dateTimeOriginal: selectedPhoto.dateTimeOriginal,
			lat: selectedPhoto.lat,
			lng: selectedPhoto.lng,
			docId:selectedPhoto.docId,
		}
		}, () => {
			this.scrollMapToPhoto(selectedPhoto.lat, selectedPhoto.lng);
		});
	}

	handlePrevPhoto = () => {
		this.setState(prevState => {
			const newIndex = (prevState.currentPhotoIndex - 1 + prevState.photoMarkers.length) % prevState.photoMarkers.length;
			const newPhoto = prevState.photoMarkers[newIndex];
			this.scrollMapToPhoto(newPhoto.lat, newPhoto.lng);
			return {
				currentPhotoIndex: newIndex,
				selectedPhoto: {
				...newPhoto,
				url: newPhoto.photoUrl,
				}
			};
		});
	}

	handleNextPhoto = () => {
		this.setState(prevState => {
			const newIndex = (prevState.currentPhotoIndex + 1) % prevState.photoMarkers.length;
			const newPhoto = prevState.photoMarkers[newIndex];
			this.scrollMapToPhoto(newPhoto.lat, newPhoto.lng);
			return {
				currentPhotoIndex: newIndex,
				selectedPhoto: {
				...newPhoto,
				url: newPhoto.photoUrl,
				}
			};
		});
	}

	updateSelectedPhoto = () => {
		const { photoMarkers, currentPhotoIndex } = this.state;
		const currentPhoto = photoMarkers[currentPhotoIndex];
		this.setState({
			selectedPhoto: {
				url: currentPhoto.photoUrl,
				name: currentPhoto.name,
				note: currentPhoto.note,
				elevation: currentPhoto.elevation,
				cameraModel: currentPhoto.cameraModel,
				fileName: currentPhoto.fileName,
				dateTimeOriginal: currentPhoto.dateTimeOriginal,
			}
		});
	}

	toggleFullscreen = () => {
		this.setState(prevState => ({
		isPhotoViewerFullscreen: !prevState.isPhotoViewerFullscreen
		}));
	}

	scrollMapToPhoto = (lat, lng) => {
		if (this.state.map) {
			this.state.map.flyTo({
				center: [lng, lat],
			});
		}
	}

	closePhotoViewer = () => {
		this.setState({ selectedPhoto: null });
	}


	addLabelSourceAndLayer(map, id) {
		console.log("addLabelSourceAndLayer:" + id);
		const t = (key) => translator.t(key);
		
		if(map.getLayer(id + '-label')) return;//追加済

		map.addLayer({
			id: id + '-label',
			type: 'symbol',
			source: id,
			layout: {
				'text-size': 14,
				'text-anchor': 'center',
				'text-allow-overlap': false,
				'symbol-placement': 'point',
				'symbol-spacing': 1000000,
				'text-field': [
					'case',
					['==', ['geometry-type'], 'Point'],
					['concat',
						`${t('Area')}: `,
						[
							'case',
							['<', ['get', 'area'], 1000000],
							['number-format',['round', ['get', 'area']],{'min-fraction-digits': 0, 'max-fraction-digits': 0}],
							['number-format',['/', ['get', 'area'], 1000000],{'min-fraction-digits': 1, 'max-fraction-digits': 1}],
						],
						[
							'case',
							['<', ['get', 'area'], 1000000],
							' m²\n',
							' km²\n'
						],

						`${t('radius')} `,
						[
							'case',
							['<', ['get', 'radius'], 1000],
							['number-format', ['round', ['get', 'radius']], {'min-fraction-digits': 0, 'max-fraction-digits': 0}],
							['number-format', ['/', ['get', 'radius'], 1000], {'min-fraction-digits': 2, 'max-fraction-digits': 2}]
						],
						[
							'case',
							['<', ['get', 'radius'], 1000],
							' m',
							' km'
						]
					],
					['==', ['geometry-type'], 'Polygon'],
					['concat', 
						`${t('Area')}: `,
						[
							'case',
							['<', ['get', 'area'], 1000000],
							['number-format',['round', ['get', 'area']],{'min-fraction-digits': 0, 'max-fraction-digits': 0}],
							['number-format',['/', ['get', 'area'], 1000000],{'min-fraction-digits': 1, 'max-fraction-digits': 1}],
						],
						[
							'case',
							['<', ['get', 'area'], 1000000],
							' m²\n',
							' km²\n'
						],

						`${t('Perimeter')}: `,
						[
							'case',
							['<', ['get', 'totalDistance'], 1000],
							['number-format',['round', ['get', 'totalDistance']],{'min-fraction-digits': 0, 'max-fraction-digits': 0}],
							['number-format',['/', ['get', 'totalDistance'], 1000],{'min-fraction-digits': 2, 'max-fraction-digits': 2}],
						],
						[
							'case',
							['<', ['get', 'totalDistance'], 1000],
							' m\n',
							' km\n'
						],
					],
					''
				]
				},
			paint: {
				'text-color': '#000000',
				'text-halo-color': '#ffffff',
				'text-halo-width': 2
			},
			minzoom: 12,  // 最小ズームレベルを設定
			maxzoom: 20  // 最大ズームレベルを設定
			//filter: ['==', '$type', 'Point'], 
		});
		map.addLayer({
			id: id + '-center-point-layer',
			type: 'circle',
			source: id,
			paint: {
				'circle-radius': ['get', 'centerPointRadius'],
				'circle-color': ['get', 'centerPointColor'],
				'circle-opacity':0.1
			},
			filter: ['==', '$type', 'Point']
		});
	}

	addShapeSource(map,id) {
		if(!map.getSource(id)) {
			map.addSource(id, {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: []
				}
			});
		}
		return map.getSource(id);
	}
	addShapeLayer(map,id) {
		map.addLayer({
			id: id + '-fill',
			type: 'fill',
			source: id,
			paint: {
				'fill-color': ['get', 'color'],
				'fill-opacity': 0.4
			}
		});

		map.addLayer({
			id: id + '-outline',
			type: 'line',
			source: id,
			layout: {
				'line-cap': 'round',
				'line-join': 'round'
			},			
			paint: {
				'line-color': ['get', 'color'],
				'line-width': ['get', 'width']
			}
		});
	}

	addSourceAndLayer(map,id,type,url,tileSize,minZoom,maxZoom,opacity,aboveLayer,copylight) {
	
		const dataSource = {
            type: type,
            tiles: [url],
            tileSize: tileSize,
            maxzoom: maxZoom,
            minzoom: minZoom,
            attribution: '© ' + copylight,
        };

		map.addSource(id,dataSource);

		map.addLayer({
            id: id + '-layer',
            type: type,
            source: id,
            paint: {
                'raster-opacity': opacity
            }
        }, aboveLayer);
	}

	addSourceAndLayerBottom(map,id,type,url,tileSize,minZoom,maxZoom,opacity,copylight,bp,cc) {
	

		if(bp === 1 || cc === 1) {
			url = url.replace('https://', 'customtile://') + '&bp=' + bp + '&cc=' + cc;
		}
		const dataSource = {
			type: type,
			tiles: [url],
			tileSize: tileSize,
			maxzoom: maxZoom,
			minzoom: minZoom,
			attribution: '© ' + copylight,
			
		};

		map.addSource(id,dataSource);

		const newLayer ={
			id: id + '-layer',
			type: type,
			source: id,
			paint: {
				'raster-opacity': opacity
			}
		};

		const layers = map.getStyle().layers;
		if (layers.length > 0) {
			const firstLayerId = layers[0].id;
			map.addLayer(newLayer, firstLayerId);
		} else {
			map.addLayer(newLayer);
		}
	}

	//一番下に追加
	addLayerAtBottom(map, newLayer) {
		const layers = map.getStyle().layers;
		if (layers.length > 0) {
			const firstLayerId = layers[0].id;
			map.addLayer(newLayer, firstLayerId);
		} else {
			// レイヤーが一つも存在しない場合は、単純に追加
			map.addLayer(newLayer);
		}
	}

	removeSourceAndLayer(map, id) {
		if (map.getLayer(id + '-layer')) {
			map.removeLayer(id + '-layer');
		}
		if (map.getSource(id)) {
			map.removeSource(id);
		}
	}


	updateMapWithGeoJSON_shape = (geoJSON) => {
		const { map } = this.state;
		const id = geoJSON.id;

		// ソースが存在すればデータを更新する
		if (map.getSource(id)) {
			const source = map.getSource(id);
			source.setData(geoJSON);
		}
		else {
			// ソースが存在しなければ、新しいソースを追加する
			const source = this.addShapeSource(map,id);
			source.setData(geoJSON);
		}

		if (!map.getLayer(id + '-fill')) {
			this.addShapeLayer(map,id);
			this.addLabelSourceAndLayer(map,id);

		}
	}

	updateMapWithGeoJSON_drawing = (geoJSON) => {
		const cleaned = CommonUtils.cleanupDuplicateLineStrings(geoJSON)
		const { map } = this.state;
		const id = geoJSON.id;

		// ソースが存在すればデータを更新する
		if (map.getSource(id)) {
			const source = map.getSource(id);
			source.setData(cleaned);
		}
		else {
			// ソースが存在しなければ、新しいソースを追加する
			map.addSource(id, {
				type: 'geojson',
				data: cleaned
			});
		}
		if (!map.getLayer(id)) {
			this.addDrawingLayer(map,id);
		}
	}
	addDrawingSource(map,id) {
		if(!map.getSource(this.myDrawing)) {
			map.addSource(id, {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: []
				}
			});
		}
		return map.getSource(this.myDrawing);
	}

	addDrawingLayer(map,id) {
		map.addLayer({
			id: id,
			type: 'line',    // レイヤーのタイプ（この場合はライン）
			source: id,
			layout: {
				'line-cap': 'round',
				'line-join': 'round'
			},
			paint: {
				'line-color': ['get', 'color'],
				'line-width': ['get', 'width']
			}
		});
		const t = (key) => translator.t(key);
		if(map.getLayer(id + '-label')) return;//追加済

		map.addLayer({
			id: id + '-label',
			type: 'symbol',
			source: id,
			layout: {
				'text-size': 14,
				'text-anchor': 'center',
				'text-allow-overlap': false,
				'symbol-placement': 'point',
//				'text-offset': [0, 1],
				'symbol-spacing': 1000,
				'text-field': [
					'case',
					['==', ['geometry-type'], 'LineString'],
					['concat', 
						[
							'case',
							['<', ['get', 'distance'], 1000],
							['number-format',['round', ['get', 'distance']],{'min-fraction-digits': 0, 'max-fraction-digits': 0}],
							['number-format',['/', ['get', 'distance'], 1000],{'min-fraction-digits': 1, 'max-fraction-digits': 1}],
						],
						[
							'case',
							['<', ['get', 'distance'], 1000],
							' m\n',
							' km\n'
						],
						`${t('ascent')}: `,
						['number-format',['round', ['get', 'altitudeDifferenceAscent']],{'min-fraction-digits': 0, 'max-fraction-digits': 0}],' m\n',
						`${t('descent')}: `,
						['number-format',['round', ['get', 'altitudeDifferenceDescent']],{'min-fraction-digits': 0, 'max-fraction-digits': 0}],' m\n',

							['number-format', ['get', 'time'], {'min-fraction-digits': 1, 'max-fraction-digits': 1}],
							`${t('min')}`,

/*						[
							'case',
							['<', ['get', 'time'], 60],
							['concat', 
								['number-format', ['get', 'time'], {'min-fraction-digits': 1, 'max-fraction-digits': 1}],
								' min'
							],
							['concat',
								['number-format', ['floor', ['/', ['get', 'time'], 60]], {'min-fraction-digits': 0, 'max-fraction-digits': 0}],
								'h ',
								['number-format', ['%', ['get', 'time'], 60], {'min-fraction-digits': 1, 'max-fraction-digits': 1}],
								'min'
							]
						],*/
						'\n'
					],					
					''
				]//,'visibility': 'visible'
				},
			paint: {
				'text-color': '#000000',
				'text-halo-color': '#ffffff',
				'text-halo-width': 2
			},
			minzoom: 12,  // 最小ズームレベルを設定
			maxzoom: 20,  // 最大ズームレベルを設定
			filter: ['==', ['geometry-type'], 'LineString']
		});		

	}


	//マーカー編集
	markerEdit(marker) {
		this.editingMarker = marker;
		this.setState({ showEditMarkerPopup: true });
    }
	//編集後処理
	handleEditMarker = (markerData) => {
		const { map } = this.state;
		if (!map) return;
//		console.log(markerData);
//		console.log(this.editingMarker);
		const existMarker = this.markers.find(markerObj => markerObj.id === markerData.marker.docId);
		if(existMarker && existMarker !== undefined) {
			existMarker.marker.closePopup();
			existMarker.marker.remove();
			existMarker.marker.name = markerData.name;
			existMarker.marker.note = markerData.note;
			existMarker.marker.iconNumber = markerData.iconNumber;
			existMarker.marker.elevation = markerData.elevation;

			markerData.marker.data.name = markerData.marker.name;
			markerData.marker.data.note = markerData.marker.note;
			markerData.marker.data.iconNumber = markerData.marker.iconNumber;
			markerData.marker.data.elevation = markerData.marker.elevation;
			markerData.marker.data.lat = markerData.marker.lat;
			markerData.marker.data.lng = markerData.marker.lng;

			existMarker.marker.data = markerData.marker.data;
			if(!existMarker.marker.elevation || existMarker.marker.elevation === undefined) existMarker.marker.elevation = "0";

			existMarker.marker.remakeMarker(
				(marker) => {
					marker.addToMap();
					this.updateFirebaseMarker(existMarker.marker,existMarker.id);
				},
				(marker) => {
					this.updateFirebaseMarker(marker,marker.docId);
				}
			);
		}
	}

	updateFirebaseMarker(marker,docId) {
		const uuid = this.props.uuid;
		const owneruid = marker.data.owneruid;
		const collectionPath = `userdata/${this.props.owneruid}/projects/${uuid}/items`;
		const data = {
			type:1,
			owneruid:owneruid,
			ownername:this.props.myName,
			ownercolor:this.props.myColor,
			lng: marker.lng,
			lat: marker.lat,
			elevation: parseInt(marker.elevation,10),
			name: marker.name,
			note: marker.note,
			iconNumber: marker.iconNumber,
			updatetime: new Date(),
		};

		this.props.fm.updateDocument(collectionPath, docId, data)
		.then(() => {
			console.log('Document successfully written!');
		})
		.catch((error) => {
			console.error('Failed to write document:', error);
		});
	}

	updateFirebasePhoto(marker,docId) {
		const uuid = this.props.uuid;
		const owneruid = marker.data.owneruid;
		const collectionPath = `userdata/${this.props.owneruid}/projects/${uuid}/items`;
		const data = {
			type:5,
			owneruid:owneruid,
			ownername:this.props.myName,
			ownercolor:this.props.myColor,
			lng: marker.lng,
			lat: marker.lat,
			elevation: parseInt(marker.elevation,10),
			name: marker.name,
			note: marker.note,
			iconNumber: marker.iconNumber,
			updatetime: new Date(),
		};

		this.props.fm.updateDocument(collectionPath, docId, data)
		.then(() => {
			console.log('Document successfully written!');
		})
		.catch((error) => {
			console.error('Failed to write document:', error);
		});
	}

	markerMove(marker) {
		// 移動処理をここに追加
		marker.startMove();
	}

	markerCopyToClipboard(marker) {
		marker.closePopup();
		const t = (key) => translator.t(key);
		toast(t('confirmCopyClipboard'), {autoClose: 1000});
		this.setState({ showOkConfirmDialog: false });
	}

    markerCopy(markerData) {
		markerData.closePopup();
		const { map } = this.state;
		if (!map) return;
		
		markerData.lng += 0.0001;
		markerData.lat += 0.0001;
		this.defaultIconNumber = markerData.iconNumber;
		const newMarker = new Marker(map, markerData.lng, markerData.lat, markerData.elevation, markerData, 60, null,this,null,null,null);
		const uuid = this.props.uuid;
		const owneruid = this.props.owneruid;
		const uuid2 = CommonUtils.generateUUID();
		const collectionPath = `userdata/${owneruid}/projects/${uuid}/items`;
		const documentId = uuid2;
		if(!markerData.elevation || markerData.elevation === undefined) markerData.elevation = "0";
		const data = {
			type:1,
			owneruid:this.props.fm.auth.currentUser.uid,
			ownername:this.props.myName,
			ownercolor:this.props.myColor,
			lng: markerData.lng,
			lat: markerData.lat,
			elevation: parseInt(markerData.elevation,10),
			name: markerData.name,
			note: markerData.note,
			iconNumber: markerData.iconNumber,
            createtime: new Date(),
            updatetime: new Date(),
		};

		this.props.fm.setDocument(collectionPath, documentId, data)
		.then(() => {
			console.log('Document successfully written!');
			newMarker.docId = documentId;
			this.updateProjectCoordinate();
		})
		.catch((error) => {
			console.error('Failed to write document:', error);
		});
    }

    markerRemove(marker) {
		const t = (key) => translator.t(key);
		let markerName = marker.name;
		if(!markerName || markerName.length < 1) markerName = t('Markers');
        this.showConfirm(CommonUtils.formatString(t('removeConfirm'),markerName)
			,marker
			, (data) => {
				this.setState({ showConfirmDialog: false });
				//削除処理
				this.removeMarkerFromMap(data.docId);
				this.props.fm.deleteDocument(`userdata/${this.props.owneruid}/projects/${this.props.uuid}/items`, data.docId);
			}
			,(data) => {
				this.setState({ showConfirmDialog: false });
        });
    }

    showConfirm = (message, data,onConfirm,onCancel) => {
		this.setState({ showConfirmDialog: true });
		this.setState({ confirmDialog: { message,data, onConfirm, onCancel} });
    };




    showOkConfirm = (message, data,onConfirm) => {
		this.setState({ showOkConfirmDialog: true });
		this.setState({ okConfirmDialog: { message,data, onConfirm} });
    };

	rotateEnaDisa(map,is2D) {
		if(is2D) {
			map.dragRotate.disable();
			map.touchZoomRotate.disableRotation();
			map.touchZoomRotate.enable(); 

			map.on('mousedown', (e) => {
				if (e.originalEvent.ctrlKey) {
					map.dragRotate.enable();
				} else {
					map.dragRotate.disable();
				}
			});

			map.on('mouseup', () => {
				map.setPitch(0);
				map.dragRotate.disable();
			});
		} else {
			map.setPitch(60);
			map.dragRotate.enable();
			map.touchZoomRotate.enableRotation();
			map.touchZoomRotate.enable(); 
		}
	}

	metersToPixelsAtLatitude(meters, latitude, zoomLevel) {
		const earthCircumference = 40075016.686; // 地球の円周（メートル）
		const latRad = latitude * Math.PI / 180; // 緯度をラジアンに変換
		const metersPerPixel = earthCircumference * Math.cos(latRad) / Math.pow(2, zoomLevel + 8); // ズームレベルに応じた1ピクセルあたりのメートル数
		return meters / metersPerPixel; // メートルをピクセルに変換
	}

	clickAddMarkerButton = async () => {
		//navigator.clipboard.readText();をすると、iOSだけペーストメニューが表示される仕様っぽい
		if(CommonUtils.isIOS()) {
			const dataCount = this.getDataCount()
			if(dataCount < MAX_DATA_COUNT) {
				this.setState({ showAddMarkerPopup: true });
			}
			else {
				const t = (key) => translator.t(key);
				toast.info(t('max_data_message')); 
			}

			setTimeout(() => {
				this.handlePasteMarker();
			}, 500);

		}
		else {
			if(await this.checkClipboardPasteMarker()) {
				this.handlePasteMarker();
				return;
			}
			const dataCount = this.getDataCount()
			if(dataCount < MAX_DATA_COUNT) {
				this.setState({ showAddMarkerPopup: true });
			}
			else {
				const t = (key) => translator.t(key);
				toast.info(t('max_data_message')); 
			}
		}
	}

	clickUploadButton = () => {
		const dataCount = this.getDataCount()
		if(dataCount < MAX_DATA_COUNT) {
			this.fileInputRef.click();
		}
		else {
			const t = (key) => translator.t(key);
			toast.info(t('max_data_message')); 
		}
	}

	clickPoszeroButton = () => {
		const { map } = this.state;
		if (!map) return;

		// ピッチとベアリングをリセット
		map.setPitch(0);
		map.setBearing(0);
		map.setTerrain(null);
		// 3D表示フラグをオフにする
		this.setState({ is3DEnabled: false });

		setTimeout(() => {
			this.customScale.update();
		}, 100);

	};

	handleAddMarker = (markerData) => {
		window.scrollTo(0, 0);
		const { map } = this.state;
		if (!map) return;

		this.defaultIconNumber = markerData.iconNumber;
//		const center = map.getCenter();
		const newMarker = new Marker(map, markerData.lng, markerData.lat, markerData.elevation, markerData, 60, null,this,null,null,null);
						//onstructor(map, lng, lat, alt, data, size, docId, boss,callback)
		const uuid = this.props.uuid;
		const owneruid = this.props.owneruid;
		const uuid2 = CommonUtils.generateUUID();
		const collectionPath = `userdata/${owneruid}/projects/${uuid}/items`;
		const documentId = uuid2;
		if(!markerData.elevation || markerData.elevation === undefined) markerData.elevation = "0";
		const data = {
			type:1,
			owneruid:this.props.fm.auth.currentUser.uid,
			ownername:this.props.myName,
			ownercolor:this.props.myColor,
			lng: markerData.lng,
			lat: markerData.lat,
			elevation: parseInt(markerData.elevation,10),
			name: markerData.name,
			note: markerData.note,
			iconNumber: markerData.iconNumber,
            createtime: new Date(),
            updatetime: new Date(),
		};

		this.props.fm.setDocument(collectionPath, documentId, data)
		.then(() => {
			console.log('Document successfully written!');
			newMarker.docId = documentId;
			this.updateProjectCoordinate();
		})
		.catch((error) => {
			console.error('Failed to write document:', error);
		});
		
		this.props.fm.checkAndSaveCurrentPosition(this.props.owneruid,this.props.uuid,this.state.map,this.currentProject);
	}

	addMarkerToMap(newMarker, docId) {
		const existMarker = this.markers.find(markerObj => markerObj.id === docId);
		if(existMarker) {
			this.removeMarkerFromMap(docId);
		}

		newMarker.addToMap();
		this.markers.push({id: docId, marker: newMarker});
		this.setState(prevState => ({
			showAddMarkerPopup: false 
		}));
	}

	removeMarkerFromMap(docId) {
		const markerObj =  this.markers.find(markerObj => markerObj.id === docId);
//	const testLabel = document.getElementById('testLabel');
//	testLabel.innerText = `removeMarkerFromMap: ${docId} / ${markerObj}`;

		if (markerObj) {
			markerObj.marker.remove(); // マーカーを地図から削除するメソッド
		}
		this.markers = this.markers.filter(markerObj => markerObj.id !== docId);
		this.updatePhotoMarkers();
	}

	makeMarkerGPX(type) {
		let header = `<?xml version="1.0" encoding="UTF-8"?>
		<gpx version="1.1" creator="collabomap - https://collabomap.com/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.topografix.com/GPX/1/1" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" xmlns:kashmir3d="http://www.kashmir3d.com/namespace/kashmir3d" xmlns:geographica="http://geographica.biz/namespace/geographica">`;

		if(type === "picture" ) {
			header = `<?xml version="1.0" encoding="UTF-8"?>
			<gpx version="1.1" creator="collabomap - https://collabomap.com/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.topografix.com/GPX/1/1" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" xmlns:kashmir3d="http://www.kashmir3d.com/namespace/kashmir3d" xmlns:geographica="http://geographica.biz/namespace/geographica" xmlns:type="picture">`;		
		}

		const footer = '\n</gpx>';

		let waypoints = '';

		this.markers.forEach(markerObj => {
			const marker = markerObj.marker;

			let time = marker.timestamp ? new Date(marker.data.updatetime).toISOString() : new Date().toISOString();
			if(type === "marker" && marker.data.fileurl && marker.data.iconurl) {
				return;
			} 
			else if(type === "picture" && (!marker.data.fileurl || !marker.data.iconurl)) {
				return;
			} 

			if(type === "picture") {
				time = CommonUtils.formatDateTime_FirestoreOrDateOrString(marker.data.dateTimeOriginal);
			}

			const lat = marker.lat.toFixed(6);
			const lon = marker.lng.toFixed(6);
			const ele = (marker.elevation || 0).toFixed(6);
			const name = marker.name || '';
			const cmt = marker.note || '';
			const icon = marker.iconNumber || 0;
			let fileUrsXML = "";
			if(marker.data.fileurl && marker.data.iconurl) {
				const encodedFileUrl = encodeURIComponent(marker.data.fileurl);
				const encodedIconUrl = encodeURIComponent(marker.data.iconurl);
				fileUrsXML = `\n				<geographica:fileurl>${encodedFileUrl}</geographica:fileurl>\n				<geographica:iconurl>${encodedIconUrl}</geographica:iconurl>`;
			}

			waypoints += `
		<wpt lat="${lat}" lon="${lon}" iswarning="0">
			<ele>${ele}</ele>
			<time>${time}</time>
			<name>${CommonUtils.escapeXml(name)}</name>
			<cmt>${CommonUtils.escapeXml(cmt)}</cmt>
			<extensions>
				<kashmir3d:ident></kashmir3d:ident>
				<kashmir3d:icon>901001</kashmir3d:icon>
				<geographica:ownerID>${marker.data.owneruid}</geographica:ownerID>
				<geographica:ownerName>${marker.data.ownername}</geographica:ownerName>
				<geographica:ownerColor>${CommonUtils.colors[marker.data.ownercolor]}</geographica:ownerColor>
				<geographica:uuid>${marker.docId}</geographica:uuid>
				<geographica:icon>${icon}</geographica:icon>${fileUrsXML}
			</extensions>
		</wpt>`;
		});

		return header + waypoints + footer;
	}


	// 線データを地図に追加する関数
	addLineToMap = (lineData, docId) => {
		const { map } = this.state;
		if (!map) {
			console.error("Map is not initialized");
			return;
		}
		const lineCoordinates = lineData.coordinates.map(coord => [coord.lng, coord.lat]);
		const source = this.state.map.getSource(this.myDrawing);
		const data = source._data;

		data.features.push({
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: lineCoordinates
			},
			properties: {
				id: docId,
				color: lineData.color,
				width: lineData.width,
			}
		});

		source.setData(data);
	};

	// 線データを地図から削除する関数
	removeLineFromMap = (docId) => {
		const source = this.state.map.getSource(docId);
		const data = source._data;

		if(data.features) {
			data.features = data.features.filter(feature => feature.properties.id !== docId);
		}
		else {
			data.features = [];
		}
		source.setData(data);

	};

	// 線データを地図上で更新する関数
	updateLineOnMap = (lineData, docId) => {
		const lineCoordinates = lineData.coordinates.map(coord => [coord.lng, coord.lat]);
		const source = this.state.map.getSource(this.myDrawing);
		const data = source._data;

		const lineIndex = data.features.findIndex(feature => feature.properties.id === docId);
		if (lineIndex !== -1) {
			data.features[lineIndex].geometry.coordinates = lineCoordinates;
			data.features[lineIndex].properties.color = lineData.color;
			data.features[lineIndex].properties.width = lineData.width;
			source.setData(data);
		}
	};



	togglePalettePopup = () => {
		this.setState(prevState => ({
			showPalettePopup: !prevState.showPalettePopup
		}));
	};

	handleColorSelect = (color,opacity) => {
		console.log('handleColorSelect:',color);
		this.setState({ selectedColor: color });
		this.setState({ selectedOpacity: opacity });
//		this.setState({ showPalettePopup: false });

		localStorage.setItem('selectedColor', color);
		localStorage.setItem('selectedOpacity', opacity.toString());
	};

	handleLineWidthChange = (width) => {
		this.setState({ lineWidth: width });
		localStorage.setItem('lineWidth', width.toString());
	};

	handleLineOpacityChange = (color,opacity) => {
		this.setState({ selectedOpacity: opacity });
		this.setState({ selectedColor: color });

		localStorage.setItem('selectedColor', color);
		localStorage.setItem('selectedOpacity', opacity.toString());
	};



	toggleMapSharePopup = () => {
		this.setState(prevState => ({
			showMapSharePopup: !prevState.showMapSharePopup
		}));
		if(this.state.showMapSharePopup) this.updateProjectCoordinate();
	};
	clickFavoButton = () => {
		const isfavo = !this.state.isfavorite;
		if(this.props.fm && this.props.fm.auth && this.props.fm.auth.currentUser) {
			const myCollectionPath = `userdata/${this.props.fm.auth.currentUser.uid}/projects/`;

			let data = null;
			data = {
				isfavorite: isfavo,
			};
			try {
				this.props.fm.updateDocument(myCollectionPath, this.props.uuid, data);
			} catch (error) {
				console.error('Failed to clickFavoButton:', error);
			}
			this.setState({isfavorite:isfavo});
		}
	};

	changeDrawingMode = () => {
		const {map} = this.state;
		map.resetNorth();
		map.setPitch(0)
		const t = (key) => translator.t(key);
		const buttonDrawMode = document.getElementById('buttonDrawMode');
		if(!buttonDrawMode) return;

		/*if(this.state.isDrawingMode === 0) {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'none';
			}

			this.state.map.doubleClickZoom.disable();
			this.setState({isDrawingMode:1});
			buttonDrawMode.textContent = t('freehand_drawing');
			map.getCanvas().style.cursor = 'url(/img/pen.png) 0 32, auto';
		}*/
		if(this.state.isDrawingMode === 1) {//フリーハンド描画
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'none';
			}

			this.state.map.doubleClickZoom.disable();
			this.setState({isDrawingMode:2});
			buttonDrawMode.textContent = t('freehand_eraser');
			map.getCanvas().style.cursor = 'url(/img/eraser.png) 0 32, auto';

			this.setState({
				penButtonBG:normalBackGround,
				shapeButtonBG:normalBackGround,
				eraserButtonBG:activeBackGround,
			});	
		}
		else {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'auto';
			}
			this.state.map.doubleClickZoom.enable();
			this.setState({isDrawingMode:0});
			buttonDrawMode.textContent = t('freehand_drawing');
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			map.getCanvas().style.cursor = 'grab';

			this.setState({
				penButtonBG:normalBackGround,
				shapeButtonBG:normalBackGround,
				eraserButtonBG:normalBackGround,
			});	
		}
	};

	//アンドゥボタン
	handleUndo = () => {
		this.undo();
	};

	clickPenButton = () => {
		this.endShapeAll();

		const {map} = this.state;
		map.resetNorth();
		map.setPitch(0)

		if(this.state.isDrawingMode === 1) {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'auto';
			}
			this.state.map.doubleClickZoom.enable();
			this.setState({isDrawingMode:0});
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			map.getCanvas().style.cursor = 'grab';
			this.setState({
				penButtonBG:normalBackGround,
				shapeButtonBG:normalBackGround,
				eraserButtonBG:normalBackGround,
			});	
		}
		else {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'none';
			}

			this.state.map.doubleClickZoom.disable();
			this.setState({isDrawingMode:1});

			map.getCanvas().style.cursor = 'url(/img/pen.png) 0 32, auto';

			this.setState({
				penButtonBG:activeBackGround,
				shapeButtonBG:normalBackGround,
				eraserButtonBG:normalBackGround,
			});	
		}
	};
	clickShapeButton = () => {
		const {map} = this.state;
		map.resetNorth();
		map.setPitch(0)
		this.shapeDrawing = false;

		if(this.state.isDrawingMode === 3) {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'auto';
			}
			this.state.map.doubleClickZoom.enable();
			this.setState({isDrawingMode:0});
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			map.getCanvas().style.cursor = 'grab';
			this.setState({
				penButtonBG:normalBackGround,
				shapeButtonBG:normalBackGround,
				eraserButtonBG:normalBackGround,
			});	
		}
		else {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'none';
			}

			this.state.map.doubleClickZoom.disable();
			this.setState({isDrawingMode:3});

			map.getCanvas().style.cursor = 'crosshair';

			this.setState({
				penButtonBG:normalBackGround,
				shapeButtonBG:activeBackGround,
				eraserButtonBG:normalBackGround,
			});

			if(!this.state.shapeDrawingMode || this.state.shapeDrawingMode === undefined || this.state.shapeDrawingMode === '') {
				this.setState({
					shapeDrawingMode: 'line',
					lineButtonBG: activeBackGround,
					circleButtonBG: normalBackGround,
					polygonButtonBG: normalBackGround,
					measurementButtonBG: normalBackGround,
				});
			}
		}


	};

	//検索
	clickSearchButton = () => {
		this.setState(prevState => ({
			showSearchPopup: !prevState.showSearchPopup
		}));
	};

	handleSearchSubmit = (result) => {
		const { map } = this.state;
		if (map) {
		map.flyTo({
			center: [result.lng, result.lat],
			zoom: 15
		});
		}
		this.setState({ showSearchPopup: false });
	};

	handleSearchClose = () => {
		this.setState({ showSearchPopup: false });
	};

	handleSearchUpdate = (keyword, results) => {
		this.setState({ searchKeyword: keyword, searchResults: results });
	};

	//消しゴム
	clickEraserButton = () => {
		this.endShapeAll();

		const {map} = this.state;
		map.resetNorth();
		map.setPitch(0)
		const t = (key) => translator.t(key);

		if(this.state.isDrawingMode === 2) {
			const centerMark = document.getElementById('centerMark');
			if (centerMark) {
				centerMark.style.pointerEvents = 'auto';
			}
			this.state.map.doubleClickZoom.enable();
			this.setState({isDrawingMode:0});
			map.touchZoomRotate.enable();
			map.dragPan.enable();
			map.getCanvas().style.cursor = 'grab';
			this.setState({ penButtonBG: normalBackGround});
			this.setState({ shapeButtonBG: normalBackGround});
			this.setState({ eraserButtonBG: normalBackGround});

			return;
		}
		const centerMark = document.getElementById('centerMark');
		if (centerMark) {
			centerMark.style.pointerEvents = 'none';
		}

		this.state.map.doubleClickZoom.disable();
		this.setState({isDrawingMode:2});
		map.getCanvas().style.cursor = 'url(/img/eraser.png) 0 32, auto';
		this.setState({ penButtonBG: normalBackGround});
		this.setState({ shapeButtonBG: normalBackGround});
		this.setState({ eraserButtonBG: activeBackGround});
	};

	update3dDistance = (distance) => {
		if(distance < 1000) distance = distance + "m";
		else distance = (distance / 1000).toFixed(1) + "km";
		this.setState({ labelScaleDistance: distance });
	}

	updateProjectCoordinate = async () => {
		if(!this.props.fm || !this.props.fm.auth || !this.props.fm.auth.currentUser || !this.props.fm.auth.currentUser.uid) return;

		const myCollectionPath = `userdata/${this.props.fm.auth.currentUser.uid}/projects/`;
		const center = this.state.map.getCenter();
		const zoomLevel = this.state.map.getZoom();
		let data = null;
		data = {
			lng:center.lng,
			lat:center.lat,
			zoom:zoomLevel,
			updatetime: new Date(),
		};
		try {
			this.props.fm.updateDocument(myCollectionPath, this.props.uuid,data);
		} catch (error) {
			console.error('Failed to updateDocument:', error);
		}
	}

	makeThumbnail = async (title) => {
		try {
			const thumbnailUrl = await this.saveMapScreenshotToFirestorage(400,title);
			this.setState({ 
				showThumbnailPopup: true, 
			});
			this.thumbnailUrl = thumbnailUrl;

			if (thumbnailUrl) {
				const myCollectionPath = `userdata/${this.props.owneruid}/projects/`;
				const center = this.state.map.getCenter();
				const zoomLevel = this.state.map.getZoom();

				let data = null;
				data = {
					lng:center.lng,
					lat:center.lat,
					zoom:zoomLevel,
					thumbnailUrl:thumbnailUrl,
				};
				try {
					this.props.fm.updateDocument(myCollectionPath, this.props.uuid,data);
				} catch (error) {
					console.error('Failed to updateDocument:', error);
				}
			}
		} catch (error) {
			console.error('Error creating thumbnail:', error);
			// You might want to show an error message to the user here
		}
	}
	closeThumbnailPopup = () => {
		this.setState({ showThumbnailPopup: false });
	}
	closeImagePopup = () => {
		this.setState({ showImagePopup: false });
	}

	deleteProject = async (title) => {
		const t = (key) => translator.t(key);
        this.showConfirm(CommonUtils.formatString(t('removeConfirm'),title)
			,this
			, (data) => {
				this.showConfirm(CommonUtils.formatString(t('reConfirm'),title)
					,this
					, (data) => {
					this.showConfirm(CommonUtils.formatString(t('rereConfirm'),title)
						,this
						, (data) => {
						this.setState({ showConfirmDialog: false });
							//削除処理
							this.setState({ showConfirmDialog: false,showOkConfirmDialog:false });
							this.props.fm.deleteProject(this.props.uuid, this.props.owneruid
								, () => {
									this.showOkConfirm(t('deleteed'),null
										, (data) => {
											window.location.href = `/top`;
										});
								}
								, (errorMsg) => {
									this.showOkConfirm(t('Error') + ':' + errorMsg,null
										, (data) => {
										});
								}
							);
						}
						,(data) => {
							this.setState({ showConfirmDialog: false });
					});		
					}
					,(data) => {
						this.setState({ showConfirmDialog: false });
				});		
			}
			,(data) => {
				this.setState({ showConfirmDialog: false });
        });		


	}


	handleSetting = () => {
		this.setState(prevState => ({
			showEditProjectPopup: !prevState.showEditProjectPopup
		}));
		//this.setState({ showEditProjectPopup: true });
	};
	closeSettingPopup = () => {
		this.setState({ showEditProjectPopup: false });
	};

	closePopup = () => {
		this.setState({ showAddMarkerPopup: false });
		window.scrollTo(0, 0);
	};



	toggleLMapSetting = () => {
		this.setState(prevState => ({
			showLayerSettingPopup: !prevState.showLayerSettingPopup
		}));
	}
	// handleApplyLayerSettings関数を追加
	handleApplyLayerSettings = (newOverlay, overlayOpacity, newMap, newExaggeration) => {
		const { map } = this.state;
		if (map) {
			this.currentOverlay = newOverlay;
			this.currentMap = newMap;

			let layerSetting = CommonUtils.overlays[newOverlay];
			if(layerSetting) layerSetting.op = overlayOpacity;

			this.setupOverlay(map, newOverlay);
			this.setupBaseMap(map, newMap);

			const collectionPath = `userdata/${this.props.owneruid}/projects/`;
			const data = {
				overlay: newOverlay,
				basemap: newMap,
				updatetime: new Date(),
				exaggeration: newExaggeration,
			};

			// CommonUtils.overlays から op が 40 でないものを列挙
			let currentLayerSetting = { ...CommonUtils.overlays[newOverlay] };
			const nonStandardOpacityOverlays = Object.entries(CommonUtils.overlays).reduce((acc, [key, value]) => {
				if (value.op !== 40 && value.op !== 0 && key !== newOverlay) {  // 現在のオーバーレイは別途処理するため除外
					acc[key] = { ...value };
				}
				return acc;
			}, {});

			// 現在のオーバーレイの opacity が 40 でない場合、または他に非標準の opacity を持つオーバーレイがある場合
			if (overlayOpacity !== 40 || Object.keys(nonStandardOpacityOverlays).length > 0) {
				data.overlays = {
					...nonStandardOpacityOverlays,
					[newOverlay]: currentLayerSetting
				};
			}

			this.props.fm.updateDocument(collectionPath, this.props.uuid, data)
			.then(() => {
				console.log('handlePhotoUpdateMemo successfully written!');
				this.updateProjectCoordinate();
				this.setState({ exaggeration: newExaggeration });

				this.createTerrainControl(newExaggeration);
				
			})
			.catch((error) => {
				console.error('Failed to write handlePhotoUpdateMemo:', error);
			});
		}
	}

	createTerrainControl(exaggeration = 1) {
		const { map } = this.state;
		if (map) {
			// 既存の TerrainControl があれば削除
			if (this.terrainControl) {
				map.removeControl(this.terrainControl);
			}

			// 新しい TerrainControl を作成
			this.terrainControl = new maplibregl.TerrainControl({
				source: 'terrain',
				exaggeration: exaggeration
			});

			map.addControl(this.terrainControl, 'top-right');
		}
	}


	// カスタムレイヤーの更新用関数
	updateCustomLayer = (type, key, updatedLayer) => {
		if (type === 'overlay') {
			this.customOverlays[key] = updatedLayer;
		} else if (type === 'map') {
			this.customMaps[key] = updatedLayer;
		}
		// カスタムレイヤーの保存
		const customOverlays = Object.entries(this.customOverlays)
			.filter(([key, value]) => value.url)
			.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

		const customMaps = Object.entries(this.customMaps)
			.filter(([key, value]) => value.url)
			.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

		const collectionPath = `userdata/${this.props.owneruid}/projects/`;
		const data = {
			updatetime: new Date(),
		};
		if (Object.keys(customOverlays).length > 0 || Object.keys(customMaps).length > 0) {
			data.customLayers = {
				overlays: customOverlays,
				maps: customMaps
			};
		}

		this.props.fm.updateDocument(collectionPath, this.props.uuid, data)
		.then(() => {
			console.log('handlePhotoUpdateMemo successfully written!');
			this.updateProjectCoordinate();
		})
		.catch((error) => {
			console.error('Failed to write handlePhotoUpdateMemo:', error);
		});

	}


	toggleLayerListPanel = () => {
		this.setState(prevState => ({
			showLayerListPanel: !prevState.showLayerListPanel
		}));
	}
	
	toggleDataListPanel = () => {
		this.setState(prevState => ({
			showDataListPanel: !prevState.showDataListPanel
		}));
	}
	
	handleDeleteFile = (file) => {
		const { map } = this.state;

		console.log('handleDeleteFile:' + file.id);
		console.log('handleDeleteFile:' + file.data.owneruid);
		console.log('handleDeleteFile:' + file.data.fileName);
		const uuid = this.props.uuid;
		const owneruid = this.props.owneruid;

		const t = (key) => translator.t(key);
		this.showConfirm(
			CommonUtils.formatString(t('removeConfirm'),file.data.fileName),
			file.data,
			(data) => {
				const source = map.getSource(file.id);
				if(source) source.setData([]);
				this.currentLine = [];

				if(file.data.type === 2) {
					map.removeLayer(file.id);
					map.removeSource(file.id);
					this.drawingHistory = [];
					if(file.id === this.myDrawing) {
						this.addDrawingSource(map,file.id);
						this.addDrawingLayer(map,file.id);
						console.log('addDrawingSource:addDrawingLayer');							
					}
				}
				else if(file.data.type === 3) {
					map.removeLayer(file.id + '-fill');
					map.removeLayer(file.id + '-outline');
					map.removeLayer(file.id + '-label');
					map.removeLayer(file.id + '-center-point-layer');
					map.removeSource(file.id);

					if(file.id === this.myShape) {
						this.addShapeLayer(map,file.id);
						this.addLabelSourceAndLayer(map,file.id);
					}
				}
				else if(file.data.type === 4) {
					const style = map.getStyle();
					const sources = Object.keys(style.sources).filter(sourceId => sourceId.includes(file.id));
					const layers = style.layers.filter(layer => layer.id.includes(file.id)).map(layer => layer.id);

					sources.forEach(sourceId => {
						const source = map.getSource(sourceId);
						if (source) {
							source.setData([]);
						}
					});
					layers.forEach(layerId => {
						map.removeLayer(layerId);
					});

					this.setState(prevState => ({
						files: prevState.files.filter(item => item.id !== file.id)
					}));

				}

				this.setState({ showConfirmDialog: false });
				// FileHandlerの削除ロジックを呼び出す
				if (this.fileHandler) {
					this.fileHandler.deleteFile(owneruid,file.id,data);
				}
			},
			() => {
				this.setState({ showConfirmDialog: false });
			}
		);
		


	}
	onDeleteMarker = (marker) => {
		this.markerRemove(marker.marker);
	}
	onDeletePhoto = (photo) => {
		this.handlePtohoDelete(photo);
	}

	clickLineButton = () => {
		this.setState({
			shapeDrawingMode: 'line',
			lineButtonBG: activeBackGround,
			circleButtonBG: normalBackGround,
			polygonButtonBG: normalBackGround,
			measurementButtonBG:normalBackGround,
		});	
	};

	clickMeasurementButton = () => {
		this.setState({
			shapeDrawingMode: 'measurement',
			lineButtonBG: normalBackGround,
			circleButtonBG: normalBackGround,
			polygonButtonBG: normalBackGround,
			measurementButtonBG:activeBackGround,
		});	
	};

	clickCircleButton = () => {
		this.setState({
			shapeDrawingMode: 'circle',
			lineButtonBG: normalBackGround,
			circleButtonBG: activeBackGround,
			polygonButtonBG: normalBackGround,
			measurementButtonBG:normalBackGround,
		});
	};


	clickPolygonButton = () => {
		this.setState({
			shapeDrawingMode: 'polygon',
			lineButtonBG: normalBackGround,
			circleButtonBG: normalBackGround,
			polygonButtonBG: activeBackGround,
			measurementButtonBG:normalBackGround,
		});
	};

    clickChatButton = () => {
        this.setState(prevState => ({
            showChatPanel: !prevState.showChatPanel
        }));
    };

	handlePhotoUpdateMemo = (photoId,memo) => {
		console.log(`handlePhotoUpdateMemo:${photoId} / ${memo}`);

		const uuid = this.props.uuid;
		const owneruid = this.props.owneruid;
		const collectionPath = `userdata/${owneruid}/projects/${uuid}/items`;
		const data = {
			note: memo,
            updatetime: new Date(),
		};

		this.props.fm.updateDocument(collectionPath, photoId, data)
		.then(() => {
			console.log('handlePhotoUpdateMemo successfully written!');
			this.updateProjectCoordinate();
		})
		.catch((error) => {
			console.error('Failed to write handlePhotoUpdateMemo:', error);
		});
	}

	handlePtohoDelete = (photo) => {
		const t = (key) => translator.t(key);
		this.showConfirm(
			t('DeletePhotoConfirmation'),
			photo,
			(data) => {
				this.setState({ showConfirmDialog: false });
				// FileHandlerの削除ロジックを呼び出す
				if (this.fileHandler) {
					this.fileHandler.deletePhoto(data);
					this.removeMarkerFromMap(data.docId);
				}
				// 写真ビューアを閉じる
				this.closePhotoViewer();
			},
			() => {
				this.setState({ showConfirmDialog: false });
			}
		);
	};

	handlePtohoMove = (photo) => {
		this.closePhotoViewer();
		const markerObj =  this.markers.find(markerObj => markerObj.id === photo.docId);
		if(markerObj && markerObj.marker) {
			markerObj.marker.startMove();
		}
	};


	captureMapScreenshot = (sizeWidth,sizeHeight, title) => {
		return new Promise((resolve, reject) => {
			const { map } = this.state;
			if (!map) {
				reject(new Error("Map is not initialized"));
				return;
			}
			this.setState({ isShowUI: false });
			const thumbnailPopup = document.getElementById('thumbnailPopup');
			if (thumbnailPopup) thumbnailPopup.style.display = 'none';

			let captureAttempted = false;

			const captureMap = () => {
			if (captureAttempted) return;
				captureAttempted = true;

				try {
					html2canvas(map.getContainer(), {
						useCORS: true,
						allowTaint: true,
						foreignObjectRendering: true
					}).then(canvas => {
						this.setState({ isShowUI: true });
						if (thumbnailPopup) thumbnailPopup.style.display = 'none';

						// 現在のマップのサイズを取得
						const width = canvas.width;
						const height = canvas.height;

						// 正方形のサイズを決定（短い辺に合わせる）
						const size = Math.min(width, height);

						// 切り取り開始位置を計算（中央に配置）
						const startX = (width - size) / 2;
						const startY = (height - size) / 2;

						// 一時的なキャンバスを作成して正方形部分を切り出す
						const tempCanvas = document.createElement('canvas');
						tempCanvas.width = size;
						tempCanvas.height = size;
						const tempCtx = tempCanvas.getContext('2d');
						tempCtx.drawImage(canvas, startX, startY, size, size, 0, 0, size, size);

						// 指定されたサイズにリサイズ
						const outputCanvas = document.createElement('canvas');
						outputCanvas.width = sizeWidth;
						outputCanvas.height = sizeHeight;
						const outputCtx = outputCanvas.getContext('2d');
						outputCtx.imageSmoothingEnabled = true;
						outputCtx.imageSmoothingQuality = 'high';
						outputCtx.drawImage(tempCanvas, 0, size/8, size, size/2, 0, 0, outputCanvas.width, outputCanvas.height);

						// タイトルを描画
						const color = CommonUtils.numberToHexColor(CommonUtils.colors[this.props.myColor]);
						
						// フォントサイズを計算
						const maxWidth = outputCanvas.width * 0.9; // 画像幅の90%を最大幅とする
						const calculateFontSize = (ctx, text, maxWidth) => {
							let fontSize = 50; // 開始フォントサイズ
							ctx.font = `bold ${fontSize}px Arial`;
							while (ctx.measureText(text).width > maxWidth && fontSize > 10) {
								fontSize--;
								ctx.font = `bold ${fontSize}px Arial`;
							}
							return fontSize;
						};

						if(title.length > 0) {
							const fontSize = calculateFontSize(outputCtx, title, maxWidth);
							outputCtx.font = `bold ${fontSize}px Arial`;
							outputCtx.textAlign = 'center';
							outputCtx.textBaseline = 'middle';

							// 縁取りを描画
							outputCtx.strokeStyle = 'white';
							outputCtx.lineWidth = Math.max(2, fontSize / 10); // フォントサイズに応じて線の太さを調整
							outputCtx.strokeText(title, outputCanvas.width / 2, outputCanvas.height/2);

							// タイトルを描画
							outputCtx.fillStyle = color;
							outputCtx.fillText(title, outputCanvas.width / 2, outputCanvas.height/2);
						}

						// 最終的な画像データを生成
						const imageData = outputCanvas.toDataURL('image/jpeg', 0.95);
						resolve(imageData);
					}).catch(error => {
						this.setState({ isShowUI: true });
						if (thumbnailPopup) thumbnailPopup.style.display = 'block';
						reject(new Error("Failed to capture map: " + error.message));
					});
				} catch (error) {
					this.setState({ isShowUI: true });
					if (thumbnailPopup) thumbnailPopup.style.display = 'block';
					reject(error);
				}
			};

			const onRender = () => {
				captureMap();
			};

			map.on('render', onRender);
			map.triggerRepaint();

			// フォールバックとしてのタイムアウト
			setTimeout(() => {
			if (!captureAttempted) {
				console.warn('Capture timeout reached, forcing capture');
				captureMap();
			}
			}, 5000); // 5秒後にフォールバック
		});
	};

	//フルスクリーンのスクリーンショット処理
	//変数の調整がいまいち
	captureMapFullScreenshot = () => {
		return new Promise((resolve, reject) => {
			const { map } = this.state;
			if (!map) {
				reject(new Error("Map is not initialized"));
				return;
			}
			this.setState({ isShowUI: false });
//			const thumbnailPopup = document.getElementById('thumbnailPopup');
//			if (thumbnailPopup) thumbnailPopup.style.display = 'none';

			let captureAttempted = false;

			const captureMap = () => {
			if (captureAttempted) return;
				captureAttempted = true;

				try {

					// ドキュメント全体の高さと幅を取得
					const fullHeight = Math.max(
						document.body.scrollHeight,
						document.documentElement.scrollHeight,
						document.body.offsetHeight,
						document.documentElement.offsetHeight,
						document.body.clientHeight,
						document.documentElement.clientHeight
					);

					const fullWidth = Math.max(
						document.body.scrollWidth,
						document.documentElement.scrollWidth,
						document.body.offsetWidth,
						document.documentElement.offsetWidth,
						document.body.clientWidth,
						document.documentElement.clientWidth
					);
					
					html2canvas(map.getContainer(), {
						y:-50,
						height: fullHeight* 1.1,
						width: fullWidth* 1.1,
						scrollX: -window.scrollX,
						scrollY: -window.scrollY,
						windowHeight: fullHeight* 1.1,
						windowWidth: fullWidth* 1.1,
						useCORS: true,
						allowTaint: true,
						foreignObjectRendering: true,
						logging: false,
						imageTimeout: 0,
						scale: window.devicePixelRatio
					}).then(canvas => {
						this.setState({ isShowUI: true });
//						if (thumbnailPopup) thumbnailPopup.style.display = 'none';

						const a = fullHeight* 1.1 - fullHeight;
						const b = fullWidth* 1.1 - fullWidth;

						// 一時的なキャンバスを作成して正方形部分を切り出す
						const tempCanvas = document.createElement('canvas');
						tempCanvas.width = fullWidth;
						tempCanvas.height = fullHeight;
						const tempCtx = tempCanvas.getContext('2d');
						tempCtx.drawImage(canvas, 0,0, fullWidth - b, fullHeight - a, 0, 0, fullWidth, fullHeight);


						// 最終的な画像データを生成
						const imageData = tempCanvas.toDataURL('image/jpeg', 0.95);
						resolve(imageData);
					}).catch(error => {
						this.setState({ isShowUI: true });
//						if (thumbnailPopup) thumbnailPopup.style.display = 'block';
						reject(new Error("Failed to capture map: " + error.message));
					});
				} catch (error) {
					this.setState({ isShowUI: true });
//					if (thumbnailPopup) thumbnailPopup.style.display = 'block';
					reject(error);
				}
			};

			const onRender = () => {
				captureMap();
			};

			map.on('render', onRender);
			map.triggerRepaint();

			// フォールバックとしてのタイムアウト
			setTimeout(() => {
			if (!captureAttempted) {
				console.warn('Capture timeout reached, forcing capture');
				captureMap();
			}
			}, 5000); // 5秒後にフォールバック
		});
	};

	//iOSでなぜかマップタイルの読み込みが遅れる？のでダミー関数で1回待つ
	waitCaptureMapScreenshot = () => {
		return new Promise((resolve, reject) => {
			const { map } = this.state;
			if (!map) {
				reject(new Error("Map is not initialized"));
				return;
			}

			// マップの再描画を待つ
			map.once('idle', () => {
				try {
					// マップコンテナ全体（マーカーを含む）をキャプチャ
					html2canvas(map.getContainer(), {
						useCORS: true,
						allowTaint: true,
						foreignObjectRendering: true
					}).then(canvas => {
						const imageData = canvas.toDataURL('image/jpeg', 0.95);
						resolve(imageData);					
					}).catch(error => {
						reject(error);
					});
				}
				catch (error) {
					reject(error);
				}
			});
			// 強制的にマップの再描画をトリガー
			map.triggerRepaint();
		});
	}

	saveMapScreenshotToFirestorage = async (outputSize,title) => {
		try {
			if(CommonUtils.isIOS()) {//iOSだとスクショが撮れないことが多々あるので待つ
				this.setState({ isShowUI: false });
				const thumbnailPopup = document.getElementById('thumbnailPopup');
				thumbnailPopup.style.display = 'none';
				await this.waitCaptureMapScreenshot();
//				await this.waitCaptureMapScreenshot();
			}//他の場所で待ってみたりいろいろやってみたが、他に解決方法が無かった。良い方法が見つかったら直して

			const imageData = await this.captureMapScreenshot(outputSize,outputSize / 2,title);

			// 画像のロードが完了するまで待機
			await new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = resolve;
				img.onerror = reject;
				img.src = imageData;
			});

			//const settingButton = document.getElementById('settingButton');
			//settingButton.src = imageData;

			const thumbnailPopup = document.getElementById('thumbnailPopup');
			if (thumbnailPopup) thumbnailPopup.style.display = 'block';

			const fileName = `screenshot.jpg`;
			const filePath = `files/${this.props.owneruid}/${this.props.uuid}/${this.props.owneruid}/open/${fileName}`;

			// Base64データをBlobに変換
			const byteString = atob(imageData.split(',')[1]);
			const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([ab], {type: mimeString});

			// Firebase Storageにアップロード
			const fileUrl = await this.props.fm.uploadFile(filePath, blob);
			console.log('Screenshot uploaded:', fileUrl);

			return fileUrl;
		}
		catch (error) {
			console.error('Error saving screenshot:', error);
			throw error;
		}
	}
	togglePrintMap = () => {

		const printer = new MapLibrePrinter(this.state.map);
		printer.printMap('A4', 'landscape', 50000);
	};

	toggleScreenShot = () => {
		this.captureFullPage();
	};

	captureFullPage = async () => {
		try {
		
			const imageData = await this.captureMapFullScreenshot();

			// 画像のロードが完了するまで待機
			await new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = resolve;
				img.onerror = reject;
				img.src = imageData;
			});

			//const settingButton = document.getElementById('settingButton');
			//settingButton.src = imageData;

			// Base64データをBlobに変換
			const byteString = atob(imageData.split(',')[1]);
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([ab], {type: 'image/png'});

			const clipboardItem = new ClipboardItem({ 'image/png': blob });
			await navigator.clipboard.write([clipboardItem]);

			console.log('Screenshot copied to clipboard successfully');

			// BlobをURLに変換してセット
			const blobUrl = URL.createObjectURL(blob);
			this.setState({ 
				showImagePopup: true,
				imageUrl: blobUrl
			});
			this.imageUrl = blobUrl;

			return blob;
		}
		catch (error) {
			console.error('Error saving screenshot:', error);
			throw error;
		}
	};

	getDataCount() {
		const markerCount = this.markers ? this.markers.length : 0;
		const fileCount = this.state.files ? this.state.files.length : 0;
		return markerCount + fileCount;
	}


	mapCopy = () => {
		const t = (key) => translator.t(key);

		if(!this.currentProject.iscopyable) {
			this.showOkConfirm(t('Copy not possible'),null
			, (data) => {
				this.setState({ showOkConfirmDialog: false });
			});
			return;
		}


        this.showConfirm(CommonUtils.formatString(t('ConfirmCopyMap'),this.currentProject.title )
			,this
			, (data) => {
				this.startLoading(t('waitingMessage'));
				this.setState({ showConfirmDialog: false });
				this.props.fm.copyProject(this.props.uuid,this.props.owneruid,this.props.fm.auth.currentUser.uid,this.props.myName
				, (copyProjectData,copyDocId) => {
					const lat = this.state.map.getCenter().lat;
					const lng = this.state.map.getCenter().lng;
					const zoom = this.state.map.getZoom();

					this.showOkConfirm(t('ConfirmMoveCopyMap'),null
					, (data) => {
						this.endLoading();
						window.location.href = `/map/${copyDocId}_${copyProjectData.owneruid}#${zoom}/${lat}/${lng}`;
					});
				}
				, (errorMsg) => {
					this.endLoading();
				});
			}
			,(data) => {
				this.setState({ showConfirmDialog: false });
        });
    }
	

	render() {
		const t = (key) => translator.t(key);
//		console.log(`render this.state.protruding ${this.props.protruding}`);


		const isRightHanded = this.props.userSettings && this.props.userSettings.handedness === 1;
		const buttonStyle = {
			position: 'absolute',
			[isRightHanded ? 'right' : 'left']: '10px',
		};
		const buttonStyleB = {
			position: 'absolute',
			[isRightHanded ? 'left' : 'right']: '10px',
		};

		const penButtonStyle = { ...buttonStyle, top: 'calc(50% - 80px)' };
		const shapeButtonStyle = { ...buttonStyle, top: 'calc(50% - 40px)' };
		const eraserButtonStyle = { ...buttonStyle, top: '50%' };
		const paletteButtonStyle = { ...buttonStyle, top: 'calc(50% + 40px)' };
		const undoButtonStyle = { ...buttonStyle, top: 'calc(50% + 100px)' };

		const poszeroButtonStyle = { ...buttonStyleB, top: 'top: 200px',position: 'absolute' };
		const addMarketButtonStyle = { ...buttonStyleB, top: 'top: 240px' };
		const uploadButtonStyle = { ...buttonStyleB, top: 'top: 280px' };

		return (
		<div>
			<div id="mapView" ref={this.mapContainerRef} style={{height:`calc(100vh - 45px - ${this.props.protruding}px`}}>
				{this.state.isShowUI && (<>

					{this.state.isDrawingMode ===0 && (<>
						<img id="centerMark" src="/img/center.png" alt="centerMark"></img>
					</>)}
					<img id="poszeroButton"  className='toolButton' onClick={this.clickPoszeroButton}  src="/img/tool_position_zero_b.png" alt={t('poszeroButton')} style={poszeroButtonStyle}></img>
					<img id="searchButton"  className="toolButton" src="/img/icon_search_b.png" onClick={this.clickSearchButton} alt={t('searchButton')}></img>
				</>)}
				{(this.state.isShowUI && this.props.fm.auth.currentUser && this.props.fm.auth.currentUser.uid && this.props.fm.auth.currentUser.uid === this.props.owneruid) && (<>
					<img id="settingButton" className="toolButton" src="/img/icon_settings_b.png" onClick={this.handleSetting} alt={t('Setting')}></img>
				</>)}
	
				{this.state.isShowUI && this.state.permission === 2 && (<>
					<img id="addMarketButton"  className='toolButton' onClick={this.clickAddMarkerButton}  src="/img/icon_marker_b.png" alt={t('addMarketButton')} style={addMarketButtonStyle}></img>
					<img id="uploadButton"  className='toolButton' onClick={this.clickUploadButton} src="/img/icon_cloud_upload_b.png" alt={t('uploadButton')} style={uploadButtonStyle}></img>


					<img id="layerButton"  className='toolButton' onClick={this.toggleLMapSetting} src="/img/icon_layers_b.png" alt={t('LayerSetting')}></img>

					<img id="penButton" className='toolButton' onClick={this.clickPenButton} src="/img/icon_pen_b.png" alt={t('penButton')} style={{ ...penButtonStyle, backgroundColor: this.state.penButtonBG }}></img>
					<img id="shapeButton" className='toolButton' onClick={this.clickShapeButton} src="/img/icon_shape_b.png" alt={t('shapeButton')} style={{ ...shapeButtonStyle, backgroundColor: this.state.shapeButtonBG }}></img>
					<img id="paletteButton" className='toolButton' onClick={this.togglePalettePopup} src="/img/icon_palette_b.png" alt={t('paletteButton')} style={paletteButtonStyle}></img>
					<img id="eraserButton" className='toolButton' onClick={this.clickEraserButton} src="/img/icon_eraser_b.png" alt={t('eraserButton')} style={{ ...eraserButtonStyle, backgroundColor: this.state.eraserButtonBG }}></img>
					<img id="undoButton" className='toolButton' onClick={this.handleUndo} disabled={this.state.drawingHistory && this.state.drawingHistory.length <= 1} src="/img/icon_undo_b.png" alt={t('undoButton')} style={undoButtonStyle}></img>
				</>)}
				{this.state.isShowUI && this.state.permission >= 1 && (<>
					{this.state.isfavorite
						? <img id="favoriteButton"  className='toolButton' onClick={this.clickFavoButton} src="/img/icon_favorite_true.png" alt={t('Add_favorite')}></img>
						: <img id="favoriteButton"  className='toolButton' onClick={this.clickFavoButton} src="/img/icon_favorite_b.png" alt={t('Add_favorite')}></img>
					}
					<img id="copyProjectButton"  className='toolButton'  onClick={this.mapCopy} src="/img/icon_file_copy_b.png" alt={t('CopyProject')}></img>
				</>)}

				{this.state.isShowUI && this.state.permission === 2 &&  this.state.isDrawingMode === 3 && (<>
					<img id="lineButton"  className='toolButton' onClick={this.clickLineButton} src="/img/icon_polyline.png" alt={t('lineButton')} style={{ backgroundColor: this.state.lineButtonBG }}></img>
					<img id="circleButton"  className='toolButton' onClick={this.clickCircleButton} src="/img/icon_circle.png" alt={t('circleButton')} style={{ backgroundColor: this.state.circleButtonBG }}></img>
					<img id="polygonButton"  className='toolButton' onClick={this.clickPolygonButton} src="/img/icon_pentagon.png" alt={t('polygonButton')} style={{ backgroundColor: this.state.polygonButtonBG }}></img>
					<img id="measurementButton"  className='toolButton' onClick={this.clickMeasurementButton} src="/img/icon_measurement.png" alt={t('measurementButton')} style={{ backgroundColor: this.state.measurementButtonBG }}></img>
				</>)}

				{this.state.isShowUI && (<>
					<img id="datalistButton"  className='toolButton' src="/img/icon_datalist_b.png" alt={t('Data_list')} onClick={this.toggleDataListPanel}></img>
					<img id="chatButton"  className='toolButton' onClick={this.clickChatButton}  src="/img/icon_chat_b.png" alt={t('chatButton')}></img>
					<img id="shareButton"  className='toolButton' onClick={this.toggleMapSharePopup} src="/img/icon_share_b.png" alt={t('Share')}></img>
					<img id="screenShotButton"  className='toolButton' onClick={this.toggleScreenShot} src="/img/icon_screenShot_b.png" alt={t('ScreenShot')}></img>
				</>)}

				{this.state.isShowUI && this.state.permission === 2 &&  (this.state.isDrawingMode === 1 || this.state.isDrawingMode === 3) && (<>
					<div className="number_box" style={{ backgroundColor: this.state.selectedColor,color: (this.state.selectedColor === '#FFFFFF' || this.state.selectedColor === '#FFFF00' || this.state.selectedColor === '#C0C0C0') ? 'black' : 'white' }}>
						{this.state.lineWidth}
					</div>
				</>)}

				{this.state.isShowUI && this.state.permission === 2 &&  this.state.isDrawingMode !== 0 && this.state.isDrawingMode !== 3 && (<>
					<button id="buttonDrawMode"
						onClick={this.changeDrawingMode} 
						className="roundButton_TC" 
						style={{ 
							backgroundColor: this.state.isDrawingMode === 0 ? 'gray' : '#8fbc18', 
						}}>
						{this.state.isDrawingMode === 1 ? t('freehand_drawing') : t('freehand_eraser') }
					</button>
				</>)}

				{this.state.is3DEnabled && this.state.showDistanceLayer && this.state.map && (
					<>
					<DistanceLayer
						map={this.state.map}
						update3dDistance={this.update3dDistance} 
					/>
					<label id="labelScaleDistance">{this.state.labelScaleDistance}</label>
					</>
				)}

				<div id="testLabel" className='testLabel' style={{display:'none'}}></div>
				{this.state.showAddMarkerPopup && 
					<AddMarkerPopup 
						onSubmit={this.handleAddMarker}
						lat={this.state.map.getCenter().lat}
						lng={this.state.map.getCenter().lng}
						defaultIconNumber = {this.defaultIconNumber}
						onClose={this.closePopup} 
					/>
				}
				{this.state.showEditProjectPopup && 
					<EditProjectPopup 
						onSubmit={this.handleAddMarker}
						fm = {this.props.fm}
						map = {this.state.map}
						parent={this}
						onClose={this.closeSettingPopup}
						myName={this.props.myName}
						myColor={this.props.myColor} 
						data={this.currentProject}
						docId={this.props.uuid}
					/>
				}
				{this.state.showEditMarkerPopup && 
					<EditMarkerPopup 
						onSubmit={this.handleEditMarker}
						markerData={this.editingMarker}
						onClose={() => this.setState({ showEditMarkerPopup: false })} 
					/>
				}
				{this.state.showPalettePopup && 
					<PalettePopup 
						onSelectColor={this.handleColorSelect} 
						onClose={this.togglePalettePopup}
						initialLineWidth={this.state.lineWidth}
						initialLineColor = {this.state.selectedColor} 
						initialLineOpacity = {this.state.selectedOpacity} 
						onLineWidthChange={this.handleLineWidthChange}
						onLineOpacityChange={this.handleLineOpacityChange}
					/>
				}
				{this.state.showMapSharePopup && 
					<MapSharePopup 
						onClose={this.toggleMapSharePopup}
						currentProject={this.currentProject}
					/>
				}
				{this.state.showConfirmDialog && (
					<ConfirmDialog
						message={this.state.confirmDialog.message}
						data = {this.state.confirmDialog.data}
						okTitle={'OK'}
						cancelTitle={t('Cancel')}
						onConfirm={this.state.confirmDialog.onConfirm}
						onCancel={this.state.confirmDialog.onCancel}
					/>
				)}
				{this.state.showOkConfirmDialog && (
					<ConfirmDialog
						message={this.state.okConfirmDialog.message}
						data = {this.state.okConfirmDialog.data}
						okTitle={'OK'}
						cancelTitle={null}
						onConfirm={this.state.okConfirmDialog.onConfirm}
						onCancel={null}
					/>
				)}
				{this.state.showSearchPopup && 
					<SearchPopup 
						onSubmit={this.handleSearchSubmit}
						onClose={this.handleSearchClose}
						onUpdate={this.handleSearchUpdate}
						initialKeyword={this.state.searchKeyword}
						initialResults={this.state.searchResults}
					/>
				}
				{this.state.showLayerSettingPopup && (
					<LayerSettingPopup
					overlays={CommonUtils.overlays}
					maps={CommonUtils.maps}
					customOverlays={this.customOverlays}
					customMaps={this.customMaps}

					currentOverlay={this.currentOverlay}
					currentMap={this.currentMap}
					onClose={this.toggleLMapSetting}
					onApply={this.handleApplyLayerSettings}
					onUpdateCustomLayer={this.updateCustomLayer}
					currentExaggeration={this.state.exaggeration}
					onExaggerationChange={(value) => this.setState({ exaggeration: value })}
					/>
				)}
				<LayerListPanel
					map={this.state.map}
					isOpen={this.state.showLayerListPanel}
					onClose={this.toggleLayerListPanel}
				/>
				<DataListPanel
					map={this.state.map}
					fm={this.props.fm}
					uuid={this.props.uuid}
					owneruid={this.props.owneruid}
					myUid={this.props.fm.auth.currentUser ? this.props.fm.auth.currentUser.uid : null}
					mapManager={this}
					markers={this.markers.filter(markerObj => !markerObj.marker.data.iconDataUrl)}
					photos={
						this.markers
							.filter(marker => marker.marker.data.iconDataUrl)
							.map(marker => ({
								...marker.marker.data,
								lat: marker.marker.lat,
								lng: marker.marker.lng,
								docId: marker.id
							}))
					}
					files={this.state.files}
					isOpen={this.state.showDataListPanel}
					onClose={this.toggleDataListPanel}

					onDeleteFile={this.handleDeleteFile}
					onDeleteMarker={this.onDeleteMarker}
					onDeletePhoto={this.onDeletePhoto}
				/>
				<ChatPanel
					fm = {this.props.fm}
					uuid={this.props.uuid}
					owneruid={this.props.owneruid}
					myUid={this.props.fm.auth.currentUser ? this.props.fm.auth.currentUser.uid : null}
					userDoc={this.props.userDoc}
					isOpen={this.state.showChatPanel}
					onClose={() => this.setState({ showChatPanel: false })}
				/>
				<input
					type="file"
					style={{ display: 'none' }}
					ref={ref => this.fileInputRef = ref}
					onChange={this.handleFileUpload}
					multiple
				/>
				{this.state.uploadProgress && (
					<ProgressBar
						progress={this.state.uploadProgress.current}
						total={this.state.uploadProgress.total}
					/>
				)}
				{this.state.selectedPhoto && (
				<PhotoViewer
					photo={this.state.selectedPhoto}
					photoMarkers={this.state.photoMarkers}
					myUid={this.props.fm.auth.currentUser ? this.props.fm.auth.currentUser.uid : null}
					onClose={this.closePhotoViewer}
					onDelete={this.handlePtohoDelete}
					onPrev={this.handlePrevPhoto}
					onNext={this.handleNextPhoto}
					onMoveStart={this.handlePtohoMove}
					currentIndex={this.state.currentPhotoIndex}
					totalPhotos={this.state.photoMarkers.length}
					isFullscreen={this.state.isPhotoViewerFullscreen}
					toggleFullscreen={this.toggleFullscreen}
					onPhotoSelect={this.handlePhotoSelect}
					onUpdateMemo={this.handlePhotoUpdateMemo}
				/>
				)}
				{this.state.showThumbnailPopup && (
					<div className="popup">
						<div className="popup-inner">
							<h2>{t('Thumbnail Created')}</h2>
							<img src={this.thumbnailUrl} alt="Thumbnail" style={{ maxWidth: '100%', marginBottom: '20px' }} />
							<p>{t('Thumbnail has been created and saved.')}</p>
							<button className='button_y' onClick={this.closeThumbnailPopup}>{t('Close')}</button>
						</div>
					</div>
				)}
				{this.state.showImagePopup && (
					<div className="popup">
						<div className="popup-inner">
							<h2>{t('Screenshot Create')}</h2>
							<img src={this.imageUrl} alt="Screen Shot" style={{ maxWidth: '100%', marginBottom: '20px' }} />
							<p>{t('Screenshot has been created.')}</p>
							<button className='button_y' onClick={this.closeImagePopup}>{t('Close')}</button>
						</div>
					</div>
				)}
				{this.state.isShowUI && this.state.isDrawingMode ===0 && (<>
					<div id="elevationLabel">
						{this.state.centerElevation !== null
							? `${t('Elevation')}: ${this.state.centerElevation} m`
							: `${t('Elevation')}: ---- m`}
					</div>
				</>)}
				{this.state.showLoadingPopup && (<>
					<div>
						<LoadingPopup isVisible={this.state.showLoadingPopup} message={this.state.loadingMessage} />
					</div>
				</>)}


				{this.state.showShapeOperationPanel && (
					<ShapeOperationPanel
					onConfirm={() => {
						this.setState({ showShapeOperationPanel: false });
						this.handleDoubleClick({ lngLat: this.state.map.unproject(this.state.operationPanelPosition) });
					}}
					onUndo={() => {
						this.removeLastPoint();
					}}
					position={this.state.operationPanelPosition}
					/>
				)}
			</div>
		</div>

		);
	}
}
//					<img id="PrintButton"  className='toolButton' onClick={this.togglePrintMap} src="/img/icon_print_b.png" alt={t('Print')}></img>


export default MapManager;