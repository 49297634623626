// Main.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'; // query-string のインポート
import './css/App.css';
import FirebaseManager from './FirebaseManager';
import { Translator, resources } from './Translator';
import ProjectList from './ProjectList';
import ProjectCardGrid from './ProjectCardGrid';
import MapScreen from './MapScreen';
import LoginPopup from './LoginPopup';
import AddProjectPopup from './AddProjectPopup';
import UserDataInputPopup from './UserDataInputPopup';
import { CommonUtils } from './CommonUtils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchComponent from './SearchComponent';
import UserSettingsPopup from './UserSettingsPopup'; 
import UserMenu from './UserMenu';
import { toast } from 'react-toastify';
import ConfirmDialog from './ConfirmDialog';

const translator = new Translator(resources);
const ITEMS_PER_PAGE = 6;

// カスタムフックを定義（useQuery.js を別ファイルにする必要はありません）
const useQuery = () => {
	const location = useLocation();
	return queryString.parse(location.search);
};

const Main = () => {
	const [user, setUser] = useState(null);
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
	const [showUserDataInputPopup, setShowUserDataInputPopup] = useState(false);
	const [favoriteProjects, setFavoriteProjects] = useState([]);
	const [recentProjects, setRecentProjects] = useState([]);
	const [myProjects, setMyProjects] = useState([]);
	const [otherProjects, setOtherProjects] = useState([]);
	const [myName, setMyName] = useState('');
	const [myColor, setMyColor] = useState('');
//	const [myUser, setMyUser] = useState(null);
	const [protruding, setProtruding] = useState(0);
	const [searchResults, setSearchResults] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const [authChecked, setAuthChecked] = useState(false);

	const [showUserSettingsPopup, setShowUserSettingsPopup] = useState(false);
	const [userSettings, setUserSettings] = useState({});

	const [showUserMenu, setShowUserMenu] = useState(false);
	const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);


	const fm = new FirebaseManager();
	const navigate = useNavigate(); // useNavigate の初期化
	const location = useLocation(); // useLocation の初期化
	const query = useQuery(); // クエリパラメータを取得


	const isMapPage = location.pathname.includes('/map');

	let lastUpdatetime_favorite = null;
	let lastUpdatetime_my = null;
	let lastUpdatetime_other = null;

	let lastUpdatetime_recent = null;
	let lastUpdatetime_search = null;

	const userMenuRef = useRef(null);

	const handleIconClick = useCallback((event) => {
		if (userMenuRef.current) {
		if (userMenuRef.current.style.display === 'none' || userMenuRef.current.style.display === '') {
			const rect = event.target.getBoundingClientRect();
			userMenuRef.current.style.top = `${rect.bottom + window.scrollY}px`;
			userMenuRef.current.style.left = `${rect.left + window.scrollX}px`;
			userMenuRef.current.style.display = 'block';
		} else {
			userMenuRef.current.style.display = 'none';
		}
		}
	}, []);

	const handleCloseUserMenu = useCallback(() => {
		if (userMenuRef.current) {
		userMenuRef.current.style.display = 'none';
		}
	}, []);

	const handleUserSettings = () => {
		setShowUserSettingsPopup(true);
		setShowUserMenu(false);
	};

	const handleAccountPermanent = async () => {
		try {
			await fm.linkAnonymousAccountWithGoogle();
			console.log('Succeeded Account permanent');
			toast.info(t('Succeeded Account permanent')); 
		} catch (error) {
			console.error('Failed to make account permanent:', error);
			toast.info(t('Error Account permanent') + error.message); 
			
		}

		console.log('Account permanence requested');
		setShowUserMenu(false);
	};

	const handleCloseUserSettings = () => {
		setShowUserSettingsPopup(false);
	};
	const handleSaveUserSettings = () => {
		getUserSettings();
	};

	const getUserSettings = async () => {
		try {
			const settings = await fm.getUserDocument();
			setUserSettings(settings);
			setMyName(settings.name);
			setMyColor(settings.color);
		} catch (error) {
			console.error('Error fetching user settings:', error);
		}
	};

	useEffect(() => {
		if (isSignedIn) {
			getUserSettings();
		}
	}, [isSignedIn]);

	useEffect(() => {
		fm.initializeAnalytics();

//		let innerHeight = window.visualViewport;
//		let innerHeight = Math.floor(window.visualViewport.height);
//		let innerHeight = Math.floor(document.documentElement.clientHeight);

		//toast.info(innerHeight);

		//画面サイズ調整
		let innerHeight = window.visualViewport ? Math.floor(window.visualViewport.height) : Math.floor(document.documentElement.clientHeight);
		const innerHeightDifference = document.documentElement.scrollHeight - innerHeight;
		setProtruding(innerHeightDifference);

		const unregisterAuthObserver = fm.auth.onAuthStateChanged((user) => {
			if (user) {
				setIsSignedIn(true);
				setUser(user);
				getUserInfo(user);
				subscribeToProjects(user.uid);
			}
			else {
				setIsSignedIn(false);
				setUser(null);
				if (!isMapPage) {
//					setShowLoginPopup(true);
				}
			}
			setAuthChecked(true);  // 認証チェックが完了したことを示す
		});

		// URLのクエリパラメータにsearchが存在する場合
		if (query.search) {
			setSearchInput(query.search);
			handleSearch(query.search);
			handleSearch('');
		} else if (location.pathname === '/top') {
			// /top ページで、かつクエリパラメータが無い場合は空の検索を実行
			handleSearch('');
		}
		return () => unregisterAuthObserver();
	}, [location.pathname]);

	const subscribeToProjects = useCallback((uid) => {
		const collectionPath = `userdata/${uid}/projects`;

		// お気に入りプロジェクト
		const unsubscribeFavorite = fm.subscribeToCollectionWithOption(
			collectionPath,
			{ limit: ITEMS_PER_PAGE, where: ['isfavorite', '==', true] },
			(snapshot) => {
				const projectsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				setFavoriteProjects(projectsData);
			}
		);

		// 自分のプロジェクト
		const unsubscribeMy = fm.subscribeToCollectionWithOption(
			collectionPath,
			{ limit: ITEMS_PER_PAGE, where: ['owneruid', '==', uid] },
			(snapshot) => {
				const projectsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				setMyProjects(projectsData);
			}
		);

		// 他人のプロジェクト
		const unsubscribeOther = fm.subscribeToCollectionWithOption(
			collectionPath,
			{ limit: ITEMS_PER_PAGE, where: ['owneruid', '!=', uid] },
			(snapshot) => {
				const projectsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				setOtherProjects(projectsData);
			}
		);

		return () => {
			unsubscribeFavorite();
			unsubscribeMy();
			unsubscribeOther();
		};
	}, [fm]);

	const searchLoadMore = async (isSearch) => {
		try {
			let snapshot = null;
			if(isSearch) {
				if(!lastUpdatetime_search && searchResults && searchResults.length > 0) lastUpdatetime_search = searchResults[searchResults.length - 1].updatetime;
				if(lastUpdatetime_search) snapshot = await fm.searchProject(searchKeyword, ITEMS_PER_PAGE, lastUpdatetime_search);
			}
			else {
				if(!lastUpdatetime_recent && recentProjects && recentProjects.length > 0) lastUpdatetime_recent = recentProjects[recentProjects.length - 1].updatetime;
				if(lastUpdatetime_recent) snapshot = await fm.searchProject(searchKeyword, ITEMS_PER_PAGE, lastUpdatetime_recent);
			}
			const results = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

			let sectionId = null;
			if(results && results.length > 0) {
				if(isSearch) {
					lastUpdatetime_search = results[results.length - 1].updatetime;
					sectionId = 'Search_projects';
				}
				else {
					lastUpdatetime_recent = results[results.length - 1].updatetime;
					sectionId = 'Recent_projects';
				}
			}

			const projectSection = document.getElementById(sectionId);
			const projectGrid = projectSection.querySelector('.project-grid');

			results.forEach(project => {
				const card = createProjectCard(project, fm, handleTagClick, null);
				projectGrid.appendChild(card);
			});

//			setSearchResults([...searchResults, ...results]);

		} catch (error) {
			console.error('検索中にエラーが発生しました:', error);
		}
	}

	const createProjectCard = (project, fm, onTagClick, onFavoriteToggle) => {
		const card = document.createElement('div');
		card.className = 'project-card';
		card.dataset.projectId = project.id;

		const updatetime = project.updatetime ? project.updatetime.toDate() : new Date();
		const timeString = CommonUtils.formatDateWithIsSec(updatetime);
		const tags = project.tags || [];

		card.innerHTML = `
			<div class="project-thumbnail">
				${project.thumbnailUrl ? `<img src="${project.thumbnailUrl}" alt="${project.title}">` : `<img src="img/screenshot.png" alt="${project.title}">`}
			</div>
			<span class="project-title">${project.title}</span>
			<span class="project-owner">${project.ownername}</span>
			${project.memo ? `<p class="project-memo">${truncateMemo(project.memo)}</p>` : ''}
			<p class="project-time">${timeString}</p>
			<p class="project-tags">
				${tags.map(tag => `<span class="project-tag">#${tag}</span>`).join(' ')}
			</p>`;

		if(onFavoriteToggle) {
			card.innerHTML += `<img 
				class="favorite-button" 
				src="${project.isfavorite ? "./img/icon_favorite_true.png" : "./img/icon_favorite_b.png"}"
				alt="${project.isfavorite ? "Favorite" : "Not favorite"}"
			>`;
		}

		// Add click event listener to the card
		card.addEventListener('click', () => {
			const lat = project.lat ? project.lat : 36.238642;
			const lng = project.lng ? project.lng : 137.969392;
			const zoom = project.zoom ? project.zoom : 13;
			let projectId = project.id;
			if (projectId.includes('_')) {
				const parts = projectId.split('_');
				if(parts.length === 2 && parts[1].length > 0) projectId = parts[1];
			}
			console.log(`card click /map/${projectId}_${project.owneruid}?is3D=false#${zoom}/${lat}/${lng}`);
			window.location.href = `/map/${projectId}_${project.owneruid}?is3D=false#${zoom}/${lat}/${lng}`;
		});

		// Add click event listeners to tags
		const tagElements = card.querySelectorAll('.project-tag');
		tagElements.forEach(tagElement => {
			tagElement.addEventListener('click', (e) => {
				e.stopPropagation();
				onTagClick(tagElement.textContent.slice(1)); // Remove '#' from the tag
			});
		});

		// Add click event listener to favorite button
		if(onFavoriteToggle) {
			const favoriteButton = card.querySelector('.favorite-button');
			favoriteButton.addEventListener('click', (e) => {
				e.stopPropagation();
				onFavoriteToggle(project.id, !project.isfavorite);
				favoriteButton.src = project.isfavorite ? "./img/icon_favorite_b.png" : "./img/icon_favorite_true.png";
				favoriteButton.alt = project.isfavorite ? "Not favorite" : "Favorite";
			});
		}

		return card;
	};

	const truncateMemo = (memo) => {
		if (memo.length > 64) {
			return memo.substring(0, 64) + '...';
		}
		return memo;
	};

	const loadMore = async (category) => {
		const uid = fm.auth.currentUser.uid;
		const collectionPath = `userdata/${uid}/projects`;
		let currentProjects, setProjects, query;

		let lastUpdatetime = null;
		switch (category) {
			case 'favorite':
				currentProjects = favoriteProjects;
				setProjects = setFavoriteProjects;
				query = { where: ['isfavorite', '==', true] };
				lastUpdatetime = lastUpdatetime_favorite;
				break;
			case 'my':
				currentProjects = myProjects;
				setProjects = setMyProjects;
				query = { where: ['owneruid', '==', uid] };
				lastUpdatetime = lastUpdatetime_my;
				break;
			case 'other':
				currentProjects = otherProjects;
				setProjects = setOtherProjects;
				query = { where: ['owneruid', '!=', uid] };
				lastUpdatetime = lastUpdatetime_other;
				break;
			default:
				return;
		}
		if(!lastUpdatetime) {
			const lastDoc = currentProjects[currentProjects.length - 1];
			if(lastDoc) lastUpdatetime = lastDoc.updatetime;
		}
		if(!lastUpdatetime) return;
		
		const options = {
			...query,
			endBefore: lastUpdatetime,
			limit: ITEMS_PER_PAGE,
			orderBy: 'updatetime',
			orderDirection: 'desc'
		};

		try {
			const snapshot = await fm.getMoreDocuments(collectionPath, options);
			const newProjects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//			setProjects([...currentProjects, ...newProjects]);

			let sectionId;

			const lastDoc = newProjects[newProjects.length - 1];
			switch (category) {
				case 'favorite':
					sectionId = 'Favorite_projects';
					lastUpdatetime_favorite = lastDoc.updatetime;
					break;
				case 'my':
					sectionId = 'My_Projects';
					lastUpdatetime_my = lastDoc.updatetime;
					break;
				case 'other':
					sectionId = 'Recently_seen_list';
					lastUpdatetime_other = lastDoc.updatetime;
					break;
				default:
				return;
			}

			const projectSection = document.getElementById(sectionId);
			const projectGrid = projectSection.querySelector('.project-grid');

			newProjects.forEach(project => {
				const card = createProjectCard(project, fm, handleTagClick, onFavoriteToggle);
				projectGrid.appendChild(card);
			});
		} catch (error) {
			console.error('プロジェクトの追加読み込み中にエラーが発生しました:', error);
		}
	};

	const getUserInfo = async (user) => {
		try {
			const userDocument = await fm.getUserDocument();
			if (userDocument) {
				setUserSettings(userDocument);
				setMyName(userDocument.name);
				setMyColor(userDocument.color);

				if(user) {
					fm.setNameAndColor(userDocument.name, userDocument.color);
					fm.setMyUid(user.uid);

					var photoURL = user.photoURL;
					fm.checkIconfileAndSave(user.uid, photoURL, userDocument.name, userDocument.color, 'iconImage');
				}

				console.log('ユーザードキュメント:', userDocument);
			} else {
				const t = (key) => translator.t(key);
				CommonUtils.makeCircleIcon(null, t('Anonymous'), 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[0]), (dataURL) => {
					document.getElementById('iconImage').src = dataURL;
				});
				setShowUserDataInputPopup(true);
			}
		} catch (error) {
			console.error('ユーザードキュメントの取得中にエラーが発生しました:', error);
		}
	};

	const handleAnonymousLogin = async () => {
		try {
			const user = await fm.signInAnonymously();
			setUser(user);
		} catch (error) {
			console.error('匿名ログインに失敗しました:', error);
		}
	};

	const handleGoogleLogin = async () => {
		try {
			const user = await fm.signInWithGoogle();
			setUser(user);
		} catch (error) {
			console.error('Googleログインに失敗しました:', error);
		}
	};

	const handleSignIn = () => {
		setShowLoginPopup(true);
	};

	const showConfirm = (message, data, onConfirm, onCancel) => {
		setShowConfirmDialog(true);
		setConfirmDialog({ message, data, onConfirm, onCancel });
	};
	const [confirmDialog, setConfirmDialog] = useState({
		message: '',
		data: null,
		onConfirm: () => {},
		onCancel: () => {}
	});
	
	const handleSignOut = async () => {
		showConfirm(
			fm.auth.currentUser.isAnonymous ? t('confirm_Logout_Anonymous') : t('confirm_Logout'),
			null,
			async () => {
				try {
					await fm.signOut();
					setUser(null);
					setShowConfirmDialog(false);
					toast.info(t('Signed out'));
				} catch (error) {
					console.error('Sign-out failed:', error);
				}
			},
			() => {
				setShowConfirmDialog(false);
			}
		);
	};

	const handleAddProject = (event) => {
		setShowAddProjectPopup(true);
	};

	const handleToTopPage = (event) => {
		window.location.href = "/top";
	};

	const handleToMyPage = (event) => {
		window.location.href = "/mypage";
	};


	const t = (key) => translator.t(key);

	/*if (!isSignedIn && !isMapPage) {
		return (
			<div className="App">
				{showLoginPopup && (
					<LoginPopup
						onAnonymousLogin={handleAnonymousLogin}
						onGoogleLogin={handleGoogleLogin}
						onClose={() => setShowLoginPopup(false)}
					/>
				)}
			</div>
		);
	}*/

	const signoutButtonStyle = {
		opacity: isSignedIn ? 1 : 0.5
	};
	const signinButtonStyle = {
//        opacity: isSignedIn === 0 ? '1' : '0.5'
    };

	// handleSearch をキーワードパラメータを受け取るように修正
	const handleSearch = async (keyword) => {
		console.log("handleSearch:", keyword);
		setSearchKeyword(keyword);

		// 新しい検索キーワードでURLを更新
		if (keyword) {
			navigate(`/top?search=${encodeURIComponent(keyword)}`, { replace: false });
		} else {
			navigate(`/top`, { replace: false });
		}

		try {
			if (!keyword.trim()) {
				// キーワードが空白の場合、最近のプロジェクトを取得
				const recentSnapshot = await fm.searchProject('', ITEMS_PER_PAGE);
				const recentResults = recentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				setRecentProjects(recentResults);
				setSearchResults([]);
			} else {
				const keywords = keyword.split(/\s+/).filter(k => k.length > 0);
				
				if (keywords.length === 0) {
					setSearchResults([]);
					return;
				}

				const snapshot = await fm.searchProject(keywords[0], ITEMS_PER_PAGE * 2);

				let results = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				for (let i = 1; i < keywords.length; i++) {
					results = results.filter(item => 
						item.keys.some(key => key.toLowerCase().includes(keywords[i].toLowerCase()))
					);
				}

				results = results.slice(0, ITEMS_PER_PAGE);

				setSearchResults(results);
			}
		} catch (error) {
			console.error('検索中にエラーが発生しました:', error);
		}
	};

	const handleTagClick = async (tag) => {
		setSearchInput(tag);
		handleSearch(tag);
	};

	const onFavoriteToggle = async (projectId, isFavorite) => {
		if (fm && fm.auth && fm.auth.currentUser) {
			const myCollectionPath = `userdata/${fm.auth.currentUser.uid}/projects/`;
			const data = {
				isfavorite: isFavorite,
			};
			try {
				await fm.updateDocument(myCollectionPath, projectId, data);
				// Update local state
				setFavoriteProjects(prev => 
					isFavorite 
					? [...prev, { id: projectId, isfavorite: true }]
					: prev.filter(p => p.id !== projectId)
				);
				setMyProjects(prev => 
					prev.map(p => p.id === projectId ? { ...p, isfavorite: isFavorite } : p)
				);
				setOtherProjects(prev => 
					prev.map(p => p.id === projectId ? { ...p, isfavorite: isFavorite } : p)
				);
			} catch (error) {
				console.error('Failed to update favorite status:', error);
			}
		}
	};

	const AuthenticatedRoute = ({ children }) => {
		const navigate = useNavigate();

		useEffect(() => {
			if (authChecked && !isSignedIn) {
				navigate('/top');
			}
		}, [authChecked, isSignedIn, navigate]);

		if (!authChecked) {
			return <div>Loading...</div>;  // または適切なローディングコンポーネント
		}

		return isSignedIn ? children : null;
	};
		
	if (!authChecked) {
		return <div>Loading...</div>;  // または適切なローディングコンポーネント
	}

	const renderTopPage = () => (
		<div className="App">
			<img 
			id="logoImage" 
			alt='Collabomap ロゴ' 
			src="./img/logo_w.png" 
			style={{ position: 'absolute', left: '5px', top: '0px' }}
			/>
			<div id="search_section">
			<SearchComponent
				fm={fm}
				onSearch={handleSearch}
				initialKeyword={searchInput}
				limit={ITEMS_PER_PAGE}
			/>
			</div>
			<div className="App-body">
			{isSignedIn && (
				<>
				<img 
					id="iconImage" 
					alt={myName} 
					className="rounded-image" 
					style={{ position: 'absolute', right: '45px', top: '2px' }}
					onClick={handleIconClick}
				/>
				<img 
					id="signoutButton" 
					className="toolButton_w" 
					src="/img/icon_logout.png" 
					onClick={handleSignOut} 
					alt={t('sign_out')} 
					style={signoutButtonStyle}
				/>
				<div className="button-container">
					<button className="flexButton" onClick={handleToMyPage}>{t('MyPage')}</button>
					<button className="flexButton" onClick={handleAddProject}>{t('new_projects')}</button>
				</div>
				</>
			)}
			{!isSignedIn && (
				<>
				<img 
					id="signinButton" 
					className="toolButton_w" 
					src="/img/icon_login.png" 
					onClick={handleSignIn} 
					alt={t('sign_in')} 
					style={signinButtonStyle}
				/>
				</>
			)}

			{searchResults && searchResults.length > 0 && (
				<div className="App-section" id="Search_projects">
				<div className="search-results-header">
					<button onClick={() => {
					setSearchResults([]);
					navigate('/top', { replace: true });
					}}>{t('Close')}</button>
					<h2>{t('searchResult')}</h2>
				</div>
				<ProjectCardGrid 
					fm={fm} 
					items={searchResults} 
					isReadThumbnail={false} 
					onTagClick={handleTagClick} 
					onFavoriteToggle={null}
				/>
				{searchResults.length >= ITEMS_PER_PAGE && (
					<button className="load-more-button" onClick={() => searchLoadMore(true)}>
					{t('More...')}
					</button>
				)}
				</div>
			)}
			{(recentProjects) && (
				<>
				<div className="App-section" id="Recent_projects">
					<h2>{t('Recent_projects')}</h2>
					<ProjectCardGrid 
						fm={fm} 
						items={recentProjects} 
						isReadThumbnail={true} 
						onTagClick={handleTagClick} 
						onFavoriteToggle={null} 
						isNoMapShow={false}
					/>
					{recentProjects.length >= ITEMS_PER_PAGE && (
					<button className="load-more-button" onClick={() => searchLoadMore(false)}>
						{t('More...')}
					</button>
					)}
				</div>
				</>
			)}
			</div>
			<div id="license" className="license">
			Developed by Keiji Matsumoto@
			<a href="https://geographica.biz/" target="_blank" rel="noopener noreferrer">
				<strong>Geographica.app</strong>
			</a> 
			<a href="./privacy-policy.html">プライバシーポリシー</a>
			</div>
			{showLoginPopup && (
			<LoginPopup
				onAnonymousLogin={handleAnonymousLogin}
				onGoogleLogin={handleGoogleLogin}
				onClose={() => setShowLoginPopup(false)}
			/>
			)}
			{showAddProjectPopup && (
				<AddProjectPopup
					fm={fm}
					myName={myName}
					myColor={myColor}
					onClose={() => {
						setShowAddProjectPopup(false);
					}}
					userSettings={userSettings}
				/>
			)}
			{showUserSettingsPopup && (
				<UserSettingsPopup
					fm={fm}
					onClose={handleCloseUserSettings}
					initialSettings={userSettings}
					onSave={handleSaveUserSettings}

				/>
			)}
			{showUserDataInputPopup && (
				<UserDataInputPopup
					fm={fm}
					onClose={() => {
						setShowUserDataInputPopup(false);
						getUserInfo(fm.auth.currentUser);
					}}
				/>
			)}
			<div ref={userMenuRef} style={{ display: 'none', position: 'absolute' }}>
				<UserMenu
					fm={fm}
					onClose={handleCloseUserMenu}
					onUserSettings={handleUserSettings}
					onAccountPermanent={handleAccountPermanent}
				/>
			</div>

		</div>
	);

	const renderMyPage = () => (
		<AuthenticatedRoute>
			<div className="App">
			<img 
				id="logoImage" 
				alt='Collabomap ロゴ' 
				src="./img/logo_w.png" 
				style={{ position: 'absolute', left: '5px', top: '0px' }}
			/>
			<div id="search_section">
				<SearchComponent
				fm={fm}
				onSearch={handleSearch}
				initialKeyword={searchInput}
				limit={ITEMS_PER_PAGE}
				/>
			</div>
			<div className="App-body">
				<img 
					id="iconImage" 
					alt={myName} 
					className="rounded-image" 
					style={{ position: 'absolute', right: '45px', top: '2px' }}
					onClick={handleIconClick}
				/>
				<img 
					id="signoutButton" 
					className="toolButton_w" 
					src="/img/icon_logout.png" 
					onClick={handleSignOut} 
					alt={t('sign_out')} 
					style={signoutButtonStyle}
				/>
				<div className="button-container">
				<button className="flexButton" onClick={handleToTopPage}>{t('TopPage')}</button>
				<button className="flexButton" onClick={handleAddProject}>{t('new_projects')}</button>
				</div>

				{searchResults && searchResults.length > 0 ? (
				<div className="App-section" id="Search_projects">
					<div className="search-results-header">
					<button onClick={() => {
						setSearchResults([]);
						navigate('/mypage', { replace: true });
					}}>{t('Close')}</button>
					<h2>{t('searchResult')}</h2>
					</div>
					<ProjectCardGrid 
						fm={fm} 
						items={searchResults} 
						isReadThumbnail={false} 
						onTagClick={handleTagClick} 
						onFavoriteToggle={onFavoriteToggle}
					/>
					{searchResults.length >= ITEMS_PER_PAGE && (
					<button className="load-more-button" onClick={() => searchLoadMore()}>
						{t('More...')}
					</button>
					)}
				</div>
				) : (
				<>
					<div className="App-section" id="Favorite_projects">
					<h2>{t('Favorite_projects')}</h2>
					<ProjectCardGrid 
						fm={fm} 
						items={favoriteProjects} 
						isReadThumbnail={true} 
						onTagClick={handleTagClick} 
						onFavoriteToggle={onFavoriteToggle}
					/>
					{favoriteProjects.length >= ITEMS_PER_PAGE && (
						<button className="load-more-button" onClick={() => loadMore('favorite')}>
						{t('More...')}
						</button>
					)}
					</div>
					<div className="App-section" id="My_Projects">
					<h2>{t('My_Projects')}</h2>
					<ProjectCardGrid 
						fm={fm} 
						items={myProjects} 
						isReadThumbnail={true} 
						onTagClick={handleTagClick} 
						onFavoriteToggle={onFavoriteToggle}
					/>
					{myProjects.length >= ITEMS_PER_PAGE && (
						<button className="load-more-button" onClick={() => loadMore('my')}>
						{t('More...')}
						</button>
					)}
					</div>
					<div className="App-section" id="Recently_seen_list">
					<h2>{t('Recently seen')}</h2>
					<ProjectCardGrid 
						fm={fm} 
						items={otherProjects} 
						isReadThumbnail={true} 
						onTagClick={handleTagClick} 
						onFavoriteToggle={onFavoriteToggle}
					/>
					{otherProjects.length >= ITEMS_PER_PAGE && (
						<button className="load-more-button" onClick={() => loadMore('other')}>
						{t('More...')}
						</button>
					)}
					</div>
				</>
				)}
			</div>

			<div id="license" className="license">
				Developed by Keiji Matsumoto@
				<a href="https://geographica.biz/" target="_blank" rel="noopener noreferrer">
				<strong>Geographica.app</strong>
				</a> 
				<a href="./privacy-policy.html">プライバシーポリシー</a>
			</div>

			{showAddProjectPopup && (
				<AddProjectPopup
					fm={fm}
					myName={myName}
					myColor={myColor}
					onClose={() => {
						setShowAddProjectPopup(false);
					}}
					userSettings={userSettings}
				/>
			)}
			{showUserDataInputPopup && (
				<UserDataInputPopup
					fm={fm}
					onClose={() => {
						setShowUserDataInputPopup(false);
						getUserInfo(fm.auth.currentUser);
					}}
				/>
			)}
			{showUserSettingsPopup && (
				<UserSettingsPopup
					fm={fm}
					onClose={handleCloseUserSettings}
					initialSettings={userSettings}
					onSave={handleSaveUserSettings}
				/>
			)}
			<div ref={userMenuRef} style={{ display: 'none', position: 'absolute' }}>
				<UserMenu
					fm={fm}
					onClose={handleCloseUserMenu}
					onUserSettings={handleUserSettings}
					onAccountPermanent={handleAccountPermanent}
				/>
			</div>
			</div>
		</AuthenticatedRoute>
	);
	return (
		<div>
		<Routes>
			<Route path="/top" element={renderTopPage()} />
			<Route path="/mypage" element={renderMyPage()} />
			<Route path="/map/:idAtUrl" element={<MapScreen fm={fm} protruding={protruding} />} />
			<Route path="*" element={<Navigate to="/top" />} />
		</Routes>
		<div>
			{showConfirmDialog && (
				<ConfirmDialog
					message={confirmDialog.message}
					data = {confirmDialog.data}
					okTitle={'OK'}
					cancelTitle={t('Cancel')}
					onConfirm={confirmDialog.onConfirm}
					onCancel={confirmDialog.onCancel}
				/>
			)}
		</div>
		<ToastContainer autoClose={3000} />
		</div>
	);
	};

export default Main;
